import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import currency from "currency.js";
import UserOverview from "../../components/userOverview/UserOverview";
import SidebarStatistics from "../../components/sidebar/SidebarStatistics";
import Layout from "../../components/layout/Layout";
import CashIcon from "../../components/assets/emoticons/CashIcon";
import Accordion from "../../components/accordion/Accordion";
import { getPayouts } from "../../data/payouts/payoutsSlice";
import styles from "./styles.module.css";

const Payouts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.payouts);
  const balanceBeforeFees = useSelector(
    (state) => state.user.payments.balanceBeforeFees
  );
  const balance = useSelector((state) => state.user.payments.balance);
  useEffect(() => {
    dispatch(getPayouts());
  }, [dispatch]);

  return (
    <Layout
      pageTitle={t("payouts.pageTitle")}
      left={<UserOverview />}
      right={<SidebarStatistics />}
    >
      <Accordion
        heading={(
          <>
            {t("payouts.pageTitle")} 
            {' '}
            <CashIcon />
          </>
        )}
        childrenWrapperClass={styles.payouts}
        hideable
      >
        <p className={styles.description}>{t("payouts.description")}</p>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <td>{t("payouts.payoutPeriod")}</td>
                <td>{t("payouts.earningsBeforeFees")}</td>
                <td>{t("payouts.processingFees")}</td>
                <td>{t("payouts.fanbeaFees")}</td>
                <td>{t("payouts.earningsBeforeTax")}</td>
              </tr>
            </thead>
            <tbody>
              {data.map((payout) => (
                <tr key={payout.createdAt}>
                  <td>
                    {new Date(payout.createdAt).toLocaleString("en-EN", {
                      month: "short",
                    })}
                    {" "}
                    {new Date(payout.createdAt).getFullYear()}
                  </td>
                  <td>{payout.items[0].amountBeforeFees}</td>
                  <td>{payout.items[0].processingFees}</td>
                  <td>{payout.items[0].fanbeaFees}</td>
                  <td>{payout.items[0].amount}</td>
                </tr>
              ))}
              <tr>
                <td>
                  {new Date().toLocaleString("en-EN", {
                    month: "short",
                  })}
                  {" "}
                  {new Date().getFullYear()}
                </td>
                <td>{currency(balanceBeforeFees).format()}</td>
                <td>
                  {currency(balanceBeforeFees).subtract(balance).format()}
                </td>
                <td>$0.00</td>
                <td>{currency(balance).format()}</td>
              </tr>
            </tbody>
          </table>
          <p className={styles.learnMore}>
            <Link to="/blog">{t("payouts.learnMore")}</Link>
          </p>
        </div>
      </Accordion>
    </Layout>
  );
};

export default Payouts;
