import React from "react";

function FaqIcon({ height = "1em", width = "1em" }) {
  return (
    <>
      <svg width={width} height={height} fill="none" viewBox="0 0 16 16">
        <g clipPath="url(#clip0)">
          <path
            fill="#041E40"
            d="M14.285 6.571a4.826 4.826 0 01-.48-.967C13.804 2.096 10.563 0 7.429 0A6.617 6.617 0 00.762 6.708c0 2.4.897 4.184 2.667 5.305v3.654a.333.333 0 00.333.333h6.667a.334.334 0 00.333-.333v-1.985c1.16-.012 1.867-.14 2.382-.654.557-.557.637-2.227.641-3.019.2 0 .456.004.678-.005a.826.826 0 00.642-.346.815.815 0 00.126-.495V9.07c.056-.744-.476-1.677-.946-2.5zm-6.618 5.096a1 1 0 110-2 1 1 0 010 2zm1.066-4.054a.667.667 0 00-.4.612.667.667 0 01-1.333 0A2 2 0 018.2 6.39 1.333 1.333 0 106.333 5.17.667.667 0 015 5.17a2.667 2.667 0 113.733 2.444z"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default FaqIcon;
