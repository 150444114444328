import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { desktop } from "../assets/breakpoints/breakpoints";
import styles from "./styles.module.css";
import Logo from "./logo/Logo";
import NavMenu from "./menu/NavMenu";
import SearchInput from "../search/SearchInput";
import ProfileContainer from "./userMenu/ProfileContainer";
import Button from "../button/Button";

const Header = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const isDesktop = useMediaQuery(desktop);
  const { isCreator } = user;
  return (
    <div className={styles.header}>
      <header className={styles.navigation}>
        <div className={styles.navigationLeftPanel}>
          <Logo />
          <NavMenu />
        </div>
        <div className={styles.navigationRightPanel}>
          <SearchInput />

          {!isDesktop && !isCreator && (
            <Button
              className={styles.becomeStarButton}
              to="/become-a-star"
              size="small"
              secondary
            >
              {t("common.becomeStar")}
            </Button>
          )}

          <ProfileContainer user={user} />
        </div>
      </header>
    </div>
  );
};

export default Header;
