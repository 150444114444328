import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import {
  smallDevice,
  desktop,
} from "../../components/assets/breakpoints/breakpoints";
import Avatar from "../../components/avatar/Avatar";
import Button from "../../components/button/Button";
import UserCategory from "../../components/userCategory/UserCategory";
import SectionBox from "./sectionBox/SectionBox";
import ExamplePost from "./svgs/ExamplePost.svg";
import ExamplePostMobile from "./svgs/ExamplePostMobile.svg";
import Communication from "./svgs/Communication.svg";
import CommunicationMobile from "./svgs/CommunicationMobile.svg";
import Stats from "./svgs/Stats.svg";
import StatsMobile from "./svgs/StatsMobile.svg";
import headingImage from "./headingImage.png";
import WonderingIcon from "../../components/assets/emoticons/WonderingIcon";
import styles from "./styles.module.css";
import { getPosts } from "../../data/blog/blogSlice";
import { getSuggestions } from "../../data/users/usersSlice";

const Homepage = () => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.blog.posts);
  const auth = useSelector((state) => state.auth);
  const suggestions = useSelector((state) => state.users.suggestions);
  const { t } = useTranslation();
  const isMobile = useMediaQuery(smallDevice);
  const isDesktop = useMediaQuery(desktop);

  useEffect(() => {
    dispatch(getPosts({}));
    dispatch(getSuggestions({}));
  }, [dispatch]);

  if (auth.isLoggedIn) {
    return <Redirect to="/feed" />;
  }

  return (
    <div className={styles.mainContainer}>
      <section className={styles.headingSection}>
        <div className={styles.textSection}>
          <p className={styles.headingPrimaryText}>{t("homePage.heading")}</p>
          <p className={styles.headingSecondaryText}>{t("homePage.subText")}</p>
          <div className={styles.headingButton}>
            <Link to="/signup">
              <Button primary size="large">
                {t("homePage.createAccount")}
              </Button>
            </Link>
          </div>
        </div>
        <img className={styles.headingImage} src={headingImage} alt="girl" />
      </section>

      <section className={styles.startFanbea}>
        <SectionBox
          subTextClass={styles.subText}
          boxClass={styles.startFanbeaTextBox}
          heading={(
            <>
              {t("homePage.fanbea")} 
              {' '}
              <WonderingIcon />
            </>
          )}
          subText={<p>{t("homePage.fanbeaDesciription")}</p>}
        />
      </section>
      <section className={styles.developIncome}>
        <SectionBox
          subTextClass={styles.subText}
          boxClass={styles.developIncomeTextBox}
          headingClass={styles.developIncomeHeading}
          heading={t("homePage.incomeDev")}
          subText={t("homePage.incomeDevDescription")}
        />
        {!isDesktop ? (
          <img alt="user stats example mobile" src={StatsMobile} />
        ) : (
          <img
            className={styles.incomeImage}
            alt="user stats example"
            src={Stats}
          />
        )}
      </section>
      <section className={styles.becomeStar}>
        <SectionBox
          boxClass={styles.becomeStarBox}
          headingClass={styles.becomeStarHeading}
          heading={t("homePage.getStarted")}
          subTextClass={`${styles.becomeStarText} ${styles.subText}`}
          subText={(
            <>
              {t("homePage.getStartedDescription")}
              <div className={styles.becomeStarButtonStar}>
                <Link to="/signup">
                  <Button secondary size="large">
                    {t("homePage.createAccount")}
                  </Button>
                </Link>
              </div>
            </>
          )}
        />
      </section>
      <section className={styles.connectWithSupporters}>
        <SectionBox
          subTextClass={styles.subText}
          boxClass={styles.connectWithSupportersBox}
          heading={t("homePage.suppConnections")}
          subText={t("homePage.suppConnectionsDescription")}
        />
        {!isDesktop ? (
          <img
            alt="comunication with supporters on mobile"
            src={CommunicationMobile}
          />
        ) : (
          <img
            className={styles.connectWithSupportersImg}
            alt="comunication with supporters"
            src={Communication}
          />
        )}
      </section>
      <section className={styles.suggestions}>
        <SectionBox
          subTextClass={styles.subText}
          boxClass={styles.suggestionsbox}
          headingClass={styles.suggestionsHeading}
          heading={t("homePage.suggestionHeading")}
        >
          <div className={styles.suggestUsers}>
            {suggestions.slice(0, 3).map((suggestion) => (
              <div className={styles.suggestBox} key={suggestion.username}>
                <Avatar src={suggestion.avatar} size="mediumLarge" />
                <div className={styles.userInfo}>
                  <p className={styles.username}>{suggestion.username}</p>
                  <p className={styles.description}>
                    <UserCategory category={suggestion.category} />
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.suggestionButton}>
            <Link to="/signup">
              <Button primary size="large">
                {t("homePage.createAccount")}
              </Button>
            </Link>
          </div>
        </SectionBox>
      </section>
      <section className={styles.premiumSubscribers}>
        <SectionBox
          subTextClass={styles.subText}
          boxClass={styles.premiumSubscribersBox}
          heading={t("homePage.premiumSub")}
          subText={t("homePage.premiumSubDescription")}
        />
        <div className={styles.examplePost}>
          {isMobile ? (
            <img alt="example post on mobile" src={ExamplePostMobile} />
          ) : (
            <img alt="example post" src={ExamplePost} />
          )}
        </div>
      </section>
      <section className={styles.blog}>
        <div className={styles.blogSectionHeading}>
          {t("homePage.blogCheck")}
        </div>
        <div className={styles.cardContainer}>
          {posts.slice(0, 3).map((post) => {
            return (
              <Link key={post.id} to={`/blog/post/${post.slug}`}>
                <div className={styles.cardBoard}>
                  <p className={styles.postHeading}>{post.title}</p>
                  <p
                    className={styles.postText}
                    dangerouslySetInnerHTML={{ __html: post.excerpt }}
                  />
                  <div>
                    <img
                      src={post.image.src}
                      className={styles.imgPost}
                      alt="creator image1"
                    />
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Homepage;
