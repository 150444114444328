import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { becomeCreator, clearErrors } from "../../data/user/userSlice";
import Layout from "../../components/layout/Layout";
import Radio from "../../components/radio/Radio";
import UserOverview from "../../components/userOverview/UserOverview";
import Button from "../../components/button/Button";
import TextInput from "../../components/textInput/TextInput";
import SidebarBox from "../../components/sidebar/SidebarBox";
import WonderingIcon from "../../components/assets/emoticons/WonderingIcon";
import StarIcon from "../../components/assets/emoticons/StarIcon";
import AdultContentIcon from "../../components/assets/emoticons/AdultContentIcon";
import NetworkIcon from "../../components/assets/emoticons/NetworkIcon";
import styles from "./styles.module.css";
import photoIcon from "./photoIcon.svg";
import Popup from "../../components/popup/Popup";

const BecomeStar = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const fileInputRef = useRef();
  const [fileInputError, setFileInputError] = useState();
  const [images, setImages] = useState([]);

  useEffect(() => {
    dispatch(clearErrors({}));
  }, [dispatch]);

  const findError = useCallback(
    (fieldName) => {
      const field = (user.errors || []).find((error) => {
        return error.param === fieldName;
      });
      return field ? field.msg : null;
    },
    [user.errors]
  );

  useEffect(() => {
    if (findError("link")) {
      setStep(3);
    }
  }, [findError, user.errors]);

  const handleAddPhotoClick = useCallback(() => {
    fileInputRef.current.value = "";
    fileInputRef.current.click();
  }, [fileInputRef]);

  useEffect(() => {
    if (fileInputError) {
      const timeout = setTimeout(() => setFileInputError(undefined), 3000);
      return () => clearTimeout(timeout);
    }

    return () => {};
  }, [fileInputError]);

  const handleFileChange = useCallback(
    (e) => {
      e.persist();
      const file = e.target.files[0];

      if (file.size > 2_000_000) {
        setFileInputError(t("becomeStar.photoTooBigError"));
        return;
      }

      setImages((currentImages) => [...currentImages, e.target.files[0]]);
    },
    [t]
  );

  const handlePhotoDelete = useCallback(
    (index) => () => {
      setImages((currentImages) =>
        currentImages.filter((item, imageIndex) => imageIndex !== index)
      );
    },
    []
  );

  const onSubmit = async (data) => {
    const resultAction = await dispatch(
      becomeCreator({
        data: { ...data, images },
      })
    );

    if (becomeCreator.fulfilled.match(resultAction)) {
      history.push("/settings");
    }
  };

  const nextStep = (event) => {
    event.preventDefault();
    setStep(step + 1);
  };

  const userOverview = () => <UserOverview />;

  const actionBox = () => (
    <>
      {step === 4 ? (
        <SidebarBox
          heading={(
            <>
              <StarIcon />
              <span>{t("becomeStar.finishHeading")}</span>
              <StarIcon />
            </>
          )}
          buttons={(
            <Button type="submit" secondary disabled={!images.length}>
              {t("becomeStar.finishButton")}
            </Button>
          )}
        />
      ) : (
        <SidebarBox
          heading={(
            <>
              <StarIcon />
              <span>{t("becomeStar.nextStepHeading")}</span>
              <StarIcon />
            </>
          )}
          buttons={(
            <Button onClick={nextStep} secondary>
              {t("becomeStar.nextStep")}
            </Button>
          )}
        />
      )}
    </>
  );

  if (!auth.isLoggedIn || !user.isLoaded) {
    return <Redirect to="/signin" />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Layout
        pageTitle={`Step ${step} of 4`}
        left={userOverview()}
        right={actionBox()}
        leftHideOnMobile
        rightHideOnMobile={false}
      >
        <div className={step === 1 ? styles.box : styles.hide}>
          <h2 className={styles.heading}>
            {t("becomeStar.categoryHeadingMobile")} 
            {' '}
            <WonderingIcon />
          </h2>
          <p className={styles.description}>
            {t("becomeStar.categoryDescription")}
          </p>
          <Radio
            ref={register}
            defaultChecked
            name="category"
            value="adult"
            label={t("categories.adult")}
          />
          <Radio
            ref={register}
            name="category"
            value="videos"
            label={t("categories.videos")}
          />
          <Radio
            ref={register}
            name="category"
            value="design"
            label={t("categories.design")}
          />
          <Radio
            ref={register}
            name="category"
            value="podcasts"
            label={t("categories.podcasts")}
          />
          <Radio
            ref={register}
            name="category"
            value="writing"
            label={t("categories.writing")}
          />
          <Radio
            ref={register}
            name="category"
            value="music"
            label={t("categories.music")}
          />
          <Radio
            ref={register}
            name="category"
            value="software"
            label={t("categories.software")}
          />
          <Radio
            ref={register}
            name="category"
            value="photography"
            label={t("categories.photography")}
          />
          <Radio
            ref={register}
            name="category"
            value="business"
            label={t("categories.business")}
          />
        </div>
        <div className={step === 2 ? styles.box : styles.hide}>
          <h2 className={styles.heading}>
            {t("becomeStar.nudityHeading")} 
            {' '}
            <AdultContentIcon />
          </h2>
          <p className={styles.description}>
            {t("becomeStar.nudityDescription")}
          </p>
          <Radio
            defaultChecked
            ref={register}
            name="settings.isNudity"
            value={false}
            label={t("becomeStar.noNudity")}
          />
          <Radio
            ref={register}
            name="settings.isNudity"
            value
            label={t("becomeStar.nudity")}
          />
        </div>
        <div className={step === 3 ? styles.box : styles.hide}>
          <h2 className={styles.heading}>
            {t("becomeStar.linkHeading")} 
            {' '}
            <NetworkIcon />
          </h2>
          <p className={styles.description}>
            {t("becomeStar.linkDescription")}
          </p>
          <TextInput
            ref={register}
            prefix="fanbea.com/u/"
            label={t("becomeStar.link")}
            name="link"
            placeholder={t("becomeStar.linkPlaceholder")}
            error={findError("link")}
            onInput={(event) => {
              event.target.value = event.target.value
                .toLowerCase()
                .replace(/([^0-9a-z_])/g, "");
            }}
          />
        </div>
        <div className={step === 4 ? styles.box : styles.hide}>
          <h2 className={styles.heading}>
            {t("becomeStar.verificationHeading")}
          </h2>
          <p className={styles.description}>
            {t("becomeStar.verificationDescription")}
          </p>
          <input
            type="file"
            ref={fileInputRef}
            accept=".jpg,.jpeg,.png"
            className={styles.fileInput}
            onChange={handleFileChange}
          />
          <button
            type="button"
            className={styles.addPhotoButton}
            onClick={handleAddPhotoClick}
            disabled={images.length === 2}
          >
            <img
              className={styles.addPhotoButtonIcon}
              src={photoIcon}
              alt={t("becomeStar.addPhoto")}
            />
            {t("becomeStar.addPhoto")}
          </button>

          {images.map((file, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={styles.selectedFile}>
              {file.name}
              <button
                type="button"
                className={styles.selectedFileDeleteButton}
                onClick={handlePhotoDelete(index)}
              >
                {t("becomeStar.deletePhoto")}
              </button>
            </div>
          ))}

          {fileInputError && (
            <Popup isOpen text={t("becomeStar.photoTooBigError")} warning />
          )}
        </div>
      </Layout>
    </form>
  );
};

export default BecomeStar;
