import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ShakeIcon from "../assets/emoticons/ShakeIcon";
import ListItem from "../list/ListItem";
import Button from "../button/Button";
import UserCategory from "../userCategory/UserCategory";
import { getMembership } from "../../data/membership/membershipSlice";
import styles from "./styles.module.css";

const UserOverviewSupporting = () => {
  const { t } = useTranslation();
  const { subscribes } = useSelector((state) => state.membership);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMembership());
  }, [dispatch]);

  if (user.isCreator) {
    return null;
  }

  return (
    <div className={styles.supporting}>
      <h2 className={styles.supportingHeader}>
        <>
          <ShakeIcon />
          {t("userOverview.supporting")}
          <ShakeIcon />
        </>
      </h2>
      {subscribes ? (
        <div className={styles.supportingList}>
          {Object.values(subscribes).map((star) => (
            <ListItem
              alt={star.username}
              key={star.username}
              primaryText={star.username}
              secondaryText={<UserCategory category={star.category} />}
              avatarUrl={star.avatar}
              link={`/u/${star.link}`}
              renderButtons={() => (
                <Button transparent to={`/u/${star.link}`}>
                  {t("common.view")}
                </Button>
              )}
            />
          ))}
          <div className={styles.supportingShowMembership}>
            <Button transparent to="/membership">
              {t("userOverview.showMembership")}
            </Button>
          </div>
        </div>
      ) : (
        <p className={styles.supportingEmpty}>
          {t("userOverview.supportingEmpty")}
        </p>
      )}
    </div>
  );
};

export default UserOverviewSupporting;
