import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={styles.footer}>
      <div className={styles.footerLeft}>
        <p>{t("footer.fanbea2021")}</p>
      </div>

      <div className={styles.footerRight}>
        <p>
          <Link to="/dmca">{t("footer.dmca")}</Link>
        </p>
        <p>
          <Link to="/2257">{t("footer.2257")}</Link>
        </p>
        <p>
          <Link to="/privacy-policy">{t("footer.privacy")}</Link>
        </p>
        <p>
          <Link to="/terms-and-conditions">{t("footer.terms")}</Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
