import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getPosts } from "../../data/blog/blogSlice";
import Layout from "../../components/layout/Layout";
import BlogPostSuggestions from "./BlogPostSuggestions";
import styles from "./styles.module.css";

const BlogPostSingle = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { t } = useTranslation();
  let postIndex;
  const posts = useSelector((state) => state.blog.posts);
  const postsWithSlug = useSelector((state) =>
    state.blog.posts.filter((post, index) => {
      if (post.slug === slug) {
        postIndex = index;
        return true;
      }
      return false;
    })
  );
  const post = postsWithSlug.length ? postsWithSlug[0] : null;
  const prevPost = posts[postIndex - 1] ? posts[postIndex - 1] : null;
  const nextPost = posts[postIndex + 1] ? posts[postIndex + 1] : null;

  useEffect(() => {
    dispatch(getPosts({}));
  }, [dispatch]);

  if (!post) {
    return null;
  }

  return (
    <Layout
      pageTitle={(
        <>
          <Link to="/blog">{t("blog.blog")}</Link>
          {' '}
          →
          {post.title}
        </>
      )}
    >
      <div className={styles.postSingle}>
        <div className={styles.imageWrapper}>
          <img src={post.image.src} alt="" />
        </div>
        <div className={styles.content}>
          <h1 className={styles.title}>{post.title}</h1>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </div>
      </div>
      <BlogPostSuggestions prev={prevPost} next={nextPost} />
    </Layout>
  );
};

export default BlogPostSingle;
