import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { listCards, addCard, removeCard } from "../../data/user/userSlice";
import MaskedTextInput from "../../components/textInput/MaskedTextInput";
import TextInput from "../../components/textInput/TextInput";
import Select from "../../components/select/Select";
import LockIcon from "../../components/assets/icons/LockIcon";
import CardIcon from "../../components/assets/emoticons/CardIcon";
import Button from "../../components/button/Button";
import CreditCard from "../../components/creditCard/CreditCard";
import countries from "./countries.json";
import styles from "./styles.module.css";

const SettingsPayments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.user.cards);
  const [cardState, setCardState] = useState({
    firstName: null,
    secondName: null,
    number: null,
    validThru: null,
    cvc: null,
    country: null,
    street: null,
    city: null,
    state: null,
    postalCode: null,
  });

  const formRef = useRef(null);
  const securionRef = useRef(null);
  const [error, setError] = useState({});
  const [isCardLoading, setCardLoading] = useState(false);
  const {
    firstName,
    secondName,
    number,
    validThru,
    cvc,
    country,
    street,
    city,
    state,
    postalCode,
  } = cardState;

  const handleChange = (event) => {
    setCardState({ ...cardState, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    dispatch(listCards());
  }, [dispatch]);

  const findError = (fieldName) => {
    if (!error) {
      return null;
    }
    if (fieldName === "number") {
      return [
        "invalid_number",
        "expired_card",
        "insufficient_funds",
        "lost_or_stolen",
        "suspected_fraud",
        "limit_exceeded",
        "card_declined",
        "processing_error",
        "blacklisted",
      ].includes(error.code)
        ? error.message
        : null;
    }
    if (fieldName === "validThru") {
      return ["invalid_expiry_month", "invalid_expiry_year"].includes(
        error.code
      )
        ? error.message
        : null;
    }
    if (fieldName === "cvc") {
      return ["incorrect_cvc", "incorrect_zip"].includes(error.code)
        ? error.message
        : null;
    }
    return null;
  };

  const onSubmit = () => {
    setCardLoading(true);
    dispatch(
      addCard({
        cardholderName:
          firstName && secondName ? `${firstName} ${secondName}` : null,
        number: number ? number.split(" ").join("") : null,
        expMonth:
          validThru && validThru.includes("/") ? validThru.split("/")[0] : null,
        expYear:
          validThru && validThru.includes("/") ? validThru.split("/")[1] : null,
        cvc,
        country,
        street,
        city,
        state,
        postalCode,
      })
    ).then((resultAction) => {
      setCardLoading(false);
      if (addCard.rejected.match(resultAction)) {
        if (resultAction.payload && resultAction.payload.error) {
          setError(resultAction.payload.error);
        }
      }
      dispatch(listCards());
    });
  };

  const onCardRemove = (id) => {
    dispatch(removeCard({ id })).then(() => {
      dispatch(listCards());
    });
  };

  const onCreateCardToken = (token) => {
    if (!token.error) {
      window.SecurionPay.verifyThreeDSecure(
        {
          amount: 1,
          currency: "EUR",
          card: token.id,
        },
        (xd) => {
          console.log(xd);
        }
      );
      dispatch(
        addCard({
          token: token.id,
        })
      ).then((resultAction) => {
        setCardLoading(false);
        if (addCard.rejected.match(resultAction)) {
          if (resultAction.payload && resultAction.payload.error) {
            setError(resultAction.payload.error);
          }
        }
        dispatch(listCards());
      });
    }
  };

  const onSecurionSubmit = (event) => {
    event.preventDefault();
    console.log(securionRef.current);
    window.Securionpay.createCardToken(securionRef.current, onCreateCardToken);
  };

  return (
    <div ref={formRef} className={styles.box}>
      <h2 className={styles.heading}>
        {t("settings.paymentSettings")} <CardIcon />
      </h2>
      <div className={styles.description}>
        <p>
          <LockIcon /> {t("settings.paymentSettingsDescription")}
        </p>
      </div>
      {cards.length === 0 && (
        <form ref={securionRef} onSubmit={onSecurionSubmit}>
          <MaskedTextInput
            name="number"
            onChange={handleChange}
            options={{ creditCard: true }}
            label={t("settings.cardNumber")}
            placeholder="0000 0000 0000 0000"
            error={findError("number")}
            data-securionpay="number"
          />
          <div className={styles.row}>
            <MaskedTextInput
              name="validThru"
              onChange={handleChange}
              label={t("settings.validThru")}
              placeholder="00"
              error={findError("validThru")}
              data-securionpay="expMonth"
            />
            <MaskedTextInput
              name="validThru"
              onChange={handleChange}
              label={t("settings.validThru")}
              placeholder="0000"
              error={findError("validThru")}
              data-securionpay="expYear"
            />
          </div>
          <MaskedTextInput
            name="cvc"
            onChange={handleChange}
            options={{ blocks: [3], delimiter: "/", numericOnly: true }}
            label={t("settings.cvc")}
            maxlength="4"
            placeholder="000"
            error={findError("cvc")}
            data-securionpay="cvc"
          />
          <div className={styles.separator} />
          <Button isLoading={isCardLoading} secondary type="submit" data->
            {t("settings.addCard")}
          </Button>
        </form>
      )}
      {cards.map((card) => (
        <CreditCard
          key={card.id}
          id={card.id}
          number={card.number}
          brand={card.brand}
          first6={card.first6}
          onAction={onCardRemove}
          actionText={t("card.removeCard")}
        />
      ))}
    </div>
  );
};

export default SettingsPayments;
