import React from "react";
import classNames from "classnames";
import styles from "./styles.module.css";
import AvatarPlaceholder from "./AvatarPlaceholder";

const Avatar = ({ src, onClick, size = "medium", isCreator, alt }) => {
  const avatarClasses = classNames(styles.wrapper, {
    [styles.large]: size === "large",
    [styles.mediumLarge]: size === "mediumLarge",
    [styles.small]: size === "small",
    [styles.creator]: isCreator,
  });
  const placeholderClasses = classNames({
    [styles.smallPlaceholder]: size,
    [styles.largePlaceholder]: size === "large",
  });
  return (
    <div className={avatarClasses} onClick={onClick}>
      {src ? (
        <img
          alt={`${alt} Fanbea`}
          title={`${alt} Fanbea`}
          key="image"
          className={styles.image}
          src={src}
        />
      ) : (
        <AvatarPlaceholder
          className={placeholderClasses}
          fill={isCreator ? "var(--black)" : "var(--darkGray)"}
        />
      )}
    </div>
  );
};

export default Avatar;
