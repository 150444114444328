import { useEffect, useState } from "react";
import { storage } from "../firebase";

const getDownloadUrl = async (storageRef) =>
  storage.ref(storageRef).getDownloadURL();

export const usePrivateAssetRef = (storageRef) => {
  const [url, setUrl] = useState();

  useEffect(() => {
    const getUrl = async () => {
      const downloadUrl = await getDownloadUrl(storageRef);
      setUrl(downloadUrl);
    };

    getUrl();
  }, [storageRef]);

  return url;
};
