import React from "react";

function HamburgerMenu({ style }) {
  return (
    <svg
      className={style}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className={style}
        fill="#E9BE00"
        d="M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm-4.5 17h-11a1 1 0 010-2h11a1 1 0 010 2zm0-4h-11a1 1 0 010-2h11a1 1 0 010 2zm0-4h-11a1 1 0 010-2h11a1 1 0 110 2z"
      />
    </svg>
  );
}

export default HamburgerMenu;
