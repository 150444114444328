import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="8"
      fill="none"
      viewBox="0 0 12 8"
    >
      <path
        fill="#7B7B7B"
        d="M6 7.75a1.15 1.15 0 01-.865-.39L.23 1.784A.924.924 0 011.616.564l4.29 4.877a.125.125 0 00.188 0l4.29-4.877a.923.923 0 111.386 1.22L6.866 7.358A1.155 1.155 0 016 7.75z"
      />
    </svg>
  );
}

export default Icon;
