import ky from "ky";

const api = ky.extend({
  retry: {
    limit: 2,
    statusCodes: [403, 401],
  },
  prefixUrl: process.env.REACT_APP_API_URL,
  hooks: {
    beforeRequest: [
      async (request) => {
        if (localStorage.getItem("idToken")) {
          request.headers.set(
            "Authorization",
            `Bearer ${localStorage.getItem("idToken")}`
          );
        }
      },
    ],
  },
});

export default api;
