import api from "../api";

const userApi = {
  updateAvatar: (formData) => api.post("avatar", { body: formData }),
  deleteAvatar: () => api.delete("avatar"),
  getCurrentUser: () => api.get("current-user"),
  updateCurrentUser: (data) => api.put("current-user", { json: data }),
  becomeCreator: (data) => api.post("become-creator", { json: data }),
  updatePlanPrice: ({ price }) =>
    api.put("current-user/plan", { json: { price } }),
  addCard: ({
    cardholderName,
    number,
    expMonth,
    expYear,
    cvc,
    country,
    street,
    city,
    state,
    postalCode,
  }) =>
    api.post("cards", {
      json: {
        cardholderName,
        number,
        expMonth,
        expYear,
        cvc,
        country,
        street,
        city,
        state,
        postalCode,
      },
    }),
  removeCard: ({ id }) => api.delete(`cards/${id}`),
  listCards: () => api.get("cards"),
  deleteAccount: () => api.delete("current-user"),
};

export default userApi;
