import React from "react";
import styles from "../header/styles.module.css";

const IconComponent = ({ icon, text, style, ...rest }) => (
  <div className={style} {...rest}>
    <div className={styles.icon}>{icon}</div>
    <span className={styles.iconText}>{text}</span>
  </div>
);

export default IconComponent;
