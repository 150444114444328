import React from "react";
import { useTranslation } from "react-i18next";
import currency from "currency.js";
import SidebarBox from "./SidebarBox";
import HeartIcon from "../assets/emoticons/HeartIcon";
import Button from "../button/Button";
import styles from "./styles.module.css";

const SidebarSubscribe = ({
  planPrice,
  username,
  postsToUnlock,
  onUnlock = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <SidebarBox
      heading={(
        <>
          <HeartIcon />
          <span>{t("sidebarSubscribe.heading")}</span>
          <HeartIcon />
        </>
      )}
    >
      <div className={styles.sidebarSubscribe}>
        <p className={styles.text}>
          {t("sidebarSubscribe.description", {
            username,
            postsToUnlock,
            textVariant: postsToUnlock > 1 ? "posts" : "post",
          })}
        </p>
        <Button onClick={onUnlock} primary>
          {t("sidebarSubscribe.unlock")} 
          {' '}
          {currency(planPrice).format()}
        </Button>
        <p className={styles.fees}>{t("sidebarSubscribe.fees")}</p>
      </div>
    </SidebarBox>
  );
};

export default SidebarSubscribe;
