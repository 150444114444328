import React, { forwardRef } from "react";
import styles from "./styles.module.css";

const CheckBox = forwardRef(({ label, ...rest }, ref) => (
  <div className={styles.checkboxWrapper}>
    <label className={styles.label}>
      <input ref={ref} className={styles.checkBox} type="checkbox" {...rest} />
      <span className={styles.checkMark} />
      <p>{label}</p>
    </label>
  </div>
));

export default CheckBox;
