import React from "react";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import styles from "./styles.module.css";

const Layout = ({
  pageTitle,
  children,
  left,
  leftHideOnMobile = true,
  right,
  rightHideOnMobile = true,
  mobilePosition = "bottom",
}) => {
  const layoutClasses = classNames(styles.layout, {
    [styles.positionTop]: mobilePosition === "top",
  });

  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const showLeft = leftHideOnMobile ? isDesktop : true;
  const showRight = rightHideOnMobile ? isDesktop : true;
  return (
    <>
      <div className={layoutClasses}>
        {showLeft && (
          <div className={styles.left}>
            <div className={styles.sticky}>{left}</div>
          </div>
        )}
        <main className={styles.main}>
          <div className={styles.pageTitle}>{pageTitle}</div>
          {children}
        </main>
        {showRight && (
          <div className={styles.right}>
            <div className={styles.sticky}>{right}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default Layout;
