import React from "react";
import Loader from "./Loader";
import styles from "./styles.module.css";

const LoaderWrapper = ({ isLoading, isOpaque, isLight, children }) => (
  <div
    className={`${styles.loaderWrapper} ${
      isLoading ? styles.loaderWrapperActive : ""
    } ${isOpaque ? styles.loaderWrapperOpaque : ""} ${
      isLight ? styles.loaderWrapperLight : ""
    }`}
  >
    {children}
    <div className={styles.loaderCentered}>{isLoading && <Loader />}</div>
  </div>
);

export default LoaderWrapper;
