import React from "react";

function LeftArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="#7B7B7B"
        d="M11.274 5.28H2.72a.126.126 0 01-.083-.219l4.319-3.8a.72.72 0 00-.95-1.08L.415 5.1a1.2 1.2 0 000 1.8l5.589 4.918a.72.72 0 00.95-1.08l-4.319-3.8a.125.125 0 01.083-.219h8.556a.72.72 0 000-1.44v.001z"
      />
    </svg>
  );
}

export default LeftArrowIcon;
