import React, { forwardRef } from "react";

const DotsIcon = forwardRef(({ height = "1em", width = "1em" }, ref) => {
  return (
    <svg
      ref={ref}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99992 4.33333C9.19654 4.33333 10.1666 3.36328 10.1666 2.16667C10.1666 0.97005 9.19654 0 7.99992 0C6.8033 0 5.83325 0.97005 5.83325 2.16667C5.83325 3.36328 6.8033 4.33333 7.99992 4.33333Z"
        fill="#7B7B7B"
      />
      <path
        d="M7.99992 10.1667C9.19654 10.1667 10.1666 9.19666 10.1666 8.00004C10.1666 6.80342 9.19654 5.83337 7.99992 5.83337C6.8033 5.83337 5.83325 6.80342 5.83325 8.00004C5.83325 9.19666 6.8033 10.1667 7.99992 10.1667Z"
        fill="#7B7B7B"
      />
      <path
        d="M7.99992 16C9.19654 16 10.1666 15.0299 10.1666 13.8333C10.1666 12.6367 9.19654 11.6666 7.99992 11.6666C6.8033 11.6666 5.83325 12.6367 5.83325 13.8333C5.83325 15.0299 6.8033 16 7.99992 16Z"
        fill="#7B7B7B"
      />
    </svg>
  );
});

export default DotsIcon;
