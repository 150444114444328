import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../components/button/Button";
import styles from "./styles.module.css";

const Pagination = ({ pages, currentPage }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.pagination}>
      <p className={styles.paginationButton}>
        {currentPage > 1 && (
          <Button transparent to={`/blog/${currentPage - 1}`}>
            {t("blog.previousPage")}
          </Button>
        )}
      </p>
      <p className={styles.paginationPageList}>
        {Array.from(Array(pages).keys()).map((page) => (
          <Link key={page} to={`/blog/${page + 1}`}>
            <span className={styles.pageListItem}>{page + 1}</span>
          </Link>
        ))}
      </p>
      <p className={styles.paginationButton}>
        {currentPage < pages && (
          <Button primary to={`/blog/${currentPage + 1}`}>
            {t("blog.nextPage")}
          </Button>
        )}
      </p>
    </div>
  );
};

export default Pagination;
