import React from "react";

function LogInIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#041E40"
        d="M15.667 0h-2a.333.333 0 00-.236.098L7.078 6.452a5 5 0 102.47 2.471l1.542-1.542 1.289.43a.666.666 0 00.867-.734l-.241-1.61.484-.484.97.441a.667.667 0 00.92-.787l-.338-1.206.862-.862A.334.334 0 0016 2.333v-2A.333.333 0 0015.667 0zm-11 12.333a1 1 0 11-2 0 1 1 0 012 0z"
      />
    </svg>
  );
}

export default LogInIcon;
