import React from "react";
import { useSelector } from "react-redux";
import Avatar from "../avatar/Avatar";
import UserCategory from "../userCategory/UserCategory";
import styles from "./styles.module.css";

const UserOverview = ({ children, user }) => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const loggedInUser = useSelector((state) => state.user);
  const { isCreator, avatar, username, category } = user || loggedInUser;

  if (!user && !isLoggedIn) {
    return null;
  }

  return (
    <div className={`${styles.wrapper} ${isCreator ? styles.creator : ""}`}>
      <div className={styles.userOverview}>
        <Avatar
          alt={username}
          src={avatar}
          size="large"
          isCreator={isCreator}
        />
        <p className={styles.username}>{username}</p>
        <p className={styles.title}>
          <UserCategory category={category} />
        </p>
      </div>
      {children}
    </div>
  );
};

export default UserOverview;
