import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { desktop } from "../../../components/assets/breakpoints/breakpoints";
import List from "../../../components/list/List";
import Button from "../../../components/button/Button";
import UserCategory from "../../../components/userCategory/UserCategory";
import ListItem from "../../../components/list/ListItem";
import SearchIcon from "../../../components/assets/icons/SearchIcon";
import UsersIcon from "../../../components/assets/emoticons/UsersIcon";
import { getContacts } from "../../../data/messages/messagesSlice";
import SidebarBox from "../../../components/sidebar/SidebarBox";
import Accordion from "../../../components/accordion/Accordion";
import styles from "./styles.module.css";

const Contacts = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(desktop);
  const [isFocused, setFocused] = useState(false);
  const [searchText, setSearchText] = useState("");
  const contacts = useSelector((state) => state.messages.contacts);
  const areContactsLoaded = useSelector(
    (state) => state.messages.areContactsLoaded
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContacts({}));
  }, [dispatch]);

  const onSearchTextChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  const getFilteredContacts = () =>
    contacts.filter((contact) => contact.username.indexOf(searchText) > -1);
  const searchContactsModule = (
    <>
      <div className={styles.search}>
        <input
          placeholder={t("users.searchUsers")}
          className={styles.searchInput}
          onChange={onSearchTextChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        <div className={styles.searchIcon}>
          <SearchIcon
            style={styles.searchIcon}
            fill={isFocused ? "var(--black)" : "var(--darkGray)"}
          />
        </div>
      </div>

      <List
        wrapperClassName={styles.contactsWrapper}
        childrenClass={styles.friendList}
      >
        {areContactsLoaded && getFilteredContacts().length === 0 && (
          <p className={styles.noContactsPlaceholder}>
            {t("messages.contactsPlaceholder")}
          </p>
        )}
        {getFilteredContacts().map((contact) => {
          return (
            <ListItem
              alt={contact.username}
              key={contact.id}
              avatarUrl={contact.avatar}
              primaryText={contact.username}
              secondaryText={<UserCategory category={contact.category} />}
              link={contact.link ? `/u/${contact.link}` : null}
              renderButtons={() => (
                <Button transparent to={`/messages/${contact.id}`}>
                  {t("membership.message")}
                </Button>
              )}
            />
          );
        })}
      </List>
    </>
  );

  return isDesktop ? (
    <SidebarBox
      contentClass={styles.container}
      withPadding={false}
      heading={(
        <>
          <UsersIcon />
          {t("users.users")}
          <UsersIcon />
        </>
      )}
    >
      {searchContactsModule}
    </SidebarBox>
  ) : (
    <Accordion
      heading={(
        <>
          {t("users.users")}
          <UsersIcon />
        </>
      )}
      hideable={!isDesktop}
    >
      {searchContactsModule}
    </Accordion>
  );
};

export default Contacts;
