import React from "react";
import styles from "./styles.module.css";

const SidebarBox = ({
  heading,
  children,
  buttons,
  contentClass = "",
  wrapperClass,
  withPadding = true,
}) => (
  <div className={`${styles.sidebarBox} ${wrapperClass}`}>
    <h2
      className={`${styles.heading} ${
        children || buttons ? styles.headingSeparator : ""
      }`}
    >
      {heading}
    </h2>
    {!!children && (
      <div
        className={`${styles.content} ${
          withPadding ? styles.paddedContent : ""
        } ${contentClass}`}
      >
        {children}
      </div>
    )}
    {!!buttons && <div className={styles.buttons}>{buttons}</div>}
  </div>
);

export default SidebarBox;
