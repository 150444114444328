import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.css";
import Button from "../../components/button/Button";
import DizzyIcon from "../../components/assets/emoticons/DizzyIcon";
import WorldMapIcon from "../../components/assets/emoticons/WorldMapIcon";

const ErrorView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className={styles.container}>
      <div className={styles.backgroundText}>
        <p className={styles.errorNumber}>404</p>
        <div className={styles.message}>
          <span className={styles.header}>
            <>
              <DizzyIcon />
              {t("404.header")}
              <DizzyIcon />
            </>
          </span>
          <span className={styles.subtext}>{t("404.subtextA")}</span>
          <span className={styles.subtext}>
            <>
              {t("404.subtextB")}
              <WorldMapIcon />
              !
            </>
          </span>
          <div className={styles.buttonBox}>
            <Button onClick={() => history.goBack()} primary>
              {t("404.getBack")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorView;
