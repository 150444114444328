import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../components/button/Button";
import LoaderWrapper from "../../components/loaders/LoaderWrapper";
import { followUser, unfollowUser } from "../../data/users/usersSlice";
import styles from "./styles.module.css";

const FollowButton = ({ isFollowing, isSubscriber, link }) => {
  const [isFocus, setFocus] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const unfollow = () => {
    setLoading(true);
    dispatch(unfollowUser({ link })).then(() => setLoading(false));
  };

  const follow = () => {
    setLoading(true);
    dispatch(followUser({ link })).then(() => setLoading(false));
  };

  return (
    <div className={styles.wrapper}>
      <LoaderWrapper isLoading={isLoading} isLight>
        <div
          className={styles.button}
          onMouseEnter={() => setFocus(true)}
          onMouseLeave={() => setFocus(false)}
        >
          {isFollowing ? (
            <>
              {isFocus && !isSubscriber ? (
                <Button
                  wrapperClass={styles.followingButton}
                  onClick={() => unfollow()}
                  warning
                >
                  {!isLoading && t("button.unfollow")}
                </Button>
              ) : (
                <Button
                  wrapperClass={styles.followingButton}
                  onClick={() => {}}
                  secondary
                >
                  {!isLoading && t("button.following")}
                </Button>
              )}
            </>
          ) : (
            <Button
              wrapperClass={styles.followingButton}
              onClick={() => (isLoggedIn ? follow() : history.push("/signup"))}
              secondary
            >
              {!isLoading && t("button.follow")}
            </Button>
          )}
        </div>
      </LoaderWrapper>
    </div>
  );
};

export default FollowButton;
