import React from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./styles.module.css";
import LoveIcon from "../assets/icons/LoveIcon";
import { mediumDevice } from "../assets/breakpoints/breakpoints";

const EmptyListWrapper = ({
  children,
  heading,
  subText,
  isEmpty,
  headingClass,
}) => {
  const isMobile = useMediaQuery(mediumDevice);
  const emptyList = (
    <div className={styles.emptyContainer}>
      <div className={`${styles.iconContainer} ${headingClass}`}>
        {!isMobile && (
          <div className={styles.icon}>
            <LoveIcon />
          </div>
        )}
        <div className={styles.textBox}>
          <span className={styles.textBoxHeader}>{heading}</span>
          <p className={styles.textBoxInfo}>{subText}</p>
        </div>
      </div>
      <div
        className={!isMobile ? styles.emptyWrapper : styles.emptyWrapperMobile}
      >
        {children}
      </div>
    </div>
  );
  return isEmpty ? emptyList : <div>{children}</div>;
};

export default EmptyListWrapper;
