import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../../../components/avatar/Avatar";
import styles from "./styles.module.css";

const Message = ({ avatar, text, isHost, link }) => {
  return (
    <div className={`${styles.messagesContainer} ${!isHost && styles.quest}`}>
      {link ? (
        <Link to={`/u/${link}`}>
          <Avatar src={avatar} size="small" />
        </Link>
      ) : (
        <Avatar src={avatar} size="small" />
      )}
      <div className={`${styles.msgField} ${isHost && styles.host}`}>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Message;
