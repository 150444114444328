import React from "react";

function CreateAccountIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#041E40"
        d="M8.42 7.439a.167.167 0 00-.042-.288c-.226-.086-.463-.174-.713-.267l-.418-.153a.4.4 0 01-.092-.208 1.715 1.715 0 01.038-.866 3.746 3.746 0 00.978-2.876C8.17 1.17 7.118 0 5.667 0 4.215 0 3.163 1.17 3.163 2.783a3.737 3.737 0 00.97 2.864c.096.283.113.586.049.878a.41.41 0 01-.09.206l-.42.154C2.04 7.485.861 7.92.501 8.636A6.116 6.116 0 000 11a.333.333 0 00.333.333h5.863a.167.167 0 00.166-.151 5.334 5.334 0 012.059-3.743z"
      />
      <path
        fill="#041E40"
        d="M11.667 7.333a4.333 4.333 0 100 8.667 4.333 4.333 0 000-8.667zm3 4.334a.666.666 0 01-.667.666h-1.333a.333.333 0 00-.334.334V14A.667.667 0 0111 14v-1.333a.333.333 0 00-.333-.334H9.333a.667.667 0 010-1.333h1.334a.333.333 0 00.333-.333V9.333a.667.667 0 011.333 0v1.334a.333.333 0 00.334.333H14a.667.667 0 01.667.667z"
      />
    </svg>
  );
}

export default CreateAccountIcon;
