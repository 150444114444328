import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getMembership } from "../../data/membership/membershipSlice";
import { unsubscribeUser } from "../../data/users/usersSlice";
import { signin } from "../../data/auth/authSlice";
import Modal from "../../components/modal/Modal";
import TextInput from "../../components/textInput/TextInput";
import List from "../../components/list/List";
import ListItem from "../../components/list/ListItem";
import Button from "../../components/button/Button";
import Loader from "../../components/loaders/Loader";
import UserCategory from "../../components/userCategory/UserCategory";
import Accordion from "../../components/accordion/Accordion";
import ShakeIcon from "../../components/assets/emoticons/ShakeIcon";
import SendIcon from "../../components/assets/emoticons/SendIcon";
import EmptyListWrapper from "../../components/list/EmptyListWrapper";
import ContextMenu from "../../components/contextMenu/ContextMenu";
import ContextMenuItem from "../../components/contextMenu/ContextMenuItem";
import styles from "./styles.module.css";

const MemberSubscribesItem = ({
  id,
  subscriptionId,
  username,
  link,
  avatar,
  category,
  subscriptionAmount,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const error = useSelector((state) => state.auth.errors.signin);

  const onSubmit = ({ email, password }) => {
    setLoading(true);
    dispatch(signin({ email, password })).then((resultAction) => {
      setLoading(false);
      if (signin.fulfilled.match(resultAction)) {
        dispatch(
          unsubscribeUser({
            starId: id,
            subscriptionId,
          })
        ).then(() => {
          dispatch(getMembership());
        });
      }
    });
  };

  const emailError = () => {
    if (error && error.message === "INVALID_EMAIL") {
      return t("signin.invalidEmail");
    }
    if (error && error.message === "EMAIL_NOT_FOUND") {
      return t("signin.emailNotFound");
    }
    if (error && error.message === "EMAIL_NOT_VERIFIED") {
      return t("signin.emailNotVerified");
    }
    return null;
  };

  const passwordError = () => {
    if (error && error.message === "INVALID_PASSWORD") {
      return t("signin.invalidPassword");
    }
    if (error && error.message === "MISSING_PASSWORD") {
      return t("signin.missingPassword");
    }
    if (error && error.message.startsWith("TOO_MANY_ATTEMPTS_TRY_LATER")) {
      return t("signin.tooManyAttempts");
    }
    return null;
  };

  return (
    <>
      <ListItem
        hideButtons
        link={`/u/${link}`}
        key={id}
        alt={username}
        avatarUrl={avatar}
        primaryText={username}
        secondaryText={(
          <>
            {subscriptionAmount}
            {"/"}
            {t("membership.month")}
            {", "}
            <UserCategory category={category} />
          </>
        )}
        mobileMenu={() => (
          <ContextMenu>
            <ContextMenuItem to={`/u/${link}`}>
              {t("membership.visit")}
            </ContextMenuItem>
            <ContextMenuItem onClick={() => setOpen(true)} warning>
              {t("membership.endSupport")}
            </ContextMenuItem>
          </ContextMenu>
        )}
        renderButtons={() => (
          <>
            <Button onClick={() => setOpen(true)} transparent warning>
              {t("membership.endSupport")}
            </Button>
            <Button primary to={`/u/${link}`}>
              {t("membership.visit")}
            </Button>
          </>
        )}
      />
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
        closeModal={() => setOpen(false)}
        size="medium"
        buttons={(
          <>
            <Button
              isLoading={isLoading}
              warning
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
            >
              {t("modalUnsubscribe.confirm")}
            </Button>
            <Button transparent onClick={() => setOpen(false)}>
              {t("modalUnsubscribe.cancel")}
            </Button>
          </>
        )}
      >
        <form
          className={styles.modalUnsubscribe}
          onSubmit={handleSubmit(onSubmit)}
        >
          <h1 className={styles.modalUnsubscribeHeading}>
            {t("modalUnsubscribe.title")}
          </h1>
          <p className={styles.modalUnsubscribeDescription}>
            {t("modalUnsubscribe.description")}
          </p>
          <TextInput
            name="email"
            type="email"
            ref={register}
            label={t("signin.email")}
            placeholder={t("signin.emailPlaceholder")}
            error={emailError()}
          />
          <TextInput
            name="password"
            type="password"
            ref={register}
            label={t("signin.password")}
            placeholder={t("signin.passwordPlaceholder")}
            error={passwordError()}
          />
        </form>
      </Modal>
    </>
  );
};

const MembershipSubscribes = ({ user, subscribes, isLoading, suggestions }) => {
  const { t } = useTranslation();

  return (
    <Accordion
      hideable
      hide={user.isCreator}
      heading={(
        <>
          {t("membership.peopleYouSupport")}
          <ShakeIcon />
        </>
      )}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <List childrenClass={styles.membershipWrapper}>
          <EmptyListWrapper
            isEmpty={!isLoading && subscribes.length === 0}
            heading={(
              <>
                {t("membership.supportfirstCreator")}
                <SendIcon />
              </>
            )}
            subText={t("membership.emptySupport")}
          >
            {subscribes.length !== 0
              ? subscribes.map((subscription) => (
                <MemberSubscribesItem
                  {...subscription}
                  key={subscription.id}
                />
                ))
              : !isLoading &&
                suggestions.map((suggestion) => (
                  <ListItem
                    link={`/u/${suggestion.link}`}
                    key={suggestion.id}
                    avatarUrl={suggestion.avatar}
                    alt={suggestion.username}
                    primaryText={suggestion.username}
                    secondaryText={
                      <UserCategory category={suggestion.category} />
                    }
                    renderButtons={() => (
                      <Button to={`/u/${suggestion.link}`} primary>
                        {t("membership.support")}
                      </Button>
                    )}
                  />
                ))}
          </EmptyListWrapper>
        </List>
      )}
    </Accordion>
  );
};

export default MembershipSubscribes;
