import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import currency from "currency.js";
import { TextBlock } from "react-placeholder/lib/placeholders";
import Image from "react-graceful-image";
import PostHeader from "./PostHeader";
import Button from "../button/Button";
import StarIcon from "../assets/emoticons/StarIcon";
import CameraIcon from "../assets/emoticons/CameraIcon";
import { mediumDevice } from "../assets/breakpoints/breakpoints";
import styles from "./styles.module.css";
import SubscribersOnlyContent from "../subscribersOnlyContent/SubscribersOnlyContent";

const Post = ({
  username,
  category,
  link,
  avatar,
  renderMenu,
  text,
  images = [],
  videos = [],
  isSubscriber,
  subscribersOnly,
  onUnlock = () => {},
  price,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(mediumDevice);
  const isPublic = !subscribersOnly;
  const hasPrivateAccess = subscribersOnly && isSubscriber;

  const renderImage = useCallback(
    (image) => {
      if (hasPrivateAccess) {
        return (
          <SubscribersOnlyContent asset={image}>
            {(url) => (
              <Image
                className={styles.image}
                src={url}
                key={url}
                placeholderColor="var(--lightGray)"
                noLazyLoad
              />
            )}
          </SubscribersOnlyContent>
        );
      }

      return (
        <Image
          className={styles.image}
          src={image.url}
          key={image.url}
          placeholderColor="var(--lightGray)"
          noLazyLoad
        />
      );
    },
    [hasPrivateAccess]
  );

  const renderVideo = useCallback(
    (video) => {
      if (video && hasPrivateAccess) {
        return (
          <SubscribersOnlyContent asset={video}>
            {(url) => (
              <video
                controls
                controlsList="nodownload"
                className={styles.video}
                src={url}
                key={url}
              />
            )}
          </SubscribersOnlyContent>
        );
      }

      if (video) {
        return (
          <video
            controls
            controlsList="nodownload"
            className={styles.video}
            src={video.url}
            key={video.url}
          />
        );
      }

      return (
        <div className={styles.videoPlaceholder}>
          <div>
            <StarIcon
              width="32px"
              height="32px"
              className={styles.videoPlaceholderIcon}
            />
            <CameraIcon
              width="32px"
              height="32px"
              className={styles.videoPlaceholderIcon}
            />
          </div>
          <p className={styles.videoPlaceholderText}>
            {t("post.premiumVideo")}
          </p>
        </div>
      );
    },
    [hasPrivateAccess, t]
  );

  return (
    <div className={styles.post}>
      <PostHeader
        link={link}
        username={username}
        category={category}
        avatar={avatar}
        renderMenu={renderMenu}
      />
      <div className={styles.content}>
        {!!text && !hasPrivateAccess && !isPublic && (
          <TextBlock rows={3} color="var(--lightGray)" />
        )}
        {((!!text && hasPrivateAccess) || (!!text && isPublic)) && (
          <p className={styles.text}>{text}</p>
        )}
        {!!images && !!images.length && (
          <div
            className={styles.images}
            onContextMenu={(event) => event.preventDefault()}
          >
            {images.map(renderImage)}
          </div>
        )}
        {!!videos && !!videos.length && (
          <div
            className={styles.videos}
            onContextMenu={(event) => event.preventDefault()}
          >
            {videos.map(renderVideo)}
          </div>
        )}
      </div>
      {subscribersOnly && (
        <div className={styles.subscribers}>
          {(!isMobile || (isMobile && isSubscriber)) && (
            <p className={styles.subscribersOnly}>
              {t("post.subscribersOnly")}
            </p>
          )}
          {!isSubscriber && (
            <Button primary onClick={onUnlock}>
              {t("post.unlockPosts", { username })} {currency(price).format()}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Post;
