import React from "react";
import styles from "./styles.module.css";

const Loader = ({ isLight }) => {
  return (
    <div className={`${styles.loader} ${isLight ? styles.loaderLight : ""}`}>
      <div className={styles.loaderOutside} />
      <div className={styles.loaderInside} />
    </div>
  );
};

export default Loader;
