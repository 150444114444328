import React from "react";

function StarIcon({ height = "1em", width = "1em" }) {
  return (
    <svg
      width={width}
      height={height}
      data-name="Component 1 – 1"
      viewBox="0 0 15.802 15.808"
    >
      <g fill="#041e40" data-name="Group 1" transform="translate(-939 -542)">
        <path
          d="M8 8a11.4 11.4 0 001.29-1.517c1.619-2.259 2.017-4.606.832-5.8C8.667-.77 5.457.165 2.808 2.811S-.768 8.67.686 10.124c1.036 1.036 2.967.858 4.946-.289A11.389 11.389 0 008 8zm-3.007.677c-1.5.884-2.82 1.045-3.362.507-.783-.783-.1-3.2 2.122-5.427S8.4.849 9.178 1.632c.5.5.4 1.688-.336 3.061a.086.086 0 01-.157-.013l-.37-1.143a.332.332 0 00-.632 0l-.934 2.868a.335.335 0 01-.318.232H3.413a.331.331 0 00-.2.6L5 8.532a.086.086 0 01-.008.142z"
          transform="translate(939 542)"
        />
        <path
          d="M10.312.876C9.186-.25 7 .056 4.849 1.48a.086.086 0 00.046.157h2.691a.331.331 0 01.2.6L5.341 4.012a.332.332 0 00-.121.371l.93 2.876a.331.331 0 01-.512.369L3.2 5.859a.322.322 0 00-.391 0L.327 7.66a.174.174 0 00-.069.111 2.812 2.812 0 00.622 2.541c1.455 1.459 4.665.525 7.313-2.122s3.574-5.859 2.119-7.314z"
          transform="translate(943.803 546.808)"
        />
      </g>
    </svg>
  );
}

export default StarIcon;
