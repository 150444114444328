import { createAsyncThunk, createAction, createSlice } from "@reduxjs/toolkit";
import authApi from "./authApi";
import { auth } from "../../firebase";

const initialState = {
  uid: null,
  isLoggedIn: false,
  email: null,
  idToken: null,
  errors: {
    signin: null,
    signup: null,
    initResetPassword: null,
    resetPassword: null,
  },
};

const signup = createAsyncThunk(
  "auth/signup",
  async ({ email, username, password }, { rejectWithValue }) => {
    try {
      return await authApi.signup(email, username, password).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const signin = createAsyncThunk(
  "auth/signin",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      return auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      return rejectWithValue({ payload: { error } });
    }
  }
);

const logout = createAsyncThunk(
  "auth/logout",
  async (options, { rejectWithValue }) => {
    try {
      return auth.signOut();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initResetPassword = createAsyncThunk(
  "auth/initResetPassword",
  async ({ email }, { rejectWithValue }) => {
    try {
      return await authApi.initResetPassword(email).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ newPassword, oobCode }, { rejectWithValue }) => {
    try {
      return await authApi.resetPassword(newPassword, oobCode).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async ({ oobCode }, { rejectWithValue }) => {
    try {
      return await authApi.verifyEmail(oobCode).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (options, { rejectWithValue }) => {
    try {
      // Disable underscore eslint error, due to firebase naming
      /* eslint-disable */
      const result = await authApi.refreshToken().json();
      const { refresh_token, id_token } = result;
      localStorage.setItem("idToken", id_token);
      localStorage.setItem("refreshToken", refresh_token);
      localStorage.setItem("tokenExpirationDate", Date.now() + 360000);
      /* eslint-enable */
      return result;
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const disableAccount = createAsyncThunk(
  "auth/disableAccount",
  async (options, { rejectWithValue }) => {
    try {
      const result = await authApi.disableAccount().json();
      localStorage.removeItem("idToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("tokenExpirationDate");
      return result;
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const clearErrors = createAction("auth/clearErrors");

const setAuthUser = createAction("auth/setAuthUser");
const setIdToken = createAsyncThunk(
  "auth/setIdToken",
  async (idToken, { rejectWithValue }) => {
    try {
      if (!idToken) {
        return localStorage.removeItem("idToken");
      }
      return localStorage.setItem("idToken", idToken);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearErrors: (state) => ({
      ...state,
      errors: {
        signin: null,
        signup: null,
        initResetPassword: null,
        resetPassword: null,
      },
    }),
    setAuthUser: (state, action) => ({
      ...state,
      uid: action.payload.uid,
      email: action.payload.email,
      isLoggedIn: action.payload.isLoggedIn,
    }),
  },
  extraReducers: {
    [setIdToken.fulfilled]: (state, action) => {
      state.idToken = action.meta.arg;
    },
    [signup.fulfilled]: (state) => ({
      ...state,
      errors: {
        ...state.errors,
        signup: null,
      },
    }),
    [signup.rejected]: (state, action) => ({
      ...state,
      errors: {
        ...state.errors,
        signup: action.payload,
      },
    }),
    [signin.fulfilled]: (state) => {
      state.errors.signin = null;
    },
    [signin.rejected]: (state, action) => {
      if (action.error) {
        state.errors.signin = action.error;
      }
    },
    [initResetPassword.rejected]: (state, action) => ({
      ...state,
      errors: {
        ...state.errors,
        initResetPassword: action.payload.error,
      },
    }),
    [initResetPassword.fulfilled]: (state) => ({
      ...state,
      errors: {
        ...state.errors,
        initResetPassword: null,
      },
    }),
    [resetPassword.rejected]: (state, action) => ({
      ...state,
      errors: {
        ...state.errors,
        resetPassword: action.payload.error,
      },
    }),
    [resetPassword.fulfilled]: (state) => ({
      ...state,
      errors: {
        ...state.errors,
        resetPassword: null,
      },
    }),
    [disableAccount.fulfilled]: () => ({
      ...initialState,
    }),
    [refreshToken.rejected]: () => ({
      ...initialState,
    }),
    [logout.fulfilled]: () => ({
      ...initialState,
    }),
  },
});

export {
  signup,
  signin,
  logout,
  initResetPassword,
  resetPassword,
  verifyEmail,
  refreshToken,
  disableAccount,
  clearErrors,
  setAuthUser,
  setIdToken,
};

export default authSlice.reducer;
