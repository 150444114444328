import React from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../../components/textInput/TextInput";
import AmazedIcon from "../../components/assets/emoticons/AmazedIcon";
import styles from "./styles.module.css";

const SettingsSocialMedia = ({ register, user }) => {
  const { t } = useTranslation();

  const findError = (fieldName) => {
    const field = user.errors.find((error) => {
      return error.param === fieldName;
    });
    return field ? field.msg : null;
  };

  if (!user.isCreator) {
    return null;
  }

  return (
    <div className={styles.box}>
      <h2 className={styles.heading}>
        {t("settings.socialMedia")} 
        {' '}
        <AmazedIcon />
      </h2>
      <p className={styles.description}>
        {t("settings.socialMediaDescription")}
      </p>
      <TextInput
        defaultValue={user.socialMedia.facebook}
        label={t("settings.facebook")}
        name="socialMedia.facebook"
        placeholder={t("settings.socialMediaPlaceholder")}
        ref={register}
        error={findError("socialMedia.facebook")}
      />
      <TextInput
        defaultValue={user.socialMedia.instagram}
        label={t("settings.instagram")}
        name="socialMedia.instagram"
        placeholder={t("settings.socialMediaPlaceholder")}
        ref={register}
        error={findError("socialMedia.instagram")}
      />
      <TextInput
        defaultValue={user.socialMedia.twitter}
        label={t("settings.twitter")}
        name="socialMedia.twitter"
        placeholder={t("settings.socialMediaPlaceholder")}
        ref={register}
        error={findError("socialMedia.twitter")}
      />
      <TextInput
        defaultValue={user.socialMedia.twitch}
        label={t("settings.twitch")}
        name="socialMedia.twitch"
        placeholder={t("settings.socialMediaPlaceholder")}
        ref={register}
        error={findError("socialMedia.twitch")}
      />
      <TextInput
        defaultValue={user.socialMedia.youtube}
        label={t("settings.youtube")}
        name="socialMedia.youtube"
        placeholder={t("settings.socialMediaPlaceholder")}
        ref={register}
        error={findError("socialMedia.youtube")}
      />
    </div>
  );
};

export default SettingsSocialMedia;
