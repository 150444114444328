import React from "react";

function ContactIcon({ height = "1em", width = "1em" }) {
  return (
    <>
      <svg width={width} height={height} fill="none" viewBox="0 0 16 16">
        <path
          fill="#041E40"
          d="M12.834 4.417a.167.167 0 00-.167-.167h-1a.167.167 0 00-.167.167V6.25a.5.5 0 01-1 0V.583a.5.5 0 00-.5-.5H6.333a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h3a.166.166 0 01.167.167v.833a.167.167 0 01-.167.167H3.2A2.867 2.867 0 00.333 7.117v3.8a.333.333 0 00.334.333h5.832a.167.167 0 01.167.167v3.833a.667.667 0 101.333 0v-3.833a.167.167 0 01.167-.167h4.5a.167.167 0 00.167-.167l.001-6.666zM15.585 6.031a.332.332 0 00-.252-.114H14a.167.167 0 00-.167.166v5a.167.167 0 00.167.167h.667a.333.333 0 00.333-.286l.667-4.667a.339.339 0 00-.082-.266z"
        />
      </svg>
    </>
  );
}

export default ContactIcon;
