import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import TextInput from "../../components/textInput/TextInput";
import styles from "./styles.module.css";
import Button from "../../components/button/Button";
import Popup from "../../components/popup/Popup";
import Layout from "../../components/layout/Layout";
import TextArea from "../../components/textArea/TextArea";
import MailBoxIxon from "../../components/assets/emoticons/MailBoxIcon";
import api from "../../data/api";

const ContactForm = () => {
  const { t } = useTranslation();
  const { register, reset, handleSubmit } = useForm();
  const formRef = useRef(null);
  const [errors, setErrors] = useState([]);
  const user = useSelector((state) => state.user);
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await api.post("contact", { json: data }).json();
      reset();
      setSuccess(true);
    } catch (error) {
      const result = await error.response.json();
      if (result.errors) {
        setErrors(result.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  const getFieldError = (field) => {
    const filteredErrors = errors.filter((error) => error.param === field);
    if (filteredErrors.length) {
      return filteredErrors[0].msg;
    }
    return null;
  };

  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.box}>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className={styles.headingContainer}>
                <h1 className={styles.heading}>{t("contact.heading")}</h1>
                <div className={styles.icon}>
                  <MailBoxIxon width="50px" height="60px" />
                </div>
              </div>
            </div>
            <p className={styles.description}>{t("contact.description")}</p>
            <TextInput
              ref={register}
              defaultValue={user.username}
              name="name"
              label={t("contact.nameLabel")}
              placeholder={t("contact.namePlaceholder")}
              error={getFieldError("name")}
            />
            <TextInput
              ref={register}
              defaultValue={user.email}
              name="email"
              label={t("contact.emailLabel")}
              placeholder={t("contact.emailPlaceholder")}
              error={getFieldError("email")}
            />
            <TextInput
              ref={register}
              name="subject"
              label={t("contact.subjectLabel")}
              placeholder={t("contact.subjectPlaceholder")}
              error={getFieldError("subject")}
            />

            <TextArea
              ref={register}
              name="message"
              label={t("contact.messageLabel")}
              placeholder={t("contact.messagePlaceholder")}
              error={getFieldError("message")}
            />
            <div className={styles.sendMessage}>
              <Button primary isLoading={isLoading} type="submit">
                {t("contact.sendMessage")}
              </Button>
              <p className={styles.disclaimer}>{t("contact.disclaimer")}</p>
            </div>
          </form>
        </div>
      </div>
      <Popup
        isOpen={isSuccess}
        onClose={() => {
          setSuccess(false);
        }}
        heading={t("contact.successHeading")}
        text={(
          <>
            {t("contact.successDescriptionFirst")}
            <br />
            {t("contact.successDescriptionSecond")}
          </>
        )}
        closeTimeoutMS={1}
      />
    </Layout>
  );
};

export default ContactForm;
