import React, { useRef, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import CustomScroll from "react-custom-scroll";
import styles from "./styles.module.css";
import SendMessageIcon from "../../../components/assets/icons/SendMessageIcon";
import LeftArrowIcon from "../../../components/assets/icons/LeftArrowIcon";
import UserOverview from "../../../components/userOverview/UserOverview";
import Layout from "../../../components/layout/Layout";
import Contacts from "../contacts/Contacts";
import Message from "./Message";
import {
  sendMessage,
  subscribeToConversation,
  initConversation,
  getConversations,
} from "../../../data/messages/messagesSlice";

const Conversation = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { register, reset, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [isActive, setActive] = useState(false);
  const user = useSelector((state) => state.user);
  const messageWindowRef = useRef(null);
  const textAreaRef = useRef(null);
  const conversation = useSelector((state) => {
    return Object.values(state.messages.conversations).find(
      (item) => item.members[id] && item.members[user.id]
    );
  });
  const conversationUserId = conversation?.user?.id;
  const messages = conversation ? conversation.messages : [];

  useEffect(() => {
    let unsubscribe;
    dispatch(getConversations({}));
    dispatch(initConversation({ receiverId: id })).then(
      async (resultAction) => {
        if (initConversation.fulfilled.match(resultAction)) {
          unsubscribe = await subscribeToConversation({
            userId: user.id,
            id: resultAction.payload.id,
            dispatch,
          });
        }
      }
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, id, user.id]);

  const onInput = useCallback(() => {
    textAreaRef.current.style.height = "";
    textAreaRef.current.style.height = `${
      textAreaRef.current.scrollHeight + 4
    }px`;
  }, [textAreaRef]);

  const onMessageSend = useCallback(
    ({ text }) => {
      if (text.replace(/\s/g, "") !== "") {
        dispatch(sendMessage({ text, receiverId: conversationUserId }));
      }
      reset({});
      onInput();
      setActive(false);
    },
    [dispatch, conversationUserId, onInput, reset]
  );

  const onKeyPress = useCallback(
    (event) => {
      if (event.which === 13 && !event.shiftKey) {
        handleSubmit(onMessageSend)();
        event.preventDefault();
      }
    },
    [handleSubmit, onMessageSend]
  );

  const onChange = useCallback((event) => {
    if (event.target.value === "") {
      setActive(false);
    } else {
      setActive(true);
    }
  }, []);

  return (
    <Layout pageTitle="Messages" left={<UserOverview />} right={<Contacts />}>
      <div className={styles.conversationContainer}>
        <div className={styles.heading}>
          <Link to="/messages">
            <LeftArrowIcon />
          </Link>
          <span className={styles.headingSpan}>
            {t("conversation.heading")}
            {" "}
            {conversation && conversation.user && (
              <>
                {conversation.user.link ? (
                  <Link to={`/u/${conversation.user.link}`}>
                    {conversation.user.username}
                  </Link>
                ) : (
                  conversation.user.username
                )}
              </>
            )}
          </span>
        </div>
        <CustomScroll flex="1" keepAtBottom>
          <div className={styles.messageFieldsContainer} ref={messageWindowRef}>
            {messages &&
              messages.map((message) => (
                <Message
                  key={message.createdAt}
                  text={message.text}
                  isHost={message.from !== user.id}
                  link={
                    message.from === user.id
                      ? user.link
                      : conversation.user.link
                  }
                  avatar={
                    message.from === user.id
                      ? user.avatar
                      : conversation.user.avatar
                  }
                />
              ))}
          </div>
        </CustomScroll>
        <form
          className={styles.messageInputContainer}
          onSubmit={handleSubmit(onMessageSend)}
        >
          <textarea
            ref={(ref) => {
              register(ref);
              textAreaRef.current = ref;
            }}
            onInput={onInput}
            onKeyPress={onKeyPress}
            onChange={onChange}
            name="text"
            placeholder="Your message..."
            className={styles.messageInput}
          />
          <button type="submit" className={styles.sendButton}>
            <SendMessageIcon
              fill={isActive ? "var(--primaryColor)" : "var(--gray)"}
              circle={isActive ? "var(--secondaryColor" : "var(--lightGray"}
            />
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default Conversation;
