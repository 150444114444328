import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const BlogPostSuggestions = ({ prev, next }) => (
  <div className={styles.suggestions}>
    <div className={styles.prevPost}>
      {prev && (
        <Link to={`/blog/post/${prev.slug}`}>
          <div className={styles.postSmall}>
            <div className={styles.content}>
              <h2 className={styles.title}>{prev.title}</h2>
              <div
                className={styles.excerpt}
                dangerouslySetInnerHTML={{ __html: prev.excerpt }}
              />
            </div>
            <div className={styles.imageWrapper}>
              <img src={prev.image.src} alt="" />
            </div>
          </div>
        </Link>
      )}
    </div>
    <div className={styles.prevPost}>
      {next && (
        <Link to={`/blog/post/${next.slug}`}>
          <div className={styles.postSmall}>
            <div className={styles.content}>
              <h2 className={styles.title}>{next.title}</h2>
              <div
                className={styles.excerpt}
                dangerouslySetInnerHTML={{ __html: next.excerpt }}
              />
            </div>
            <div className={styles.imageWrapper}>
              <img src={next.image.src} alt="" />
            </div>
          </div>
        </Link>
      )}
    </div>
  </div>
);

export default BlogPostSuggestions;
