import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import styles from "../styles.module.css";
import breakpoins from "../../assets/breakpoints/breakpoints";
import DropDown from "./DropDown";
import HamburgerMenu from "../../assets/icons/HamburgerMenu";
import UserPanel from "./UserPanel";

const ProfileContainer = ({ user }) => {
  const [isVisible, setVisibile] = useState(false);

  const isDesktop = useMediaQuery(breakpoins.desktop);

  const userMenu = !isDesktop ? (
    <button className={styles.button} onClick={() => setVisibile(!isVisible)}>
      <HamburgerMenu style={styles.hamburgerMenu} />
    </button>
  ) : (
    <UserPanel user={user} isVisible={isVisible} setVisibile={setVisibile} />
  );

  return (
    <>
      {userMenu}
      <DropDown
        isLoggedIn={user.username}
        setVisibile={setVisibile}
        isVisible={isVisible}
        user={user}
      />
    </>
  );
};

export default ProfileContainer;
