import React, { forwardRef } from "react";
import styles from "./styles.module.css";

const TextInput = forwardRef(
  (
    {
      name,
      label,
      value,
      onChange,
      placeholder,
      error,
      wrapperClassName = "",
      inputClassName = "",
      defaultValue,
      prefix,
      type = "text",
      ...rest
    },
    ref
  ) => (
    <div className={`${styles.wrapper} `}>
      {label && <p className={styles.label}>{label}</p>}
      <label
        className={`${styles.inputWrapper} ${wrapperClassName} ${
          error ? styles.withError : ""
        }`}
      >
        {prefix && <p className={styles.prefix}>{prefix}</p>}
        <input
          name={name}
          defaultValue={defaultValue}
          key={defaultValue || name}
          ref={ref}
          type={type}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          className={`${styles.input} ${inputClassName} ${
            prefix ? styles.prefixedInput : ""
          }`}
          {...rest}
        />
      </label>
      {!!error && <p className={styles.error}>{error}</p>}
    </div>
  )
);

export default TextInput;
