import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { desktop } from "../../components/assets/breakpoints/breakpoints";
import styles from "./styles.module.css";
import List from "../../components/list/List";
import ListItem from "../../components/list/ListItem";
import Button from "../../components/button/Button";
import Layout from "../../components/layout/Layout";
import UserOverview from "../../components/userOverview/UserOverview";
import Contacts from "./contacts/Contacts";
import Accordion from "../../components/accordion/Accordion";
import { getConversations } from "../../data/messages/messagesSlice";
import { ReactComponent as LastTextArrow } from "./svgs/lastTextArrow.svg";

const Messages = () => {
  const isDesktop = useMediaQuery(desktop);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const conversations = useSelector((state) =>
    Object.values(state.messages.conversations).sort(
      (conversationA, conversationB) =>
        conversationB.updatedAt - conversationA.updatedAt
    )
  );
  const isEmpty = conversations.length === 0;

  useEffect(() => {
    dispatch(getConversations({}));
  }, [dispatch]);

  if (!auth.isLoggedIn || !user.isLoaded) {
    return <Redirect to="/signin" />;
  }

  return (
    <Layout
      pageTitle="Messages"
      rightHideOnMobile={false}
      left={<UserOverview />}
      right={<Contacts />}
    >
      <Accordion
        heading={isEmpty ? t("messages.emptyMessages") : t("messages.list")}
        hideable={!isDesktop}
      >
        {isEmpty ? (
          <p className={styles.emptyInfo}>{t("messages.startMessages")}</p>
        ) : (
          <List childrenClass={!isEmpty ? styles.containerMessages : ""}>
            {conversations
              .filter((conversation) => conversation.lastText)
              .map((conversation) => {
                return (
                  <ListItem
                    onClick={() =>
                      history.push(`/messages/${conversation.user.id}`)}
                    highlight={
                      conversation.isUnread &&
                      conversation.lastTextAuthor !== user.userId
                    }
                    key={conversation.id}
                    avatarUrl={conversation.user.avatar}
                    alt={conversation.user.username}
                    primaryText={conversation.user.username}
                    secondaryText={(
                      <>
                        {conversation.lastTextAuthor === user.userId && (
                          <div className={styles.lastTextArrow}>
                            <LastTextArrow />
                          </div>
                        )}
                        {" "}
                        {conversation.lastText || ""}
                      </>
                    )}
                    renderButtons={() => (
                      <Button transparent>{t("messages.message")}</Button>
                    )}
                  />
                );
              })}
          </List>
        )}
      </Accordion>
    </Layout>
  );
};

export default Messages;
