import React, { forwardRef } from "react";
import styles from "./styles.module.css";

const TextArea = forwardRef(
  (
    {
      name,
      label,
      value,
      onChange,
      placeholder,
      error,
      wrapperClassName = "",
      defaultValue,
      ...rest
    },
    ref
  ) => (
    <div className={`${styles.wrapper} ${wrapperClassName}`}>
      {label && <label className={styles.label}>{label}</label>}
      <textarea
        name={name}
        defaultValue={defaultValue}
        key={defaultValue || name}
        ref={ref}
        type="text"
        value={value}
        placeholder={placeholder}
        className={`${styles.input} ${error ? styles.withError : ""}`}
        {...rest}
      />
      {!!error && <p className={styles.error}>{error}</p>}
    </div>
  )
);

export default TextArea;
