import React, { useRef, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  createTextPost,
  createPhotoPost,
  createVideoPost,
  uploadVideo,
} from "../../data/posts/postsSlice";
import Button from "../button/Button";
import PostHeader from "../post/PostHeader";
import CheckBox from "../checkBox/CheckBox";
import Popup from "../popup/Popup";
import LoaderWrapper from "../loaders/LoaderWrapper";
import textPostIcon from "./textPostIcon.svg";
import photoPostIcon from "./photoPostIcon.svg";
import videoPostIcon from "./videoPostIcon.svg";
import styles from "./styles.module.css";

const isBlank = (str) => {
  return !str || /^\s*$/.test(str);
};

const CreatePost = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [postType, setPostType] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(null);
  const [isDimensionsPopupOpen, setDimensionsPopupOpen] = useState(false);
  const [isFileSizePopupOpen, setFileSizePopupOpen] = useState(false);
  const { register, handleSubmit } = useForm();
  const photoInputRef = useRef(null);
  const videoInputRef = useRef(null);

  const changeType = (type) => {
    setPostType(type);
    if (type === "photo") {
      photoInputRef.current.click();
    }
    if (type === "video") {
      videoInputRef.current.click();
    }
    if (type === null) {
      setPreviewUrl(null);
      photoInputRef.current.value = "";
      videoInputRef.current.value = "";
    }
  };

  const onSubmit = (data) => {
    if (isUploading) {
      return;
    }
    const formData = new FormData();
    formData.append("subscribersOnly", data.subscribersOnly);
    if (postType === "text" && !isBlank(data.text)) {
      formData.append("text", data.text);
      setIsUploading(true);
      dispatch(createTextPost({ formData })).then(() => {
        setPostType(changeType(null));
        setIsUploading(false);
      });
    }
    if (postType === "photo" && data.photo.length) {
      formData.append("photo", data.photo[0]);
      setIsUploading(true);
      dispatch(createPhotoPost({ formData })).then(() => {
        photoInputRef.current.value = "";
        setPostType(changeType(null));
        setIsUploading(false);
      });
    }
    if (postType === "video" && data.video.length) {
      setIsUploading(true);
      dispatch(
        uploadVideo({
          subscribersOnly: data.subscribersOnly,
          file: data.video[0],
          userId: user.userId,
        })
      ).then((resultAction) => {
        if (uploadVideo.fulfilled.match(resultAction)) {
          dispatch(
            createVideoPost({
              subscribersOnly: data.subscribersOnly,
              path: resultAction.payload.fullPath,
            })
          ).then(() => {
            videoInputRef.current.value = "";
            setPostType(changeType(null));
            setIsUploading(false);
          });
        }
      });
    }
  };

  const onFileChange = (event, type) => {
    if (type === "photo") {
      const image = new Image();
      image.onload = () => {
        const dimensions = image.width / image.height;
        if (dimensions < 8 / 16) {
          setPreviewUrl(null);
          setDimensionsPopupOpen(true);
        }
      };
      image.src = URL.createObjectURL(event.target.files[0]);
    }
    if (type === "video") {
      const video = document.createElement("video");
      video.addEventListener(
        "loadedmetadata",
        () => {
          const dimensions = video.videoWidth / video.videoHeight;
          if (dimensions < 10 / 16) {
            setPreviewUrl(null);
            setDimensionsPopupOpen(true);
          }
        },
        false
      );
      video.src = URL.createObjectURL(event.target.files[0]);
    }
    if (
      event.target.files[0].size / 1024 / 1024 >
      parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10)
    ) {
      setPreviewUrl(null);
      setFileSizePopupOpen(true);
      return;
    }
    setPreviewUrl(URL.createObjectURL(event.target.files[0]));
  };
  const textAreaRef = useRef(null);
  const onInput = useCallback(() => {
    textAreaRef.current.style.height = "";
    textAreaRef.current.style.height = `${
      textAreaRef.current.scrollHeight + 2
    }px`;
  }, [textAreaRef]);
  return (
    <div className={styles.wrapper}>
      <PostHeader
        username={user.username}
        link={user.link}
        category={user.category}
        avatar={user.avatar}
      />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.content}>
        {postType === "photo" && previewUrl && (
          <LoaderWrapper isLoading={isUploading} isOpaque>
            <img
              className={styles.preview}
              src={previewUrl}
              alt={t("star.postPreview")}
            />
          </LoaderWrapper>
        )}
        {postType === "video" && previewUrl && (
          <LoaderWrapper isLoading={isUploading} isOpaque>
            <video
              className={styles.preview}
              src={previewUrl}
              controls={!isUploading}
              autoPlay
            />
          </LoaderWrapper>
        )}

        <input
          type="file"
          name="photo"
          onChange={(event) => onFileChange(event, "photo")}
          className={styles.fileInput}
          accept="image/jpeg, image/png"
          ref={(ref) => {
            photoInputRef.current = ref;
            register(ref);
          }}
        />
        <input
          type="file"
          name="video"
          onChange={(event) => onFileChange(event, "video")}
          className={styles.fileInput}
          accept="video/mp4,video/x-m4v,video/*"
          ref={(ref) => {
            videoInputRef.current = ref;
            register(ref);
          }}
        />
        {postType === "text" && (
          <textarea
            className={styles.textArea}
            onInput={onInput}
            ref={(ref) => {
              register(ref);
              textAreaRef.current = ref;
            }}
            name="text"
            label={t("star.yourMessage")}
          />
        )}
        {(postType === "text" || previewUrl) && (
          <div className={styles.publish}>
            <CheckBox
              ref={register}
              name="subscribersOnly"
              label={t("star.subscribersOnly")}
            />
            <div className={styles.buttons}>
              <Button
                isLoading={isUploading}
                primary
                type="submit"
                disabled={!!isUploading}
                onClick={() => {}}
              >
                {t("star.publish")}
              </Button>
              <Button onClick={() => changeType(null)} transparent>
                {t("star.cancel")}
              </Button>
            </div>
          </div>
        )}

        {postType !== "text" && !previewUrl && (
          <div className={styles.typeButtons}>
            <button
              className={styles.button}
              onClick={() => changeType("text")}
              type="button"
            >
              <img
                className={styles.buttonIcon}
                src={textPostIcon}
                alt={t("star.textPost")}
              />
              {t("star.addTextPost")}
            </button>
            <button
              className={styles.button}
              onClick={() => changeType("photo")}
              type="button"
            >
              <img
                className={styles.buttonIcon}
                src={photoPostIcon}
                alt={t("star.photoPost")}
              />
              {t("star.addPhotoPost")}
            </button>
            <button
              className={styles.button}
              onClick={() => changeType("video")}
              type="button"
            >
              <img
                className={styles.buttonIcon}
                src={videoPostIcon}
                alt={t("star.videoPost")}
              />
              {t("star.addVideoPost")}
            </button>
          </div>
        )}
      </form>
      <Popup
        warning
        size="small"
        isOpen={isDimensionsPopupOpen}
        withoutOverlay
        heading={t("star.dimensionsPopupHeading")}
        text={(
          <>
            {t("star.dimensionsPopupDescriptionFirst")}
            {" "}
            <Link to="/faq">{t("star.dimensionsPopupDescriptionLink")}</Link>
            {" "}
            {t("star.dimensionsPopupDescriptionSecond")}
          </>
        )}
      />
      <Popup
        warning
        size="small"
        isOpen={isFileSizePopupOpen}
        withoutOverlay
        heading={t("star.fileSizePopupHeading")}
        text={(
          <>
            {t("star.fileSizePopupDescriptionFirst")}
            {" "}
            <Link to="/faq">{t("star.fileSizePopupDescriptionLink")}</Link>
            {" "}
            {t("star.fileSizePopupDescriptionSecond")}
          </>
        )}
      />
    </div>
  );
};

export default CreatePost;
