import React from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import ReactConfetti from "react-confetti";
import styles from "./styles.module.css";

export default ({ isActive, ...rest }) => {
  const { width, height } = useWindowSize();
  return (
    <div className={styles.confetti}>
      <ReactConfetti
        recycle={isActive}
        width={width}
        height={height}
        numberOfPieces={500}
        gravity={0.1}
        colors={["#041E40", "#FFD000"]}
        drawShape={(ctx) => {
          ctx.beginPath();

          ctx.fillRect(0, 0, 8, 15);
          ctx.stroke();
          ctx.closePath();
        }}
        {...rest}
      />
    </div>
  );
};
