import React from "react";
import ReactModal from "react-modal";
import styles from "./styles.module.css";

const Popup = ({
  isOpen,
  onClose,
  heading,
  text,
  buttons,
  withoutOverlay,
  wrapperClass,
  size = "medium",
  warning,
  ...rest
}) => {
  const sizes = {
    small: "300px",
    medium: "320px",
    large: "410px",
  };
  const customStyles = {
    overlay: {
      backgroundColor: "transparent",
      position: withoutOverlay ? "static" : "fixed",
    },
    content: {
      position: withoutOverlay ? "fixed" : "absolute",
      maxWidth: sizes[size],
    },
  };
  return (
    <ReactModal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={onClose}
      closeTimeoutMS={500}
      overlayClassName={styles.overlay}
      className={{
        base: styles.base,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      style={customStyles}
      ariaHideApp={false}
      {...rest}
    >
      <div
        className={`${styles.popup} ${wrapperClass} ${
          warning ? styles.warning : ""
        }`}
      >
        <div className={styles.main}>
          <h2 className={styles.heading}>{heading}</h2>
          {!!text && <p className={styles.text}>{text}</p>}
        </div>
        {buttons && <div className={styles.buttons}>{buttons}</div>}
      </div>
    </ReactModal>
  );
};

export default Popup;
