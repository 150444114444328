import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Arrow from "./Arrow";
import styles from "../styles.module.css";
import Button from "../../button/Button";
import Avatar from "../../avatar/Avatar";
import IconComponent from "../../assets/IconComponent";
import StarIcon from "../../assets/icons/StarIcon";
import breakpoints from "../../assets/breakpoints/breakpoints";
import MessageIcon from "../../assets/icons/MessageIcon";
import { getUnreadConversationsCount } from "../../../data/messages/messagesSlice";
import { firestore } from "../../../firebase";

const UserPanel = ({ user, setVisibile, isVisible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery(breakpoints.smallDevice);
  const isLargeDesktop = useMediaQuery(breakpoints.largeDesktop);
  const { unreadConversationsCount } = useSelector((state) => state.messages);
  const auth = useSelector((state) => state.auth);

  const MessageText = isLargeDesktop && (
    <p className={styles.iconText}>{t("header.messages")}</p>
  );
  const CreatorText = isLargeDesktop && (
    <p className={styles.iconText}>{t("header.yourPage")}</p>
  );
  const { avatar, username, isCreator, link } = user;

  useEffect(() => {
    let unsubscribe;
    if (auth.isLoggedIn) {
      dispatch(getUnreadConversationsCount({}));
      const conversations = firestore.collection("conversations");
      unsubscribe = conversations.onSnapshot(() => {
        dispatch(getUnreadConversationsCount({}));
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user.email, auth.isLoggedIn, dispatch]);

  const userPanelLoggedIn = (
    <>
      {isCreator && link && !isMobile && (
        <Link to={`/u/${link}`}>
          <IconComponent
            style={styles.profileContainerItem}
            icon={(
              <div className={styles.iconWrapper}>
                <StarIcon height="24px" width="24px" />
              </div>
            )}
            text={CreatorText}
            size="large"
          />
        </Link>
      )}
      {!isMobile && (
        <Link to="/messages">
          <IconComponent
            style={`${styles.profileContainerItem} ${
              unreadConversationsCount > 0 ? styles.unreadMessages : ""
            }`}
            icon={(
              <div className={styles.iconWrapper}>
                <MessageIcon height="24px" width="24px" />
              </div>
            )}
            text={MessageText}
            size="large"
          />
        </Link>
      )}

      <div
        className={styles.userMenu}
        tabIndex={0}
        role="button"
        onMouseEnter={() => setVisibile(true)}
        onMouseLeave={() => setVisibile(false)}
        onClick={() => {
          if (isCreator && link) {
            history.push(`/u/${link}`);
          }
        }}
      >
        <Avatar alt={username} src={avatar} />
        <span className={styles.username}>{username}</span>
        <div
          className={`${styles.arrow} ${isVisible ? styles.arrowHover : ""}`}
        >
          <Arrow />
        </div>
      </div>
    </>
  );

  const userPanelLoggedOut = (
    <>
      <Button to="/signin" transparent>
        {t("header.login")}
      </Button>
      <Button to="/signup" primary>
        {t("header.createAccount")}
      </Button>
    </>
  );

  const currentUser = user.username ? userPanelLoggedIn : userPanelLoggedOut;
  return currentUser;
};

export default UserPanel;
