import React, { forwardRef } from "react";
import styles from "./styles.module.css";

const Select = forwardRef(
  ({ name, label, onChange, children, error, defaultValue, ...rest }, ref) => (
    <div className={`${styles.selectWrapper} `}>
      {label && <p className={styles.label}>{label}</p>}
      <select
        name={name}
        defaultValue={defaultValue}
        key={defaultValue || name}
        ref={ref}
        type="text"
        className={styles.select}
        onChange={onChange}
        {...rest}
      >
        {children}
      </select>
      {!!error && <p className={styles.error}>{error}</p>}
    </div>
  )
);

export default Select;
