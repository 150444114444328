import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "../../components/accordion/Accordion";
import Layout from "../../components/layout/Layout";
import { getFAQ } from "../../data/docs/docsSlice";
import styles from "./styles.module.css";

const Faq = () => {
  const dispatch = useDispatch();
  const faq = useSelector((state) => state.docs.faq);
  useEffect(() => {
    dispatch(getFAQ());
  }, [dispatch]);

  return (
    <Layout pageTitle="FAQ">
      {faq.items.map((item) => (
        <Accordion hide hideable heading={item.question} key={item.question}>
          <div
            className={styles.faqAnswer}
            dangerouslySetInnerHTML={{ __html: item.answer }}
          />
        </Accordion>
      ))}
    </Layout>
  );
};

export default Faq;
