import React from "react";

function FeedIcon({ height = "1em", width = "1em" }) {
  return (
    <>
      <svg width={width} height={height} fill="none" viewBox="0 0 16 16">
        <path
          fill="#041E40"
          d="M.529.524a.667.667 0 00-.196.471V8.34A.667.667 0 001 9.005h5.333A.667.667 0 007 8.34V1.005A.667.667 0 006.333.34L1 .329a.667.667 0 00-.471.195zM15 .329L9.667.338A.667.667 0 009 1.005v3.333a.667.667 0 00.667.667H15a.667.667 0 00.667-.667V.995A.667.667 0 0015 .33zM6.805 15.476A.667.667 0 007 15.005V11.67a.667.667 0 00-.667-.666H1a.667.667 0 00-.667.666v3.324A.666.666 0 001 15.66l5.333.01c.177 0 .347-.07.472-.195zM15 7.005H9.667A.667.667 0 009 7.672v7.333a.667.667 0 00.667.667l5.333-.01a.666.666 0 00.667-.666V7.672A.667.667 0 0015 7.005z"
        />
      </svg>
    </>
  );
}

export default FeedIcon;
