import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/loaders/Loader";
import Layout from "../../components/layout/Layout";
import { getPrivacyPolicy } from "../../data/docs/docsSlice";
import styles from "./styles.module.css";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.docs.privacyPolicy);
  useEffect(() => {
    dispatch(getPrivacyPolicy());
  }, [dispatch]);

  return (
    <Layout pageTitle={data ? data.title : ""}>
      <div className={styles.doc}>
        <h1 className={styles.docTitle}>{data ? data.title : ""}</h1>
        {data ? (
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: data.content }}
          />
        ) : (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
