import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import docsApi from "./docsApi";

const initialState = {
  privacyPolicy: null,
  termsAndConditions: null,
  dmca: null,
  2257: null,
  faq: { items: [] },
};

const get2257 = createAsyncThunk(
  "docs/2257",
  async (options, { rejectWithValue }) => {
    try {
      return await docsApi.getDoc("2257").json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const getDMCA = createAsyncThunk(
  "docs/dmca",
  async (options, { rejectWithValue }) => {
    try {
      return await docsApi.getDoc("dmca").json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const getPrivacyPolicy = createAsyncThunk(
  "docs/getPrivacyPolicy",
  async (options, { rejectWithValue }) => {
    try {
      return await docsApi.getDoc("privacyPolicy").json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const getTermsAndConditions = createAsyncThunk(
  "docs/getTermsAndConditions",
  async (options, { rejectWithValue }) => {
    try {
      return await docsApi.getDoc("termsAndConditions").json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const getFAQ = createAsyncThunk(
  "docs/getFAQ",
  async (options, { rejectWithValue }) => {
    try {
      return await docsApi.getDoc("faq").json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

export const docsSlice = createSlice({
  name: "docs",
  initialState,
  reducers: {},
  extraReducers: {
    [getPrivacyPolicy.fulfilled]: (state, action) => ({
      ...state,
      privacyPolicy: action.payload,
    }),
    [getTermsAndConditions.fulfilled]: (state, action) => ({
      ...state,
      termsAndConditions: action.payload,
    }),
    [getFAQ.fulfilled]: (state, action) => ({
      ...state,
      faq: action.payload,
    }),
    [getDMCA.fulfilled]: (state, action) => ({
      ...state,
      dmca: action.payload,
    }),
    [get2257.fulfilled]: (state, action) => ({
      ...state,
      2257: action.payload,
    }),
  },
});

export { getPrivacyPolicy, getTermsAndConditions, getFAQ, get2257, getDMCA };

export default docsSlice.reducer;
