import React from "react";

function PayoutsIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M12.1667 15H0.5C0.367392 15 0.240215 15.0527 0.146447 15.1464C0.0526784 15.2402 0 15.3674 0 15.5C0 15.6326 0.0526784 15.7598 0.146447 15.8536C0.240215 15.9473 0.367392 16 0.5 16H12.1667C12.2993 16 12.4265 15.9473 12.5202 15.8536C12.614 15.7598 12.6667 15.6326 12.6667 15.5C12.6667 15.3674 12.614 15.2402 12.5202 15.1464C12.4265 15.0527 12.2993 15 12.1667 15Z"
          fill="#041E40"
        />
        <path
          d="M1.00002 6.33333C0.911615 6.33333 0.82683 6.36845 0.764318 6.43096C0.701806 6.49347 0.666687 6.57826 0.666687 6.66667V14.1667C0.666687 14.2109 0.684247 14.2533 0.715503 14.2845C0.746759 14.3158 0.789151 14.3333 0.833354 14.3333H3.16669C3.21089 14.3333 3.25328 14.3158 3.28454 14.2845C3.31579 14.2533 3.33335 14.2109 3.33335 14.1667V6.66667C3.33335 6.57826 3.29823 6.49347 3.23572 6.43096C3.17321 6.36845 3.08843 6.33333 3.00002 6.33333H1.00002Z"
          fill="#041E40"
        />
        <path
          d="M4.66669 14.1667C4.66669 14.2109 4.68425 14.2533 4.7155 14.2845C4.74676 14.3158 4.78915 14.3333 4.83335 14.3333H7.16669C7.21089 14.3333 7.25328 14.3158 7.28454 14.2845C7.31579 14.2533 7.33335 14.2109 7.33335 14.1667V9.83334C7.33335 9.64934 7.18402 9.66667 7.00002 9.66667H5.00002C4.91161 9.66667 4.82683 9.70179 4.76432 9.7643C4.70181 9.82682 4.66669 9.9116 4.66669 10V14.1667Z"
          fill="#041E40"
        />
        <path
          d="M11.1667 14.3333C11.2109 14.3333 11.2533 14.3158 11.2845 14.2845C11.3158 14.2533 11.3334 14.2109 11.3334 14.1667V11.3333C11.3334 11.1493 11.184 11.1667 11 11.1667H9.00002C8.91161 11.1667 8.82683 11.2018 8.76432 11.2643C8.70181 11.3268 8.66669 11.4116 8.66669 11.5V14.1667C8.66669 14.2109 8.68425 14.2533 8.7155 14.2845C8.74676 14.3158 8.78915 14.3333 8.83335 14.3333H11.1667Z"
          fill="#041E40"
        />
        <path
          d="M14.1226 7.56066C14.0948 7.53303 14.0777 7.49645 14.0743 7.45741C14.0708 7.41836 14.0814 7.37937 14.1039 7.34733C14.7749 6.39158 15.0632 5.219 14.9118 4.06108C14.7605 2.90317 14.1805 1.84408 13.2864 1.0929C12.3923 0.341724 11.2491 -0.0469424 10.0824 0.00363493C8.91574 0.0542122 7.81043 0.540357 6.9847 1.36609C6.15896 2.19182 5.67282 3.29713 5.62224 4.4638C5.57166 5.63047 5.96033 6.77371 6.71151 7.6678C7.46268 8.5619 8.52177 9.14187 9.67969 9.29323C10.8376 9.44458 12.0102 9.15631 12.9659 8.48533C12.998 8.46262 13.0371 8.45197 13.0762 8.45527C13.1154 8.45856 13.1521 8.47558 13.1799 8.50333L14.8619 10.186C14.9877 10.3074 15.1561 10.3746 15.3309 10.3731C15.5057 10.3716 15.6729 10.3015 15.7965 10.1779C15.9201 10.0543 15.9902 9.88706 15.9917 9.71226C15.9932 9.53746 15.926 9.36906 15.8046 9.24333L14.1226 7.56066ZM10.2859 7.99999C9.62666 7.99999 8.9822 7.8045 8.43403 7.43823C7.88587 7.07195 7.45863 6.55136 7.20634 5.94227C6.95404 5.33318 6.88803 4.66296 7.01665 4.01636C7.14527 3.36976 7.46274 2.77581 7.92891 2.30964C8.39509 1.84346 8.98903 1.52599 9.63563 1.39738C10.2822 1.26876 10.9525 1.33477 11.5615 1.58706C12.1706 1.83935 12.6912 2.2666 13.0575 2.81476C13.4238 3.36292 13.6193 4.00739 13.6193 4.66666C13.6182 5.55039 13.2667 6.39762 12.6418 7.02251C12.0169 7.64741 11.1697 7.99894 10.2859 7.99999Z"
          fill="#041E40"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PayoutsIcon;
