import React from "react";
import Avatar from "../avatar/Avatar";
import styles from "./styles.module.css";

const SearchResult = ({
  primaryText,
  secondaryText,
  avatarUrl,
  onMouseDown,
}) => {
  return (
    <div className={styles.searchResult} onMouseDown={onMouseDown}>
      <Avatar src={avatarUrl} />
      <div className={styles.infoTexts}>
        <span className={styles.primaryText}>{primaryText}</span>
        <span className={styles.secondaryText}>{secondaryText}</span>
      </div>
    </div>
  );
};
export default SearchResult;
