import * as React from "react";

function EnvelopePigeon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#pigeonprefix__clip0)" fill="#7B7B7B">
        <path d="M9.215 5.8A9.302 9.302 0 007.345.977a.661.661 0 00-.92-.124.667.667 0 00-.212.263c-.778 1.727-1.1 2.927-.959 3.57 1.51-.344 2.996.472 3.961 1.114z" />
        <path d="M14.067 13.762a.164.164 0 01-.017-.153.167.167 0 01.117-.102 3.116 3.116 0 001.667-.875.665.665 0 00-.242-1.06 15.055 15.055 0 01-2.896-1.846 44.3 44.3 0 01-.884-.696c2.368-1.097 3.744-3.23 4.178-6.18a.667.667 0 00-.87-.734c-2.677.897-4.044 1.698-4.272 2.382-.237.713-.824 2.11-1.154 2.879a8.122 8.122 0 00-3.132-1.71 2.18 2.18 0 00-1.842.36 2.484 2.484 0 00-.886 1.83 6.92 6.92 0 00-.52.681l-.83-.195a.667.667 0 00-.76.373L.057 12.383a.667.667 0 00.454.925l5.667 1.333a.667.667 0 00.76-.373l.72-1.585a.167.167 0 01.238-.074l.1.059c.466.272.96.495 1.471.665a.163.163 0 01.087.067l1.053 1.579a.667.667 0 101.11-.74l-.193-.288a.165.165 0 01.058-.239.167.167 0 01.088-.02c.308.013.534.013.667.013a.167.167 0 01.14.074l.8 1.2a.667.667 0 101.11-.74l-.32-.477zm-12.46-1.566l.9-1.98 1.066 1.708a.502.502 0 00.582.21l2.696-.9-.9 1.984-4.344-1.022zm5.06-4.704a.667.667 0 11-1.333 0 .667.667 0 011.333 0zm-3.14 2.467l2.342.551-1.653.551-.69-1.102z" />
      </g>
      <defs>
        <clipPath id="pigeonprefix__clip0">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoEnvelopePigeon = React.memo(EnvelopePigeon);
export default MemoEnvelopePigeon;
