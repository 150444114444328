import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { followUser, unfollowUser } from "../users/usersSlice";
import { logout, disableAccount, refreshToken } from "../auth/authSlice";
import userApi from "./userApi";
import { auth, firestore, storage } from "../../firebase";

const initialState = {
  isLoaded: false,
  avatar: null,
  userId: null,
  username: null,
  email: null,
  isCreator: false,
  link: null,
  following: {},
  followers: {},
  creatorVerification: null,
  settings: {
    category: null,
    isAdultContent: null,
    notifyAboutFans: null,
    sendEmailMarketing: null,
    showAdultContent: null,
  },
  cards: [],
  payments: {
    balance: null,
    planId: null,
    planPrice: null,
    productId: null,
  },
  subscribes: {},
  subscribers: {},
  socialMedia: {
    facebook: null,
    instagram: null,
    twitch: null,
    twitter: null,
    youtube: null,
  },
  errors: [],
};

const resetUser = createAction("user/resetUser");

const updateAvatar = createAsyncThunk(
  "user/updateAvatar",
  async ({ formData }, { rejectWithValue }) => {
    try {
      return await userApi.updateAvatar(formData).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const getCurrentUser = createAsyncThunk(
  "user/getCurrentUser",
  async (options, { rejectWithValue }) => {
    try {
      return await userApi.getCurrentUser().json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const updateCurrentUser = createAsyncThunk(
  "user/updateCurrentUser",
  async ({ data }, { rejectWithValue }) => {
    try {
      return await userApi.updateCurrentUser(data).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const deleteAvatar = createAsyncThunk(
  "user/deleteAvatar",
  async (options, { rejectWithValue }) => {
    try {
      return await userApi.deleteAvatar().json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const becomeCreator = createAsyncThunk(
  "user/becomeCreator",
  async ({ data, productId, planId }, { rejectWithValue }) => {
    try {
      const imagesUploadPromises = data.images.map((file) =>
        storage
          .ref()
          .child(`verificationImages/${auth.currentUser.uid}/${uuid()}`)
          .put(file)
      );
      const uploadedImages = await Promise.all(imagesUploadPromises);
      return await userApi
        .becomeCreator({
          ...data,
          productId,
          planId,
          images: uploadedImages.map((snapshot) => snapshot.metadata.fullPath),
        })
        .json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const getCreatorVerification = createAsyncThunk(
  "user/getCreatorVerification",
  async ({ userId }) => {
    try {
      const snapshot = await firestore
        .collection("verification")
        .doc(userId)
        .get();

      if (snapshot.exists) {
        return snapshot.data();
      }

      return null;
    } catch (e) {
      return null;
    }
  }
);

const updatePlanPrice = createAsyncThunk(
  "user/updatePlanPrice",
  async ({ price }, { rejectWithValue }) => {
    try {
      return await userApi.updatePlanPrice({ price }).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const addCard = createAsyncThunk(
  "user/addCard",
  async (
    {
      cardholderName,
      number,
      expMonth,
      expYear,
      cvc,
      country,
      street,
      city,
      state,
      postalCode,
    },
    { rejectWithValue }
  ) => {
    try {
      return await userApi
        .addCard({
          cardholderName,
          number,
          expMonth,
          expYear,
          cvc,
          country,
          street,
          city,
          state,
          postalCode,
        })
        .json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const removeCard = createAsyncThunk(
  "user/removeCard",
  async ({ id }, { rejectWithValue }) => {
    try {
      return await userApi.removeCard({ id }).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const listCards = createAsyncThunk(
  "user/listCards",
  async (options, { rejectWithValue }) => {
    try {
      return await userApi.listCards().json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const deleteAccount = createAsyncThunk(
  "user/deleteAccount",
  async (options, { rejectWithValue }) => {
    try {
      const result = await userApi.deleteAccount().json();
      localStorage.removeItem("idToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("tokenExpirationDate");
      return result;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const clearErrors = createAction("user/clearErrors");

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearErrors: (state) => ({
      ...state,
      errors: [],
    }),
    resetUser: (state) => ({
      ...initialState,
    }),
  },
  extraReducers: {
    [updateAvatar.fulfilled]: (state, action) => ({
      ...state,
      avatar: action.payload.avatar,
    }),
    [deleteAvatar.fulfilled]: (state) => ({
      ...state,
      avatar: null,
    }),
    [getCurrentUser.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      isLoaded: true,
    }),
    [updateCurrentUser.pending]: (state) => ({
      ...state,
      errors: [],
    }),
    [updateCurrentUser.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      errors: [],
    }),
    [updateCurrentUser.rejected]: (state, action) => ({
      ...state,
      errors: action.payload.errors || [],
    }),
    [becomeCreator.pending]: (state) => ({
      ...state,
      errors: [],
    }),
    [becomeCreator.rejected]: (state, action) => ({
      ...state,
      ...(action.payload.errors ? { errors: action.payload.errors } : {}),
    }),
    [becomeCreator.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [followUser.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload.user,
    }),
    [unfollowUser.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload.user,
    }),
    [logout.fulfilled]: () => ({
      ...initialState,
    }),
    [refreshToken.rejected]: () => ({
      ...initialState,
    }),
    [disableAccount.fulfilled]: () => ({
      ...initialState,
    }),
    [updatePlanPrice.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [getCreatorVerification.fulfilled]: (state, action) => ({
      ...state,
      creatorVerification: action.payload,
    }),
    [addCard.fulfilled]: (state) => ({
      ...state,
    }),
    [listCards.fulfilled]: (state, action) => ({
      ...state,
      cards: action.payload.list,
    }),
    [deleteAccount.fulfilled]: () => ({
      ...initialState,
    }),
  },
});

export {
  resetUser,
  getCreatorVerification,
  updateAvatar,
  deleteAvatar,
  getCurrentUser,
  updateCurrentUser,
  becomeCreator,
  updatePlanPrice,
  clearErrors,
  listCards,
  addCard,
  removeCard,
  deleteAccount
};

export default userSlice.reducer;
