import React from "react";
import { useTranslation } from "react-i18next";
import List from "../../components/list/List";
import ListItem from "../../components/list/ListItem";
import Button from "../../components/button/Button";
import Loader from "../../components/loaders/Loader";
import Accordion from "../../components/accordion/Accordion";
import HeartIcon from "../../components/assets/emoticons/HeartIcon";
import styles from "./styles.module.css";

const MembershipFollowers = ({ followers, user, isLoading }) => {
  const { t } = useTranslation();

  if (!user.isCreator) {
    return null;
  }

  return (
    <Accordion
      hideable
      hide
      heading={(
        <>
          {t("membership.yourFollowers")}
          <HeartIcon />
        </>
      )}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <List childrenClass={styles.membershipWrapper}>
          {followers.length !== 0 &&
            followers.map((follower) => (
              <ListItem
                link={follower.link ? `/u/${follower.link}` : undefined}
                key={follower.id}
                alt={follower.username}
                avatarUrl={follower.avatar}
                primaryText={follower.username}
                secondaryText={
                  follower.category
                    ? `${t(`categories.${follower.category}`)} ${t(
                        "common.creator"
                      )}`
                    : t("common.basicUser")
                }
                renderButtons={() => (
                  <Button transparent to={`/messages/${follower.id}`}>
                    {t("membership.message")}
                  </Button>
                )}
              />
            ))}
        </List>
      )}
    </Accordion>
  );
};

export default MembershipFollowers;
