import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CheckBox from "../../checkBox/CheckBox";
import CostsTable from "../../costsTable/CostsTable";
import Button from "../../button/Button";
import MaskedTextInput from "../../textInput/MaskedTextInput";
import TextInput from "../../textInput/TextInput";
import CreditCard from "../../creditCard/CreditCard";
import Modal from "../Modal";

import { listCards, addCard } from "../../../data/user/userSlice";
import { subscribeUser } from "../../../data/users/usersSlice";
import LockerIcon from "../../assets/emoticons/LockerIcon";
import CryingIcon from "../../assets/emoticons/CryingIcon";
import styles from "./styles.module.css";

const ModalSubscribe = ({
  isOpen,
  onSuccess = () => {},
  closeModal,
  link,
  planPrice,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const cards = useSelector((state) => state.user.cards) || [];
  const [isAccepted, setAccepted] = useState(false);
  const [isDeclined, setDeclined] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const formRef = useRef(null);
  const [error, setError] = useState({});
  const [cardState, setCardState] = useState({
    firstName: null,
    secondName: null,
    number: null,
    validThru: null,
    cvc: null,
  });
  const { firstName, secondName, number, validThru, cvc } = cardState;

  const handleChange = (event) => {
    setCardState({ ...cardState, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    dispatch(listCards());
  }, [dispatch]);

  useEffect(() => {
    setDeclined(false);
  }, [isOpen]);

  const onManageCards = () => {
    history.push("/settings");
  };

  const findError = (fieldName) => {
    if (!error) {
      return null;
    }
    if (fieldName === "number") {
      return [
        "invalid_number",
        "expired_card",
        "insufficient_funds",
        "lost_or_stolen",
        "suspected_fraud",
        "limit_exceeded",
        "card_declined",
        "processing_error",
        "blacklisted",
      ].includes(error.code)
        ? error.message
        : null;
    }
    if (fieldName === "validThru") {
      return ["invalid_expiry_month", "invalid_expiry_year"].includes(
        error.code
      )
        ? error.message
        : null;
    }
    if (fieldName === "cvc") {
      return ["incorrect_cvc", "incorrect_zip"].includes(error.code)
        ? error.message
        : null;
    }
    return null;
  };

  const createSubscription = (token) => {
    dispatch(subscribeUser({ link, token })).then((resultAction) => {
      setLoading(false);
      if (subscribeUser.rejected.match(resultAction)) {
        setDeclined(true);
      }
      if (subscribeUser.fulfilled.match(resultAction)) {
        onSuccess();
        closeModal();
      }
    });
  };

  const onCreateCardToken = (token) => {
    if (!token.error) {
      window.SecurionPay.verifyThreeDSecure(
        {
          amount: 100,
          currency: "USD",
          card: token.id,
        },
        (token) => {
          console.log(token);
          createSubscription(token.id);
        }
      );
      // dispatch(
      //   addCard({
      //     token: token.id,
      //   })
      // ).then((resultAction) => {
      //   setCardLoading(false);
      //   if (addCard.rejected.match(resultAction)) {
      //     if (resultAction.payload && resultAction.payload.error) {
      //       setError(resultAction.payload.error);
      //     }
      //   }
      //   dispatch(listCards());
      // });
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    window.Securionpay.createCardToken(formRef.current, onCreateCardToken);
  };

  if (isDeclined) {
    return (
      <Modal
        heading={
          <>
            <CryingIcon /> {t("modalSubscribe.declinedHeading")} <CryingIcon />
          </>
        }
        buttons={
          <>
            <Button secondary onClick={() => setDeclined(false)}>
              Try again
            </Button>
            <Button transparent onClick={closeModal}>
              Cancel
            </Button>
          </>
        }
        isOpen={isOpen}
        onRequestClose={closeModal}
        closeModal={closeModal}
        size="big"
        text={t("modalSubscribe.declinedDescription")}
      />
    );
  }

  return (
    <Modal
      heading={
        <>
          <LockerIcon /> {t("modalSubscribe.subscribe")} <LockerIcon />
        </>
      }
      isOpen={isOpen}
      onRequestClose={closeModal}
      closeModal={closeModal}
      size="big"
    >
      <form ref={formRef} onSubmit={onSubmit} className={styles.modalSubscribe}>
        <p className={styles.intro}>{t("modalSubscribe.intro")}</p>
        <div className={styles.cards}>
          {cards.map((card) => (
            <CreditCard
              key={card.id}
              id={card.id}
              number={card.number}
              brand={card.brand}
              first6={card.first6}
              onAction={onManageCards}
              actionText={t("card.manageCards")}
            />
          ))}
          {!cards.length && (
            <>
              <div className={styles.row}>
                <TextInput
                  name="firstName"
                  onChange={handleChange}
                  label={t("settings.firstName")}
                  error={findError("cardholderName")}
                />
                <TextInput
                  name="secondName"
                  onChange={handleChange}
                  label={t("settings.secondName")}
                  error={findError("cardholderName")}
                />
              </div>
              <MaskedTextInput
                name="number"
                onChange={handleChange}
                options={{ creditCard: true }}
                label={t("settings.cardNumber")}
                placeholder="0000 0000 0000 0000"
                error={findError("number")}
                data-securionpay="number"
              />
              <div className={styles.row}>
                <MaskedTextInput
                  name="validThru"
                  onChange={handleChange}
                  label={t("settings.validThru")}
                  placeholder="00"
                  error={findError("validThru")}
                  data-securionpay="expMonth"
                />
                <MaskedTextInput
                  name="validThru"
                  onChange={handleChange}
                  label={t("settings.validThru")}
                  placeholder="0000"
                  error={findError("validThru")}
                  data-securionpay="expYear"
                />
              </div>
              <TextInput
                name="cvc"
                onChange={handleChange}
                label={t("settings.cvc")}
                placeholder="000"
                error={findError("cvc")}
              />
            </>
          )}
        </div>
        <CostsTable planPrice={planPrice} />
        <p className={styles.terms}>
          <CheckBox
            label={
              <>
                {t("modalSubscribe.terms")}{" "}
                <Link to="/terms-and-conditions">
                  {t("modalSubscribe.termsLink")}
                </Link>
              </>
            }
            checked={isAccepted}
            onChange={() => setAccepted(!isAccepted)}
          />
        </p>
        <p>
          <Button
            primary
            isLoading={isLoading}
            disabled={!isAccepted}
            type="submit"
          >
            {t("modalSubscribe.becomeSupporter")}
          </Button>
        </p>
        <p className={styles.disclaimer}>{t("modalSubscribe.disclaimer")}</p>
      </form>
    </Modal>
  );
};

export default ModalSubscribe;
