import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Loader from "../loaders/Loader";
import styles from "./styles.module.css";

const Button = ({
  to,
  href,
  onClick,
  children,
  primary,
  secondary,
  warning,
  transparent,
  disabled,
  isLoading = false,
  wrapperClass = "",
  size = "medium",
  ...rest
}) => {
  const modeClassName = classNames(styles.button, wrapperClass, {
    [styles.transparent]: transparent,
    [styles.warning]: warning,
    [styles.secondary]: secondary,
    [styles.disabled]: disabled,
    [styles.primary]: primary,
    [styles.small]: size === "small",
    [styles.medium]: size === "medium",
    [styles.large]: size === "large",
    [styles.small]: size === "small",
  });

  const content = isLoading ? (
    <div className={styles.buttonLoader}>
      <Loader isLight={secondary} />
    </div>
  ) : (
    children
  );

  if (to) {
    return (
      <Link className={`${modeClassName}`} to={to}>
        {content}
      </Link>
    );
  }
  if (href) {
    return (
      <a className={`${modeClassName}`} href={href}>
        {content}
      </a>
    );
  }
  return (
    <button
      type="button"
      className={`${modeClassName}`}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {content}
    </button>
  );
};

export default Button;
