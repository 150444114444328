import * as React from "react";

function AvatarPlaceholder({
  width = "28px",
  height = "28px",
  fill = "var(--darkGray)",
  ...rest
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      {...rest}
    >
      <g clipPath="url(#avatarPlaceholder)" fill={fill}>
        <path d="M11.277 19.524c.025-.04.091-.153.41-.153.32 0 .386.117.411.154a.867.867 0 001.491-.885 2.1 2.1 0 00-1.901-1.002 2.1 2.1 0 00-1.903 1.003.867.867 0 001.492.883zM14.714 19.833a.857.857 0 00.442.117.866.866 0 00.747-.426c.024-.04.09-.153.41-.153.32 0 .386.117.411.154a.866.866 0 101.49-.885 2.1 2.1 0 00-1.901-1.002 2.1 2.1 0 00-1.902 1.003.867.867 0 00.303 1.192z" />
        <path d="M26.41 14.734a.865.865 0 00-1.034-.658 50.59 50.59 0 01-3.718.665.287.287 0 01-.303-.165L14.786.46a.902.902 0 00-1.572 0L6.65 14.576a.287.287 0 01-.303.165 50.585 50.585 0 01-3.718-.665.867.867 0 00-.376 1.693c1.226.273 2.466.485 3.712.67a.29.29 0 01.248.28v.1a.282.282 0 01-.07.191 2.45 2.45 0 00-.527 1.657c.01.393.083.782.217 1.151a.3.3 0 01.014.144 6.565 6.565 0 01-2.024 4.033 1.157 1.157 0 00.117 1.866 3.114 3.114 0 002.735.46.29.29 0 01.386.273v.823a.583.583 0 00.583.583h1.223a.29.29 0 00.279-.366 9.617 9.617 0 01-.35-2.191c.001-1.012.301-2.001.862-2.844a.288.288 0 00-.038-.364l-.287-.284a.292.292 0 01-.07-.117 3.145 3.145 0 01-.117-.426A2.03 2.03 0 007.987 19.9a1.477 1.477 0 01-.637-1.268.726.726 0 01.117-.509.867.867 0 00.48-.786v-.299a.29.29 0 01.319-.292 54.67 54.67 0 005.734.305 54.668 54.668 0 005.734-.305.289.289 0 01.32.292v.299a.867.867 0 00.48.786c.1.15.142.33.116.509a1.478 1.478 0 01-.644 1.268 2.024 2.024 0 00-1.157 1.51 3.14 3.14 0 01-.108.408.29.29 0 01-.07.116l-.296.296a.288.288 0 00-.037.364c.564.842.865 1.833.867 2.848a9.62 9.62 0 01-.35 2.19.29.29 0 00.278.368h1.232a.583.583 0 00.578-.583V26.6a.29.29 0 01.386-.273 3.111 3.111 0 002.734-.46 1.157 1.157 0 00.116-1.866 6.568 6.568 0 01-2.025-4.034.296.296 0 01.013-.148c.135-.37.21-.76.22-1.155a2.45 2.45 0 00-.525-1.654.283.283 0 01-.069-.19v-.1a.29.29 0 01.248-.282 52.254 52.254 0 003.712-.67.867.867 0 00.657-1.034z" />
        <path d="M18.047 25.443a4.047 4.047 0 10-8.094 0c.046.806.203 1.603.466 2.367a.29.29 0 00.269.19h6.623a.287.287 0 00.272-.19c.263-.764.419-1.56.464-2.367zm-2.286-.343a2.37 2.37 0 01-1.653.632h-.218a2.369 2.369 0 01-1.652-.632.868.868 0 111.211-1.24.682.682 0 00.441.137h.218a.684.684 0 00.442-.137.867.867 0 111.211 1.24z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AvatarPlaceholder;
