import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./auth/authSlice";
import userReducer from "./user/userSlice";
import usersReducer from "./users/usersSlice";
import feedReducer from "./feed/feedSlice";
import postsReducer from "./posts/postsSlice";
import messagesReducer from "./messages/messagesSlice";
import membershipReducer from "./membership/membershipSlice";
import docsReducer from "./docs/docsSlice";
import blogReducer from "./blog/blogSlice";
import payoutsReducer from "./payouts/payoutsSlice";

const logger = createLogger({
  predicate: () => process.env.NODE_ENV !== "production",
});

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  logger,
];

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  users: usersReducer,
  feed: feedReducer,
  posts: postsReducer,
  messages: messagesReducer,
  membership: membershipReducer,
  docs: docsReducer,
  blog: blogReducer,
  payouts: payoutsReducer,
});

const persistConfig = {
  key: "root",
  whitelist: ["user", "auth"],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware,
});
const persistor = persistStore(store);
export { store, persistor };
