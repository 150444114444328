import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getMembership } from "../../data/membership/membershipSlice";
import { unfollowUser } from "../../data/users/usersSlice";
import List from "../../components/list/List";
import ListItem from "../../components/list/ListItem";
import Button from "../../components/button/Button";
import Loader from "../../components/loaders/Loader";
import Accordion from "../../components/accordion/Accordion";
import HeartIcon from "../../components/assets/emoticons/HeartIcon";
import styles from "./styles.module.css";

const MembershipFollowing = ({ following, isLoading, setLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const unfollow = (link) => {
    dispatch(unfollowUser({ link })).then(() => {
      dispatch(getMembership()).then(() => {
        setLoading(false);
      });
    });
  };

  return (
    <Accordion
      hideable
      hide
      heading={(
        <>
          {t("membership.peopleYouFollow")}
          <HeartIcon />
        </>
      )}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <List childrenClass={styles.membershipWrapper}>
          {following.length !== 0 &&
            following.map((followedUser) => (
              <ListItem
                link={`/u/${followedUser.link}`}
                alt={followedUser.username}
                key={followedUser.id}
                avatarUrl={followedUser.avatar}
                primaryText={followedUser.username}
                secondaryText={
                  followedUser.category
                    ? `${t(`categories.${followedUser.category}`)} ${t(
                        "common.creator"
                      )}`
                    : t("common.basicUser")
                }
                renderButtons={() => (
                  <>
                    <Button
                      transparent
                      warning
                      onClick={() => unfollow(followedUser.link)}
                    >
                      {t("membership.unfollow")}
                    </Button>
                    <Button primary to={`/u/${followedUser.link}`}>
                      {t("membership.visit")}
                    </Button>
                  </>
                )}
              />
            ))}
        </List>
      )}
    </Accordion>
  );
};

export default MembershipFollowing;
