import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { smallDevice } from "../assets/breakpoints/breakpoints";
import Avatar from "../avatar/Avatar";

const ListItem = ({
  primaryText,
  secondaryText,
  renderButtons = () => {},
  avatarUrl,
  link,
  mobileMenu = () => {},
  style,
  hideButtons,
  highlight = false,
  onClick,
  alt,
}) => {
  const isMobile = useMediaQuery(smallDevice);
  return (
    <div
      className={`${styles.listItem} ${
        highlight ? styles.listItemHighlight : ""
      } ${onClick ? styles.listItemPointer : ""}`}
      onClick={onClick}
    >
      {link ? (
        <Link to={link}>
          <Avatar src={avatarUrl} alt={alt} />
        </Link>
      ) : (
        <Avatar src={avatarUrl} alt={alt} />
      )}
      <div className={styles.infoTexts}>
        <p className={styles.primaryText}>
          {link ? <Link to={link}>{primaryText}</Link> : primaryText}
        </p>
        <p className={styles.secondaryText}>
          {link ? <Link to={link}>{secondaryText}</Link> : secondaryText}
        </p>
      </div>
      <div className={styles.buttons} style={style}>
        {hideButtons && isMobile ? mobileMenu() : renderButtons()}
      </div>
    </div>
  );
};
export default ListItem;
