import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Button from "./Button";
import { followUser, unfollowUser } from "../../data/users/usersSlice";
import styles from "./styles.module.css";

const FollowButtonTransparent = ({ id, link }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const isFollowing = Object.keys(user.following).includes(id);

  const unfollow = () => {
    setLoading(true);
    dispatch(unfollowUser({ link })).then(() => setLoading(false));
  };

  const follow = () => {
    setLoading(true);
    dispatch(followUser({ link })).then(() => setLoading(false));
  };

  return (
    <div className={styles.wrapper}>
      {isFollowing ? (
        <Button onClick={() => unfollow()} transparent isLoading={isLoading}>
          {t("button.unfollow")}
        </Button>
      ) : (
        <Button onClick={() => follow()} transparent isLoading={isLoading}>
          {t("button.follow")}
        </Button>
      )}
    </div>
  );
};

export default FollowButtonTransparent;
