import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SidebarBox from "./SidebarBox";
import StarIcon from "../assets/emoticons/StarIcon";
import Button from "../button/Button";
import styles from "./styles.module.css";
import { getCreatorVerification } from "../../data/user/userSlice";

const SidebarBecomeStar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const creatorVerification = useSelector(
    (state) => state.user.creatorVerification
  );

  useEffect(() => {
    if (auth.isLoggedIn) {
      dispatch(getCreatorVerification({ userId: auth.uid }));
    }
  }, [auth, dispatch]);

  if (creatorVerification && creatorVerification.approved) {
    return null;
  }

  if (creatorVerification && !creatorVerification.approved) {
    return (
      <SidebarBox
        heading={(
          <>
            <span>{t("common.becomeStarVerifyingHeading")}</span>
          </>
        )}
      >
        <p className={styles.text}>
          {t("common.becomeStarVerifyingDescription")}
        </p>
      </SidebarBox>
    );
  }

  return (
    <SidebarBox
      heading={(
        <>
          <StarIcon />
          <span>{t("common.becomeStarHeading")}</span>
          <StarIcon />
        </>
      )}
      buttons={(
        <Button to="/become-a-star" secondary>
          {t("common.becomeStarStart")}
        </Button>
      )}
    >
      <p className={styles.text}>{t("common.becomeStarDescription")}</p>
    </SidebarBox>
  );
};

export default SidebarBecomeStar;
