import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { signup } from "../../data/auth/authSlice";
import TextInput from "../../components/textInput/TextInput";
import Modal from "../../components/modal/Modal";
import Layout from "../../components/layout/Layout";
import Button from "../../components/button/Button";
import StarIcon from "../../components/assets/emoticons/StarIcon";
import styles from "./styles.module.css";

const Signup = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);
    dispatch(signup(data)).then((resultAction) => {
      setLoading(false);
      if (signup.fulfilled.match(resultAction)) {
        setModalOpen(true);
      }
    });
  };
  const { t } = useTranslation();
  const error = useSelector((state) => state.auth.errors.signup);
  const emailError = () => {
    if (error && error.code === "auth/email-already-exists") {
      return error.message;
    }
    if (error && error.code === "auth/invalid-email") {
      return error.message;
    }
    return null;
  };
  const usernameError = () => {
    if (error && error.code === "auth/username-taken") {
      return error.message;
    }
    return null;
  };
  const passwordError = () =>
    error && error.code === "auth/invalid-password" ? error.message : null;
  return (
    <Layout>
      <div className={styles.box}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className={styles.heading}>{t("signup.header")}</h2>
          <p className={styles.description}>{t("signup.description")}</p>
          <TextInput
            name="email"
            type="email"
            ref={register}
            label={t("signup.email")}
            placeholder={t("signup.emailPlaceholder")}
            error={emailError()}
          />
          <TextInput
            name="username"
            ref={register}
            label={t("signup.username")}
            placeholder={t("signup.usernamePlaceholder")}
            error={usernameError()}
            onInput={(event) => {
              event.target.value = event.target.value
                .toLowerCase()
                .replace(/([^0-9a-z_])/g, "");
            }}
          />
          <TextInput
            name="password"
            type="password"
            ref={register}
            label={t("signup.password")}
            placeholder={t("signup.passwordPlaceholder")}
            error={passwordError()}
          />
          <div className={styles.signupSubmit}>
            <Button primary type="submit" isLoading={isLoading}>
              {t("signup.createAccount")}
            </Button>
            <p className={styles.disclaimer}>
              {t("signup.disclaimer")}
              {" "}
              <Link to="/terms-and-conditions">
                {t("signup.disclaimerTermsOfUse")}
              </Link>
              {" "}
              {t("signup.disclaimerAnd")}
              {" "}
              <Link to="/privacy-policy">
                {t("signup.disclaimerPrivacyPolicy")}
              </Link>
              {" "}
            </p>
          </div>
        </form>
      </div>
      <div className={styles.links}>
        <div className={styles.link}>
          <Link to="/signin">{t("signup.signinRedirect")}</Link>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        heading={(
          <>
            <StarIcon className={styles.doubleIcon} />
            <span>{t("signup.modalHeading")}</span>
            <StarIcon className={styles.doubleIcon} />
          </>
        )}
        text={t("signup.modalText")}
        buttons={(
          <Button primary to="/signin">
            {t("signup.modalButton")}
          </Button>
        )}
      />
    </Layout>
  );
};

export default Signup;
