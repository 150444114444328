import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import payoutsApi from "./payoutsApi";

const initialState = [];

const getPayouts = createAsyncThunk(
  "payouts/getPayouts",
  async (options, { rejectWithValue }) => {
    try {
      return await payoutsApi.getPayouts("privacyPolicy").json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

export const payoutsSlice = createSlice({
  name: "payouts",
  initialState,
  reducers: {},
  extraReducers: {
    [getPayouts.fulfilled]: (state, action) => action.payload,
  },
});

export { getPayouts };

export default payoutsSlice.reducer;
