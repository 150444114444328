import React from "react";

function MessageIcon({ height = "1em", width = "1em" }) {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 16 16">
      <path
        fill="#041E40"
        d="M8 .557c-4.411 0-8 2.983-8 6.65a6.005 6.005 0 002.038 4.439l-1.417 2.83a.667.667 0 00.877.903l3.987-1.856c.82.223 1.666.335 2.515.333 4.411 0 8-2.983 8-6.65C16 3.542 12.411.558 8 .558zm0 11.966a8.109 8.109 0 01-2.375-.348.666.666 0 00-.476.033l-2.046.953a.166.166 0 01-.236-.126.167.167 0 01.016-.1l.58-1.157a.667.667 0 00-.18-.82 4.806 4.806 0 01-1.95-3.75C1.333 4.273 4.324 1.89 8 1.89c3.676 0 6.667 2.384 6.667 5.316S11.676 12.523 8 12.523z"
      />
      <path
        fill="#041E40"
        fillRule="evenodd"
        d="M3 11.5L2 14l3.5-1h4l4-1.5 2-3.5-1-3L12 2l-5-.5L3 3 1 5.5V9l2 2.5zm6.112-7.396a2.22 2.22 0 011.033-.074c.346.053.673.187.955.39.282.204.51.471.666.78a2.142 2.142 0 01-.414 2.51l-3.093 3.177a.375.375 0 01-.417.083.375.375 0 01-.123-.083l-3.1-3.182A2.155 2.155 0 014 6.175c.006-.571.239-1.116.65-1.519.41-.402.965-.63 1.545-.633a2.227 2.227 0 011.552.615l.242.237.241-.237c.246-.245.549-.428.882-.534z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default MessageIcon;
