import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const BlogPost = ({ slug, title, image, excerpt }) => (
  <Link to={`/blog/post/${slug}`}>
    <div className={styles.post}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <div
          className={styles.excerpt}
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
      </div>
      <div className={styles.imageWrapper}>
        <img src={image} alt="" />
      </div>
    </div>
  </Link>
);

export default BlogPost;
