import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../avatar/Avatar";
import UserCategory from "../userCategory/UserCategory";
import styles from "./styles.module.css";

const Post = ({ username, category, avatar, link, renderMenu }) => {
  return (
    <div className={styles.header}>
      <div className={styles.userInfo}>
        <div className={styles.avatar}>
          {link ? (
            <Link to={`/u/${link}`}>
              <Avatar alt={username} src={avatar} />
            </Link>
          ) : (
            <Avatar alt={username} src={avatar} />
          )}
        </div>
        <p className={styles.username}>
          {link ? <Link to={`/u/${link}`}>{username}</Link> : username}
        </p>
        <p className={styles.title}>
          <UserCategory category={category} />
        </p>
      </div>
      <div className={styles.menu}>{renderMenu && renderMenu()}</div>
    </div>
  );
};

export default Post;
