import React from "react";

function HomePageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g fill="#041E40" clipPath="url(#clip0)">
        <path d="M1.762 7.06l6.033-4.989a.322.322 0 01.41 0l6.033 4.989a.966.966 0 001.377-.148 1.015 1.015 0 00-.145-1.407L9.437.516a2.26 2.26 0 00-2.874 0L.533 5.505a1.015 1.015 0 00-.145 1.407.967.967 0 001.374.148z" />
        <path d="M14.854 14.667V10a1.484 1.484 0 00-.489-1.06L8.832 4.273a1.287 1.287 0 00-1.663 0L1.636 8.944A1.483 1.483 0 001.146 10v4.667A1.32 1.32 0 002.451 16h3.591a.66.66 0 00.653-.667v-3.666A.66.66 0 017.347 11h1.306a.66.66 0 01.652.667v3.666a.66.66 0 00.653.667h3.59a1.322 1.322 0 001.306-1.333z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HomePageIcon;
