import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import usersApi from "./usersApi";

const initialState = {
  suggestions: [],
  users: {},
};

const getSuggestions = createAsyncThunk(
  "users/getSuggestions",
  async (options, { rejectWithValue }) => {
    try {
      return await usersApi.getSuggestions().json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const getUser = createAsyncThunk(
  "users/getUser",
  async ({ link }, { rejectWithValue }) => {
    try {
      return await usersApi.getUser({ link }).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const followUser = createAsyncThunk(
  "users/followUser",
  async ({ link }, { rejectWithValue }) => {
    try {
      return await usersApi.followUser({ link }).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const unfollowUser = createAsyncThunk(
  "users/unfollowUser",
  async ({ link }, { rejectWithValue }) => {
    try {
      return await usersApi.unfollowUser({ link }).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const subscribeUser = createAsyncThunk(
  "users/subscribeUser",
  async (
    { link, token },
    { rejectWithValue }
  ) => {
    try {
      return await usersApi
        .subscribeUser({
          link,
          token,
        })
        .json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const unsubscribeUser = createAsyncThunk(
  "users/unsubscribeUser",
  async ({ subscriptionId, starId }, { rejectWithValue }) => {
    try {
      return await usersApi
        .unsubscribeUser({
          subscriptionId,
          starId,
        })
        .json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {
    [getSuggestions.fulfilled]: (state, action) => ({
      ...state,
      suggestions: action.payload,
    }),
    [getUser.fulfilled]: (state, action) => ({
      ...state,
      users: {
        ...state.users,
        [action.payload.id]: action.payload,
      },
    }),
    [followUser.fulfilled]: (state, action) => ({
      ...state,
      users: {
        ...state.users,
        [action.payload.star.id]: action.payload.star,
      },
    }),
  },
});

export {
  getUser,
  followUser,
  unfollowUser,
  getSuggestions,
  subscribeUser,
  unsubscribeUser,
};

export default userSlice.reducer;
