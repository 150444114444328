import React from "react";

function AtomicBombIcon({ height = "1em", width = "1em" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#041E40" clipPath="url(#atomicbomb)">
        <path d="M10.652 8.667h-.946a2.383 2.383 0 00-3.245 0h-.946a1.733 1.733 0 00-1.756 1.493A1.666 1.666 0 005.417 12h1.75a.166.166 0 01.167.167v3.166a.667.667 0 101.333 0v-3.166A.166.166 0 018.834 12h1.916a1.668 1.668 0 001.658-1.84 1.734 1.734 0 00-1.756-1.493z" />
        <path d="M5.703 12.667a.666.666 0 00-.717.613 1.971 1.971 0 01-.714 1.507.666.666 0 10.769 1.088 3.26 3.26 0 001.275-2.492.667.667 0 00-.613-.716zM11.014 13.282a.667.667 0 00-1.33.103 3.26 3.26 0 001.275 2.492.665.665 0 001.055-.389.666.666 0 00-.286-.699 1.97 1.97 0 01-.714-1.507zM14.446 3.026a2.38 2.38 0 00-3.52-1.72 3.929 3.929 0 00-5.85 0 2.38 2.38 0 00-3.522 1.72 1.831 1.831 0 00.313 3.64h2.966a.333.333 0 00.334-.333v-1a.5.5 0 011 0v1.5a.667.667 0 101.333 0V4.5a.5.5 0 011 0v2.333a.667.667 0 001.333 0v-1.5a.5.5 0 011 0v1a.333.333 0 00.334.334h2.966a1.831 1.831 0 00.313-3.641z" />
      </g>
      <defs>
        <clipPath id="atomicbomb">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AtomicBombIcon;
