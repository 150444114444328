import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import membershipApi from "./membershipApi";

const initialState = {
  subscribes: {},
  subscribers: {},
  followers: {},
  following: {},
};

const getMembership = createAsyncThunk(
  "membership/getMembership",
  async (options, { rejectWithValue }) => {
    try {
      return await membershipApi.getMembership().json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

export const membershipSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {
    [getMembership.fulfilled]: (state, action) => ({
      ...state,
      subscribes: action.payload.subscribes,
      subscribers: action.payload.subscribers,
      followers: action.payload.followers,
      following: action.payload.following,
    }),
  },
});

export { getMembership };

export default membershipSlice.reducer;
