import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { desktop } from "../../components/assets/breakpoints/breakpoints";
import SearchInput from "../../components/search/SearchInput";
import styles from "./styles.module.css";
import Layout from "../../components/layout/Layout";

const Search = () => {
  const isDesktop = useMediaQuery(desktop);
  const history = useHistory();

  useEffect(() => {
    if (isDesktop) {
      history.goBack();
    }
  });

  return (
    !isDesktop && (
      <Layout>
        <div className={styles.searchContainer}>
          <p className={styles.heading}>Search</p>
          <SearchInput isMobile />
        </div>
      </Layout>
    )
  );
};

export default Search;
