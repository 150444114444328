import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMembership } from "../../data/membership/membershipSlice";
import { getSuggestions } from "../../data/users/usersSlice";
import UserOverview from "../../components/userOverview/UserOverview";
import Layout from "../../components/layout/Layout";
import SidebarBecomeStar from "../../components/sidebar/SidebarBecomeStar";
import SidebarStatistics from "../../components/sidebar/SidebarStatistics";
import MembershipSubscribers from "./MembershipSubscribers";
import MembershipSubscribes from "./MembershipSubscribes";
import MembershipFollowers from "./MembershipFollowers";
import MembershipFollowing from "./MembershipFollowing";

const Membership = () => {
  const membership = useSelector((state) => state.membership);
  const user = useSelector((state) => state.user);
  const suggestions = Object.values(
    useSelector((state) => state.users.suggestions)
  );
  const subscribers = Object.values(membership.subscribers);
  const subscribes = Object.values(membership.subscribes);
  const followers = Object.values(membership.followers);
  const following = Object.values(membership.following);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getSuggestions());
    dispatch(getMembership()).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  return (
    <Layout
      pageTitle="Membership"
      left={<UserOverview />}
      right={(
        <>
          {!user.isCreator && <SidebarBecomeStar />}
          <SidebarStatistics />
        </>
      )}
    >
      <MembershipSubscribers
        subscribers={subscribers}
        isLoading={isLoading}
        user={user}
      />
      <MembershipSubscribes
        subscribes={subscribes}
        suggestions={suggestions}
        isLoading={isLoading}
        setLoading={setLoading}
        user={user}
      />
      <MembershipFollowers
        followers={followers}
        isLoading={isLoading}
        user={user}
      />
      <MembershipFollowing
        following={following}
        isLoading={isLoading}
        setLoading={setLoading}
        user={user}
      />
    </Layout>
  );
};

export default Membership;
