import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "../styles.module.css";

const NavMenu = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.menu}>
      <Link to="/blog">
        <div className={styles.menuItem}>{t("header.blog")}</div>
      </Link>
      <Link to="/faq">
        <div className={styles.menuItem}>{t("header.faq")}</div>
      </Link>
      <Link to="/contact">
        <div className={styles.menuItem}>{t("header.contact")}</div>
      </Link>
    </div>
  );
};

export default NavMenu;
