import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import blogApi from "./blogApi";

const initialState = {
  posts: [],
};

const getPosts = createAsyncThunk(
  "blog/getPosts",
  async (options, { rejectWithValue }) => {
    try {
      return await blogApi.getPosts().json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: {
    [getPosts.fulfilled]: (state, action) => ({
      ...state,
      posts: action.payload,
    }),
  },
});

export { getPosts };

export default blogSlice.reducer;
