import React from "react";
import { useTranslation } from "react-i18next";
import SidebarBox from "./SidebarBox";
import HeartIcon from "../assets/emoticons/HeartIcon";
import Button from "../button/Button";
import styles from "./styles.module.css";

const SidebarMessage = ({ id }) => {
  const { t } = useTranslation();
  return (
    <SidebarBox
      heading={(
        <>
          <HeartIcon />
          <span>{t("sidebarMessage.heading")}</span>
          <HeartIcon />
        </>
      )}
      buttons={(
        <Button to={`/messages/${id}`} primary>
          {t("sidebarMessage.message")}
        </Button>
      )}
    >
      <p className={styles.text}>{t("sidebarMessage.description")}</p>
    </SidebarBox>
  );
};

export default SidebarMessage;
