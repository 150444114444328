import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  getPosts,
  getMorePosts,
  deleteFeedPost,
} from "../../data/feed/feedSlice";
import { unfollowUser, getSuggestions } from "../../data/users/usersSlice";
import List from "../../components/list/List";
import ListItem from "../../components/list/ListItem";
import EmptyListWrapper from "../../components/list/EmptyListWrapper";
import Loader from "../../components/loaders/Loader";
import Layout from "../../components/layout/Layout";
import CreatePost from "../../components/createPost/CreatePost";
import FollowButtonTranparent from "../../components/button/FollowButtonTransparent";
import ContextMenu from "../../components/contextMenu/ContextMenu";
import ContextMenuItem from "../../components/contextMenu/ContextMenuItem";
import UserOverview from "../../components/userOverview/UserOverview";
import UserOverviewSupporting from "../../components/userOverview/UserOverviewSupporting";
import SidebarStatistics from "../../components/sidebar/SidebarStatistics";
import SidebarBecomeStar from "../../components/sidebar/SidebarBecomeStar";
import ModalSubscribe from "../../components/modal/modalSubscribe/ModalSubscribe";
import Post from "../../components/post/Post";
import BrokenHeartIcon from "../../components/assets/icons/BrokenHeartIcon";
import AtomicBombIcon from "../../components/assets/icons/AtomicBombIcon";
import Accordion from "../../components/accordion/Accordion";
import HeartIcon from "../../components/assets/emoticons/HeartIcon";
import BicepsIcon from "../../components/assets/emoticons/BicepsIcon";
import styles from "./styles.module.css";

const Feed = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { posts } = useSelector((state) => state.feed);
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const [isLoading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isModalSubscribeOpen, setModalSubscribeOpen] = useState(false);
  const [shouldRenderSuggestions] = useState(
    Object.entries(user.following).length === 0
  );
  const [modalSubscribeState, setModalSubscribeState] = useState({
    planId: null,
    planPrice: null,
    link: null,
  });
  const { suggestions } = useSelector((state) => state.users);

  const loadPosts = useCallback(() => {
    setLoading(true);
    dispatch(getPosts({ limit: 10 })).then(() => {
      setLoading(false);
    });
    dispatch(getSuggestions({}));
  }, [dispatch]);

  const onLoadMore = useCallback(() => {
    const startsAfterId = posts.length ? posts[posts.length - 1].id : null;
    setLoading(true);
    dispatch(getMorePosts({ startsAfterId, limit: 5 })).then((resultAction) => {
      setLoading(false);
      if (resultAction.payload && resultAction.payload.length === 0) {
        setHasNextPage(false);
      }
    });
  }, [posts, dispatch]);

  const infiniteScrollRef = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore,
  });

  useEffect(() => {
    loadPosts();
  }, [dispatch, loadPosts]);

  const renderPosts = () => (
    <div ref={infiniteScrollRef}>
      {posts.map((post) => (
        <Post
          key={post.id}
          username={post.username}
          category={post.category}
          avatar={post.avatar}
          text={post.text}
          images={post.images}
          videos={post.videos}
          link={post.link}
          isSubscriber={post.isSubscriber}
          subscribersOnly={post.subscribersOnly}
          price={post.planPrice}
          onUnlock={() => {
            setModalSubscribeState({
              planId: post.planId,
              link: post.link,
              planPrice: post.planPrice,
            });
            setModalSubscribeOpen(true);
          }}
          renderMenu={() => (
            <ContextMenu>
              {post.author === user.id && (
                <ContextMenuItem
                  onClick={() => {
                    dispatch(deleteFeedPost({ id: post.id }));
                  }}
                  icon={<AtomicBombIcon />}
                >
                  {t("post.deletePost")}
                </ContextMenuItem>
              )}
              {Object.keys(user.subscribes).indexOf(post.author) === -1 &&
                post.author !== user.id && (
                  <ContextMenuItem
                    onClick={() => {
                      dispatch(unfollowUser({ link: post.link }));
                    }}
                    icon={<BrokenHeartIcon />}
                  >
                    {t("post.unfollowStar")}
                  </ContextMenuItem>
                )}
            </ContextMenu>
          )}
        />
      ))}
    </div>
  );
  const renderSuggestions = () => (
    <Accordion childrenWrapperClass={styles.listWrapper}>
      <div className={styles.feedWrapper}>
        <EmptyListWrapper
          headingClass={styles.heading}
          isEmpty={posts.length === 0}
          heading={(
            <>
              {t("feed.followCreators")}
              <HeartIcon />
            </>
          )}
          subText={t("feed.followCreatorsDescription")}
        >
          <List>
            <div className={styles.sugestionListWrapper}>
              {suggestions.map((suggestion) => (
                <ListItem
                  alt={suggestion.username}
                  key={suggestion.username}
                  primaryText={suggestion.username}
                  secondaryText={t(`categories.${suggestion.category}`)}
                  avatarUrl={suggestion.avatar}
                  link={`/u/${suggestion.link}`}
                  renderButtons={() => (
                    <FollowButtonTranparent
                      id={suggestion.id}
                      link={suggestion.link}
                    />
                  )}
                />
              ))}
            </div>
          </List>
        </EmptyListWrapper>
      </div>
    </Accordion>
  );

  if (!auth.isLoggedIn || !user.isLoaded) {
    return <Redirect to="/signin" />;
  }

  return (
    <Layout
      pageTitle="Feed"
      left={(
        <UserOverview>
          <UserOverviewSupporting />
        </UserOverview>
      )}
      right={(
        <>
          {!user.isCreator && <SidebarBecomeStar />}
          <SidebarStatistics />
        </>
      )}
      leftHideOnMobile
      rightHideOnMobile
    >
      <div className={styles.feed}>
        {shouldRenderSuggestions &&
          !isLoading &&
          !posts.length &&
          renderSuggestions()}
        {user.isCreator && !!posts.length && <CreatePost />}
        {!!posts.length && renderPosts()}
        {isLoading && (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        )}
        {!hasNextPage && (
          <>
            <p className={styles.feedEnd}>
              {t("feed.end")} 
              {' '}
              <BicepsIcon />
            </p>
          </>
        )}
      </div>
      <ModalSubscribe
        isOpen={isModalSubscribeOpen}
        link={modalSubscribeState.link}
        planId={modalSubscribeState.planId}
        planPrice={modalSubscribeState.planPrice}
        closeModal={() => setModalSubscribeOpen(false)}
        onSuccess={() =>
          history.push(`/u/${modalSubscribeState.link}`, { withConfetti: true })}
      />
    </Layout>
  );
};

export default Feed;
