import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import List from "../../components/list/List";
import ListItem from "../../components/list/ListItem";
import Button from "../../components/button/Button";
import Loader from "../../components/loaders/Loader";
import Accordion from "../../components/accordion/Accordion";
import HeartIcon from "../../components/assets/emoticons/HeartIcon";
import SendIcon from "../../components/assets/emoticons/SendIcon";
import EmptyListWrapper from "../../components/list/EmptyListWrapper";
import styles from "./styles.module.css";

const MembershipSubscribers = ({ subscribers, user, isLoading }) => {
  const { t } = useTranslation();

  if (!user.isCreator) {
    return null;
  }

  return (
    <Accordion
      hideable
      heading={(
        <>
          {t("membership.yourSupporters")}
          <HeartIcon />
        </>
      )}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <List childrenClass={styles.membershipWrapper}>
          <EmptyListWrapper
            isEmpty={!isLoading && !subscribers.length}
            heading={(
              <>
                {t("membership.reachFans")}
                <SendIcon />
              </>
            )}
            subText={(
              <>
                {t("membership.noFans")}
                <Link to={`/u/${user.link}`} className={styles.userLink}>
                  {`fanbea.com/u/${user.link}`}
                </Link>
              </>
            )}
          >
            {subscribers.length !== 0 &&
              subscribers.map((subscriber) => (
                <ListItem
                  link={subscriber.link ? `/u/${subscriber.link}` : undefined}
                  key={subscriber.id}
                  alt={subscriber.username}
                  avatarUrl={subscriber.avatar}
                  primaryText={subscriber.username}
                  secondaryText={
                    subscriber.category
                      ? `${t(`categories.${subscriber.category}`)} ${t(
                          "common.creator"
                        )}`
                      : t("common.basicUser")
                  }
                  renderButtons={() => (
                    <Button transparent to={`/messages/${subscriber.id}`}>
                      {t("membership.message")}
                    </Button>
                  )}
                />
              ))}
          </EmptyListWrapper>
        </List>
      )}
    </Accordion>
  );
};

export default MembershipSubscribers;
