import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import feedApi from "./feedApi";
import postsApi from "../posts/postsApi";
import { unfollowUser } from "../users/usersSlice";
import {
  createTextPost,
  createPhotoPost,
  createVideoPost,
} from "../posts/postsSlice";

const initialState = {
  posts: [],
};

const getPosts = createAsyncThunk(
  "feed/getPosts",
  async ({ startsAfterId, limit }, { rejectWithValue }) => {
    try {
      return await feedApi.getPosts(startsAfterId, limit).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const getMorePosts = createAsyncThunk(
  "feed/getMorePosts",
  async ({ startsAfterId, limit }, { rejectWithValue }) => {
    try {
      return await feedApi.getPosts(startsAfterId, limit).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const deleteFeedPost = createAsyncThunk(
  "feed/deleteFeedPost",
  async ({ id }, { rejectWithValue }) => {
    try {
      return await postsApi.deletePost({ id }).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

export const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {},
  extraReducers: {
    [getPosts.fulfilled]: (state, action) => ({
      ...state,
      posts: action.payload,
    }),
    [getMorePosts.fulfilled]: (state, action) => ({
      ...state,
      posts: [...state.posts, ...action.payload],
    }),
    [createTextPost.fulfilled]: (state, action) => ({
      ...state,
      posts: [action.payload, ...state.posts],
    }),
    [createPhotoPost.fulfilled]: (state, action) => ({
      ...state,
      posts: [action.payload, ...state.posts],
    }),
    [createVideoPost.fulfilled]: (state, action) => ({
      ...state,
      posts: [action.payload, ...state.posts],
    }),
    [unfollowUser.fulfilled]: (state, action) => ({
      ...state,
      posts: state.posts.filter(
        (post) => post.author !== action.payload.star.id
      ),
    }),
    [deleteFeedPost.fulfilled]: (state, action) => ({
      ...state,
      posts: state.posts.filter((item) => item.id !== action.payload.id),
    }),
  },
});

export { getPosts, getMorePosts, deleteFeedPost };

export default feedSlice.reducer;
