import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import OutClick from "react-outclick";
import styles from "../styles.module.css";
import FeedIcon from "../../assets/icons/FeedIcon";
import MembershipIcon from "../../assets/icons/MembershipIcon";
import SettingsIcon from "../../assets/icons/SettingsIcon";
import LogOutIcon from "../../assets/icons/LogOutIcon";
import IconComponent from "../../assets/IconComponent";
import MessageIcon from "../../assets/icons/MessageIcon";
import SearchIcon from "../../assets/icons/SearchIcon";
import BlogIcon from "../../assets/icons/BlogIcon";
import FaqIcon from "../../assets/icons/FaqIcon";
import ContactIcon from "../../assets/icons/ContactIcon";
import StarIcon from "../../assets/icons/StarIcon";
import LogInIcon from "../../assets/icons/LogInIcon";
import CreateAccountIcon from "../../assets/icons/CreateAccountIcon";
import HomePageIcon from "../../assets/icons/HomePageIcon";
import PayoutsIcon from "../../assets/icons/PayoutsIcon";
import { desktop } from "../../assets/breakpoints/breakpoints";
import { logout } from "../../../data/auth/authSlice";

const DropDown = ({ setVisibile, isVisible, isLoggedIn, user }) => {
  const { t } = useTranslation();
  const style = styles.profileContainerBlock;
  const isDesktop = useMediaQuery(desktop);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isCreator, link } = user;
  const MenuLink = ({ to, children }) => (
    <Link to={to} onClick={() => setVisibile(false)}>
      {children}
    </Link>
  );
  const onLogout = () => {
    dispatch(logout()).then(() => {
      history.push("/signin");
    });
  };

  const loggedInMenu = (
    <>
      {!isDesktop && <div className={styles.dropdownCategories}>Account</div>}
      {isCreator && !isDesktop && (
        <MenuLink to={`/u/${link}`}>
          <IconComponent
            style={style}
            icon={<StarIcon />}
            text={t("header.yourPage")}
          />
        </MenuLink>
      )}
      {isDesktop && (
        <MenuLink to="/feed">
          <IconComponent
            style={style}
            icon={<FeedIcon />}
            text={t("header.feed")}
          />
        </MenuLink>
      )}
      {!isDesktop && (
        <MenuLink to="/messages">
          <IconComponent
            style={style}
            icon={<MessageIcon />}
            text={t("header.messages")}
          />
        </MenuLink>
      )}
      <MenuLink to="/membership">
        <IconComponent
          style={style}
          icon={<MembershipIcon />}
          text={t("header.membership")}
        />
      </MenuLink>
      {user.isCreator && (
        <MenuLink to="/payouts">
          <IconComponent
            style={style}
            icon={<PayoutsIcon />}
            text={t("header.payouts")}
          />
        </MenuLink>
      )}
      <MenuLink to="/settings">
        <IconComponent
          style={style}
          icon={<SettingsIcon />}
          text={t("header.settings")}
        />
      </MenuLink>
      <IconComponent
        onClick={() => onLogout()}
        style={style}
        icon={<LogOutIcon />}
        text={t("header.logOut")}
      />
      {!isDesktop && <div className={styles.dropdownCategories}>Site</div>}
      {!isDesktop && (
        <>
          <MenuLink to="/feed">
            <IconComponent
              style={style}
              icon={<FeedIcon />}
              text={t("header.feed")}
            />
          </MenuLink>
          <MenuLink to="/search">
            <IconComponent
              style={style}
              icon={<SearchIcon fill="var(--secondaryColor)" />}
              text={t("header.search")}
            />
          </MenuLink>
          <MenuLink to="/blog">
            <IconComponent
              style={style}
              icon={<BlogIcon />}
              text={t("header.blog")}
            />
          </MenuLink>
          <MenuLink to="/faq">
            <IconComponent
              style={style}
              icon={<FaqIcon />}
              text={t("header.faq")}
            />
          </MenuLink>
          <MenuLink to="/contact">
            <IconComponent
              style={style}
              icon={<ContactIcon />}
              text={t("header.contact")}
            />
          </MenuLink>

          <div className={styles.footerDropDown}>
            <p>{t("footer.fanbea2020")}</p>

            <p>
              <MenuLink to="/privacy-policy">{t("footer.privacy")}</MenuLink>
              {" • "}
              <MenuLink to="/terms-and-conditions">
                {t("footer.terms")}
              </MenuLink>
            </p>

            <p>
              <MenuLink to="/2257">{t("footer.2257")}</MenuLink>
              {" • "}
              <MenuLink to="/dmca">{t("footer.dmca")}</MenuLink>
            </p>
          </div>
        </>
      )}
    </>
  );

  const loggedOutMenu = !isDesktop && (
    <>
      <div className={styles.dropdownCategories}>Account</div>
      <MenuLink to="/signin">
        <IconComponent
          style={style}
          icon={<LogInIcon />}
          text={t("header.login")}
        />
      </MenuLink>
      <MenuLink to="/signup">
        <IconComponent
          style={style}
          icon={<CreateAccountIcon />}
          text={t("header.createAccount")}
        />
      </MenuLink>

      <div className={styles.dropdownCategories}>Site</div>
      <MenuLink to="/">
        <IconComponent
          style={style}
          icon={<HomePageIcon />}
          text={t("header.homePage")}
        />
      </MenuLink>

      <MenuLink to="/search">
        <IconComponent
          style={style}
          icon={<SearchIcon fill="var(--secondaryColor)" />}
          text={t("header.search")}
        />
      </MenuLink>
      <IconComponent
        style={style}
        icon={<BlogIcon />}
        text={t("header.blog")}
      />

      <MenuLink to="/faq">
        <IconComponent
          style={style}
          icon={<FaqIcon />}
          text={t("header.faq")}
        />
      </MenuLink>
      <MenuLink to="/contact">
        <IconComponent
          style={style}
          icon={<ContactIcon />}
          text={t("header.contact")}
        />
      </MenuLink>

      <div className={styles.footerDropDown}>
        <p>
          {t("footer.fanbea2020")}
          {t("footer.homepage")}
        </p>

        <p>
          <MenuLink to="/privacy-policy">{t("footer.privacy")}</MenuLink>
          {" • "}
          <MenuLink to="/terms-and-conditions">{t("footer.terms")}</MenuLink>
        </p>

        <p>
          <MenuLink to="/2257">{t("footer.2257")}</MenuLink>
          {" • "}
          <MenuLink to="/dmca">{t("footer.dmca")}</MenuLink>
        </p>
      </div>
    </>
  );
  const menu = isLoggedIn ? loggedInMenu : loggedOutMenu;
  const dropDownMenu = isVisible && (
    <div
      onMouseEnter={() => setVisibile(true)}
      onMouseLeave={() => setVisibile(false)}
      className={styles.dropDown}
    >
      <OutClick onOutsideClick={() => setVisibile(false)}>{menu}</OutClick>
    </div>
  );
  return dropDownMenu;
};
export default DropDown;
