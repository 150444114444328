import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../button/Button";
import Popup from "../popup/Popup";
import styles from "./styles.module.css";

const Cookies = () => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", true);
    setOpen(false);
  };

  useEffect(() => {
    const areCookiesSet = localStorage.getItem("cookiesAccepted");
    if (areCookiesSet === "true") {
      setOpen(false);
    } else {
      localStorage.setItem("cookiesAccepted", false);
      setOpen(true);
    }
  }, [isOpen]);

  return (
    <Popup
      wrapperClass={styles.blue}
      isOpen={isOpen}
      heading={t("cookies.heading")}
      size="large"
      withoutOverlay
      text={(
        <>
          {t("cookies.description")}
          {" "}
          <Link to="/privacy-policy">{t("cookies.moreInfo")}</Link>
        </>
      )}
      buttons={(
        <Button secondary onClick={() => acceptCookies()}>
          {t("cookies.accept")}
        </Button>
      )}
    />
  );
};

export default Cookies;
