import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getPosts } from "../../data/blog/blogSlice";
import Layout from "../../components/layout/Layout";
import BlogPost from "./BlogPost";
import Pagination from "./Pagination";
import styles from "./styles.module.css";

const Blog = ({ perPage = 5 }) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.blog.posts);
  const { page } = useParams();
  const currentPage = page ? parseInt(page - 1, 10) : 0;
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getPosts({}));
  }, [dispatch]);

  return (
    <Layout
      pageTitle={`${t("blog.recentPosts")} - ${t("blog.page")} ${
        currentPage + 1
      }`}
    >
      <div className={styles.blog}>
        {posts
          .slice(currentPage * perPage, currentPage * perPage + perPage)
          .map((post) => (
            <BlogPost
              key={post.id}
              title={post.title}
              excerpt={post.excerpt}
              image={post.image.src}
              slug={post.slug}
            />
          ))}
      </div>
      <Pagination
        currentPage={currentPage + 1}
        pages={Math.ceil(posts.length / perPage)}
      />
    </Layout>
  );
};

export default Blog;
