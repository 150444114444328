import React from "react";

function BrokenHeartIcon({ height = "1em", width = "1em" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5466 3.46667C15.2328 2.85588 14.7801 2.3272 14.2248 1.9231C13.6696 1.519 13.0274 1.25075 12.3497 1.13988C11.672 1.02902 10.9777 1.07863 10.3227 1.28475C9.66763 1.49086 9.07006 1.84772 8.57795 2.32667C8.43661 2.45534 8.39328 2.48067 8.37195 2.54L6.57195 7.43867C6.5627 7.46395 6.55969 7.49109 6.56317 7.51779C6.56665 7.54448 6.57653 7.56994 6.59195 7.592C6.60726 7.61406 6.62769 7.63207 6.6515 7.6445C6.6753 7.65692 6.70176 7.66339 6.72861 7.66334H9.91995C9.9973 7.66325 10.0736 7.68113 10.1429 7.71557C10.2121 7.75001 10.2724 7.80007 10.3191 7.8618C10.3657 7.92354 10.3973 7.99525 10.4114 8.0713C10.4256 8.14734 10.4219 8.22564 10.4006 8.3L8.75928 14.07C8.74921 14.1057 8.75133 14.1437 8.76532 14.1781C8.7793 14.2124 8.80434 14.2411 8.83648 14.2597C8.86862 14.2782 8.90601 14.2854 8.94274 14.2803C8.97948 14.2752 9.01345 14.258 9.03928 14.2313L14.5133 8.59267C15.2305 7.97443 15.7213 7.13497 15.9085 6.20677C16.0956 5.27856 15.9683 4.31448 15.5466 3.46667Z"
        fill="#041E40"
      />
      <path
        d="M9.2001 8.8753C9.20699 8.85049 9.20811 8.82443 9.20337 8.79913C9.19863 8.77382 9.18816 8.74993 9.17276 8.7293C9.15731 8.70852 9.1372 8.69166 9.11404 8.68008C9.09088 8.6685 9.06532 8.66253 9.03943 8.66263H5.77276C5.69245 8.66222 5.61342 8.64246 5.54236 8.60504C5.4713 8.56762 5.4103 8.51363 5.36452 8.44764C5.31874 8.38166 5.28953 8.30561 5.27936 8.22594C5.26919 8.14628 5.27836 8.06533 5.3061 7.98996L7.3581 2.39996C7.36954 2.36888 7.37147 2.3351 7.36364 2.30292C7.3558 2.27074 7.33855 2.24163 7.3141 2.2193C6.82701 1.77349 6.24468 1.44462 5.61139 1.2577C4.9781 1.07079 4.31052 1.03075 3.65943 1.14063C2.98058 1.24634 2.33677 1.51253 1.78148 1.91708C1.22619 2.32163 0.775469 2.85286 0.466764 3.46663C0.0560154 4.27276 -0.0878494 5.18859 0.055951 6.08183C0.199751 6.97507 0.623772 7.79947 1.26676 8.43596C1.26676 8.43596 6.17143 13.728 7.23543 14.8653C7.25548 14.8869 7.28089 14.9027 7.30906 14.9113C7.33723 14.9198 7.36717 14.9208 7.39582 14.914C7.42448 14.9072 7.45082 14.893 7.47219 14.8727C7.49355 14.8524 7.50916 14.8269 7.51743 14.7986L9.2001 8.8753Z"
        fill="#041E40"
      />
    </svg>
  );
}

export default BrokenHeartIcon;
