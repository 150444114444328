import React, { useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import SearchIcon from "../assets/icons/SearchIcon";
import Loader from "../loaders/Loader";
import SearchResult from "./SearchResult";
import styles from "./styles.module.css";
import usersApi from "../../data/users/usersApi";

const SearchInput = ({ isMobile }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isFocused, setFocused] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const inputRef = useRef(null);

  const onTextChange = useCallback((event) => {
    if (event.target.value.length) {
      setLoading(true);
      usersApi
        .searchUsers({ username: event.target.value })
        .then(async (result) => {
          setLoading(false);
          const users = await result.json();
          setResults(users);
        });
    }
  }, []);

  const onBlur = useCallback(() => {
    setResults([]);
    setFocused(false);
    inputRef.current.value = "";
  }, []);

  return (
    <div className={!isMobile ? styles.search : styles.searchMobile}>
      <input
        ref={inputRef}
        onInput={(event) => {
          event.target.value = event.target.value
            .toLowerCase()
            .replace(/([^0-9a-z_])/g, "");
        }}
        className={!isMobile ? styles.searchInput : styles.searchInputMobile}
        placeholder={t("header.searchInput")}
        onFocus={() => setFocused(true)}
        onBlur={onBlur}
        onChange={onTextChange}
      />
      <div className={styles.searchIcon}>
        {isLoading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <SearchIcon
            style={styles.searchIcon}
            fill={isFocused ? "var(--black)" : "var(--darkGray)"}
          />
        )}
      </div>
      {isFocused && (
        <div className={styles.searchResults}>
          {results.map((result) => (
            <SearchResult
              key={result.id}
              onMouseDown={() => history.push(`/u/${result.link}`)}
              avatarUrl={result.avatar}
              primaryText={result.username}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default SearchInput;
