import api from "../api";

const postApi = {
  getUserPosts: ({ link, startAfterId }) =>
    api.get("user/posts", {
      searchParams: { link, ...(startAfterId ? { startAfterId } : {}) },
    }),
  createTextPost: (formData) =>
    api.post("create-post/text", {
      body: formData,
    }),
  createPhotoPost: (formData) =>
    api.post("create-post/photo", {
      body: formData,
    }),
  createVideoPost: ({ path, subscribersOnly }) =>
    api.post("create-post/video", {
      json: { path, subscribersOnly },
    }),
  deletePost: ({ id }) =>
    api.post("delete-post", {
      json: { id },
    }),
};

export default postApi;
