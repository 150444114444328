import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Button from "../../components/button/Button";
import SidebarBox from "../../components/sidebar/SidebarBox";
import ListItem from "../../components/list/ListItem";
import Accordion from "../../components/accordion/Accordion";
import { desktop } from "../../components/assets/breakpoints/breakpoints";
import styles from "./styles.module.css";
import Facebook from "./icons/facebook.svg";
import Instagram from "./icons/instagram.svg";
import Twitter from "./icons/twitter.svg";
import Twitch from "./icons/twitch.svg";
import Youtube from "./icons/youtube.svg";
import Smartphone from "../../components/assets/icons/Smartphone";

const SocialMedia = ({ facebook, instagram, twitter, twitch, youtube }) => {
  const { t } = useTranslation();
  const { link } = useParams();
  const isDesktop = useMediaQuery(desktop);
  const user = useSelector((state) => state.user);
  const isOwner = user.link === link;

  if (!facebook && !instagram && !twitter && !twitter && !twitch && !youtube) {
    return null;
  }
  const socialMediaList = (
    <>
      {facebook && (
        <ListItem
          onClick={() =>
            window.open(`http://facebook.com/${facebook}`, "_blank")}
          avatarUrl={Facebook}
          primaryText={t("star.facebook")}
          secondaryText={`/${facebook}`}
          renderButtons={() => <Button transparent>View</Button>}
        />
      )}
      {instagram && (
        <ListItem
          onClick={() =>
            window.open(`http://instagram.com/${instagram}`, "_blank")}
          avatarUrl={Instagram}
          primaryText={t("star.instagram")}
          secondaryText={`/${instagram}`}
          renderButtons={() => <Button transparent>View</Button>}
        />
      )}
      {twitter && (
        <ListItem
          onClick={() => window.open(`http://twitter.com/${twitter}`, "_blank")}
          avatarUrl={Twitter}
          primaryText={t("star.twitter")}
          secondaryText={`/${twitter}`}
          renderButtons={() => <Button transparent>View</Button>}
        />
      )}
      {twitch && (
        <ListItem
          onClick={() => window.open(`http://twitch.tv/${twitch}`, "_blank")}
          avatarUrl={Twitch}
          primaryText={t("star.twitch")}
          secondaryText={`/${twitch}`}
          renderButtons={() => <Button transparent>View</Button>}
        />
      )}
      {youtube && (
        <ListItem
          onClick={() =>
            window.open(`http://youtube.com/c/${youtube}`, "_blank")}
          avatarUrl={Youtube}
          primaryText={t("star.youtube")}
          secondaryText={`/${youtube}`}
          renderButtons={() => <Button transparent>View</Button>}
        />
      )}
    </>
  );
  return isDesktop ? (
    <SidebarBox
      wrapperClass={styles.socialMediaWrapper}
      withPadding={false}
      heading={(
        <>
          <Smartphone />
          <span>Social media</span>
          <Smartphone />
        </>
      )}
      contentClass={styles.socialMediaList}
    >
      {socialMediaList}
    </SidebarBox>
  ) : (
    !isOwner && (
      <Accordion
        hide
        hideable={!isDesktop}
        heading="Social Media"
        childrenWrapperClass={styles.socialMediaList}
      >
        {socialMediaList}
      </Accordion>
    )
  );
};

export default SocialMedia;
