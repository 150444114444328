import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";
import UserOverview from "../../components/userOverview/UserOverview";
import Button from "../../components/button/Button";
import Popup from "../../components/popup/Popup";
import SidebarStatistics from "../../components/sidebar/SidebarStatistics";
import SidebarBecomeStar from "../../components/sidebar/SidebarBecomeStar";
import SettingsGeneral from "./SettingsGeneral";
import SettingsPayments from "./SettingsPayments";
import SettingsDisableAccount from "./SettingsDisableAccount";
import SettingsSocialMedia from "./SettingsSocialMedia";
import SettingsNotifications from "./SettingsNotifications";
import SettingsNudity from "./SettingsNudity";
import { updateCurrentUser, clearErrors } from "../../data/user/userSlice";
import DiscIcon from "../../components/assets/emoticons/DiscIcon";
import styles from "./styles.module.css";

const Settings = () => {
  const { register, control, handleSubmit, formState, watch, reset } = useForm({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const formRef = useRef(null);
  const [isSaving, setSaving] = useState(false);
  useEffect(() => {
    dispatch(clearErrors({}));
  }, [dispatch]);

  const onSubmit = (data) => {
    setSaving(true);
    dispatch(
      updateCurrentUser({ data: { ...data, settings: data.settings } })
    ).then((resultAction) => {
      setSaving(false);
      if (updateCurrentUser.fulfilled.match(resultAction)) {
        reset({});
      }
    });
  };

  return (
    <Layout
      pageTitle="Settings"
      left={<UserOverview />}
      right={(
        <>
          {!user.isCreator && <SidebarBecomeStar />}
          <SidebarStatistics />
        </>
      )}
      leftHideOnMobile
      rightHideOnMobile
    >
      <div className={styles.settings}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <SettingsGeneral register={register} user={user} watch={watch} />
          <SettingsPayments register={register} user={user} watch={watch} />
          <SettingsSocialMedia register={register} user={user} />
          <SettingsNotifications register={register} user={user} />
          <SettingsNudity register={register} user={user} control={control} />
          <Popup
            isOpen={formState.isDirty}
            withoutOverlay
            size="large"
            heading={(
              <>
                {t("settings.saveSettings")}
                <DiscIcon />
              </>
            )}
            text={(
              <>
                <p>{t("settings.saveSettingsDescriptionFirst")}</p>
                <p>{t("settings.saveSettingsDescriptionSecond")}</p>
              </>
            )}
            buttons={(
              <Button
                primary
                isLoading={isSaving}
                onClick={() =>
                  formRef.current.dispatchEvent(new Event("submit"))}
              >
                {t("settings.save")}
              </Button>
            )}
          />
        </form>
        <SettingsDisableAccount register={register} user={user} />
      </div>
    </Layout>
  );
};

export default Settings;
