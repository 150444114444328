import React from "react";

function SendMessageIcon({ fill = "#C4C4C4", circle = "#F2F2F2" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      style={{ fill }}
      viewBox="0 0 32 32"
    >
      <circle cx="16" cy="16" r="16" fill={circle} />
      <path d="M23.322 8.425a.333.333 0 00-.479-.372l-13.655 7.16a.974.974 0 00-.067 1.684l3.04 1.922a.165.165 0 00.2-.017l8.105-7.245a.167.167 0 01.241.228L14.23 19.91a.166.166 0 00-.009.198.17.17 0 00.05.05l4.288 2.71a.969.969 0 001.46-.597l3.303-13.845zM13.256 20.788a.167.167 0 00-.256.14v2.39a.667.667 0 001.138.472l1.345-1.345a.168.168 0 00.023-.206.167.167 0 00-.052-.052l-2.198-1.4z" />
    </svg>
  );
}

export default SendMessageIcon;
