import React from "react";

function SearchIcon({ height = "1em", width = "1em", fill = "gray" }) {
  return (
    <>
      <svg width={width} height={height} viewBox="0 0 16 16" style={{ fill }}>
        <path d="M15.61 13.727l-3.097-3.096a6.838 6.838 0 10-1.886 1.886l3.097 3.096a1.35 1.35 0 001.885 0 1.333 1.333 0 000-1.886zM6.832 2.003a4.833 4.833 0 110 9.667 4.833 4.833 0 010-9.667z" />
      </svg>
    </>
  );
}

export default SearchIcon;
