import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { clearErrors, signin, logout } from "../../data/auth/authSlice";
import { deleteAccount } from "../../data/user/userSlice";

import Modal from "../../components/modal/Modal";
import Button from "../../components/button/Button";
import TextInput from "../../components/textInput/TextInput";
import DissapointedIcon from "../../components/assets/emoticons/DissapointedIcon";
import styles from "./styles.module.css";

const ModalDisableAccount = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const formRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.auth.errors.signin);

  useEffect(() => {
    dispatch(clearErrors({}));
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(signin(data)).then((signinAction) => {
      if (signin.fulfilled.match(signinAction)) {
        dispatch(deleteAccount()).then((deleteAccountAction) => {
          if (deleteAccount.fulfilled.match(deleteAccountAction)) {
            dispatch(logout());
            history.push("/");
          }
        });
      }
    });
  };

  const emailError = () => {
    if (error && error.message === "INVALID_EMAIL") {
      return t("signin.invalidEmail");
    }
    if (error && error.message === "EMAIL_NOT_FOUND") {
      return t("signin.emailNotFound");
    }
    return null;
  };

  const passwordError = () => {
    if (error && error.message === "INVALID_PASSWORD") {
      return t("signin.invalidPassword");
    }
    if (error && error.message === "MISSING_PASSWORD") {
      return t("signin.missingPassword");
    }
    if (error && error.message.startsWith("TOO_MANY_ATTEMPTS_TRY_LATER")) {
      return t("signin.tooManyAttempts");
    }
    return null;
  };

  return (
    <Modal
      isOpen={isOpen}
      size="medium"
      buttons={(
        <>
          <Button
            warning
            onClick={(event) => {
              event.preventDefault();
              formRef.current.dispatchEvent(new Event("submit"));
            }}
          >
            {t("settings.disableAccount")}
          </Button>
          <Button transparent onClick={onClose}>
            {t("settings.cancel")}
          </Button>
        </>
      )}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
        className={styles.disableAccount}
      >
        <h2 className={styles.disableAccountHeading}>
          {t("settings.disableAccountModalHeading")}
        </h2>
        <p className={styles.disableAccountDescription}>
          {t("settings.disableAccountModalDescription")}
        </p>
        <TextInput
          name="email"
          ref={register}
          label="Email"
          placeholder={t("signin.emailPlaceholder")}
          error={emailError()}
        />
        <TextInput
          name="password"
          type="password"
          ref={register}
          label="Password"
          placeholder={t("signin.passwordPlaceholder")}
          error={passwordError()}
        />
      </form>
    </Modal>
  );
};

const SettingsDisableAccount = () => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div className={styles.box}>
      <h2 className={styles.heading}>
        <>
          {t("settings.disableAccount")}
          <DissapointedIcon />
        </>
      </h2>
      <p className={styles.description}>
        {t("settings.disableAccountDescription")}
      </p>
      <button
        type="button"
        onClick={() => setModalOpen(true)}
        className={styles.disableAccountButton}
      >
        {t("settings.disableAccountButton")}
      </button>
      <ModalDisableAccount
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
    </div>
  );
};

export default SettingsDisableAccount;
