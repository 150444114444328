import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { desktop } from "../assets/breakpoints/breakpoints";
import Arrow from "./Arrow";
import styles from "./styles.module.css";

const Accordion = ({
  childrenWrapperClass,
  children,
  heading,
  hideable,
  hide = false,
}) => {
  const [ishidden, setHidden] = useState(hide);
  const isDesktop = useMediaQuery(desktop);
  useEffect(() => {
    if (isDesktop && !hide) {
      setHidden(false);
    }
  }, [isDesktop, hide]);

  return (
    <div className={styles.container}>
      <div
        role="button"
        tabIndex="0"
        onClick={() => hideable && setHidden(!ishidden)}
        className={`${
          heading && (hideable ? styles.heading : styles.emptyHeading)
        }
          ${
            hideable
              ? !ishidden
                ? styles.headingShown
                : styles.headingHidden
              : ""
          }
          `}
      >
        <div>{heading}</div>
        {hideable && (
          <div className={`${styles.arrow} ${ishidden ? styles.arrowUp : ""}`}>
            <Arrow />
          </div>
        )}
      </div>
      <div
        className={
          ishidden
            ? styles.hide
            : `${childrenWrapperClass || styles.contentContainer}`
        }
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
