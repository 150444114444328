import React from "react";

function SettingsIcon({ height = "1em", width = "1em" }) {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 16 16">
      <path
        fill="#041E40"
        d="M14.947 6.51l-.844-.3a.917.917 0 01-.52-1.257l.384-.809a1.584 1.584 0 00-2.11-2.11l-.808.385a.917.917 0 01-1.257-.522l-.3-.843a1.584 1.584 0 00-2.984 0l-.3.844a.917.917 0 01-1.257.52l-.808-.384a1.584 1.584 0 00-2.11 2.11l.384.809a.917.917 0 01-.52 1.257l-.844.3a1.583 1.583 0 000 2.983l.844.3a.916.916 0 01.52 1.257l-.384.809a1.584 1.584 0 002.11 2.11l.808-.384a.915.915 0 011.257.52l.3.844a1.584 1.584 0 002.984 0l.3-.844a.916.916 0 011.257-.52l.808.384a1.585 1.585 0 002.11-2.11l-.384-.809a.92.92 0 01.196-1.058.916.916 0 01.324-.199l.844-.3a1.583 1.583 0 000-2.984zM8 11.19a3.287 3.287 0 01-2.96-1.962 3.209 3.209 0 011.733-4.187 3.245 3.245 0 014.187 1.733 3.209 3.209 0 01-1.733 4.187 3.06 3.06 0 01-1.227.23z"
      />
    </svg>
  );
}

export default SettingsIcon;
