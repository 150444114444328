import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import styles from "./styles.module.css";
import Radio from "../../components/radio/Radio";
import AdultContentIcon from "../../components/assets/emoticons/AdultContentIcon";

const SettingsNudity = ({ user, control }) => {
  const { t } = useTranslation();

  if (!user.isCreator) {
    return null;
  }

  return (
    <div className={styles.box}>
      <h2 className={styles.heading}>
        <>
          {t("settings.nuditySettings")}
          <AdultContentIcon />
        </>
      </h2>
      <div className={styles.description}>
        <p>{t("settings.nuditySettingsDescription")}</p>
      </div>
      <Controller
        name="settings.isNudity"
        render={(props) => (
          <Radio
            {...props}
            value="false"
            defaultChecked={!user.settings.isNudity}
            name="settings.isNudity"
            label={t("settings.nuditySettingsNo")}
            onChange={(e) => {
              props.onChange(e.target.value === "true");
            }}
          />
        )}
        control={control}
      />
      <Controller
        name="settings.isNudity"
        render={(props) => (
          <Radio
            {...props}
            value="true"
            defaultChecked={user.settings.isNudity}
            name="settings.isNudity"
            label={t("settings.nuditySettingsYes")}
            onChange={(e) => {
              props.onChange(e.target.value === "true");
            }}
          />
        )}
        control={control}
      />
    </div>
  );
};

export default SettingsNudity;
