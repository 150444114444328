import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles.module.css";

const Logo = () => (
  <Link to="/">
    <div className={styles.logo} />
  </Link>
);
export default Logo;
