import React from "react";

function BlogIcon({ height = "1em", width = "1em" }) {
  return (
    <>
      <svg width={width} height={height} fill="none" viewBox="0 0 16 16">
        <path
          fill="#041E40"
          d="M8.092 10.267l-2.804 2.8a1.742 1.742 0 01-2.153.288 1.677 1.677 0 01-.268-2.574L5.733 7.91a10.58 10.58 0 011.6-4.362.163.163 0 00-.133-.254H1.041a1 1 0 00-1 1v10.666a1 1 0 001 1h10.667a1 1 0 001-1V8.804a.164.164 0 00-.255-.133 10.582 10.582 0 01-4.361 1.596z"
        />
        <path
          fill="#041E40"
          d="M15.613.04a9.829 9.829 0 00-4.206 1.12.171.171 0 00-.09.173l.216 1.636a.333.333 0 01-.516.32l-1.246-.836a.164.164 0 00-.21.02 85.78 85.78 0 00-.885.916 8.903 8.903 0 00-1.11 1.784 9.52 9.52 0 00-.582 1.587 9.465 9.465 0 00-.184.813 16.68 16.68 0 00-.067.4c-.01.074 0 .35-.048.4L3.57 11.488a.667.667 0 000 .943.675.675 0 00.943 0L7.63 9.313a9.333 9.333 0 005.672-2.608 9.425 9.425 0 002.657-6.32.334.334 0 00-.346-.344z"
        />
      </svg>
    </>
  );
}

export default BlogIcon;
