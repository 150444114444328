import React from "react";

function LogOutIcon({ height = "1em", width = "1em" }) {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 16 16">
      <path
        fill="#041E40"
        d="M8 0a8 8 0 108 8 8.009 8.009 0 00-8-8zM5.333 4.667a1.333 1.333 0 110 2.666 1.333 1.333 0 010-2.666zm-.558 6.968a3.85 3.85 0 016.45 0 .667.667 0 11-1.117.73 2.513 2.513 0 00-4.216 0 .667.667 0 01-1.117-.73zm5.892-4.302a1.334 1.334 0 110-2.667 1.334 1.334 0 010 2.667zM13.333 11a1.667 1.667 0 01-1.666-1.667c0-.757 1.127-2.524 1.528-3.127a.166.166 0 01.277 0c.4.603 1.528 2.37 1.528 3.127A1.666 1.666 0 0113.333 11z"
      />
    </svg>
  );
}

export default LogOutIcon;
