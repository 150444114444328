const smallDevice = {
  maxWidth: 576,
};
const mediumDevice = {
  maxWidth: 768,
};

const largeDevices = {
  maxWidth: 992,
};

const smallDesktop = {
  maxWidth: 992,
};
const desktop = {
  minWidth: 1200,
};
const largeDesktop = {
  minWidth: 1300,
};

module.exports = {
  smallDevice,
  mediumDevice,
  largeDevices,
  smallDesktop,
  desktop,
  largeDesktop,
};
