import React from "react";
import styles from "./styles.module.css";
import facebook from "./facebook.svg";
import twitter from "./twitter.svg";
import instagram from "./instagram.svg";
import logo from "./logo.svg";

const Maintenance = () => {
  return (
    <div className={styles.wrapper}>
      <img src={logo} width="157" height="51" alt="Fanbea" />
      <div className={styles.box}>
        <h1 className={styles.heading}>We are currently offline.</h1>
        <p>
          Dear Stars & Fans, Due to recent and continuous issues with PayPal, as
          well as issues many Stars had with it, we have decided to take down
          Fanbea for the time being and redesign the whole payment
          functionality: all in order to ensure secure, transparent and
          compliant payment system that offers a seamless experience for both
          Stars and their Fans, completely risk-free.
        </p>
        <p>
          At the moment we estimate that the platform will be back in Q2 2021.
        </p>
        <p>
          We were very happy to see you coming in such number after the platform
          launched and our determination to bring back Fanbea is firm. Follow
          our social media, where we'll continue to update you on the progress
          as well as share more details about new payment solution.
        </p>
        <div className={styles.social}>
          <a
            href="https://facebook.com/FanbeaCommunity"
            className={styles.socialItem}
          >
            <img src={facebook} width="24" height="24" alt="Facebook" />
          </a>
          <a
            href="https://twitter.com/FanbeaCommunity"
            className={styles.socialItem}
          >
            <img src={twitter} width="24" height="24" alt="Twitter" />
          </a>
          <a
            href="https://instagram.com/FanbeaCommunity"
            className={styles.socialItem}
          >
            <img src={instagram} width="24" height="24" alt="Instagram" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
