import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../../firebase";
import postsApi from "./postsApi";

const initialState = {};

const createTextPost = createAsyncThunk(
  "posts/createTextPost",
  async ({ formData }, { rejectWithValue }) => {
    try {
      return await postsApi.createTextPost(formData).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const createPhotoPost = createAsyncThunk(
  "posts/createPhotoPost",
  async ({ formData }, { rejectWithValue }) => {
    try {
      return await postsApi.createPhotoPost(formData).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const createVideoPost = createAsyncThunk(
  "posts/createVideoPost",
  async ({ path, subscribersOnly }, { rejectWithValue }) => {
    try {
      return await postsApi.createVideoPost({ path, subscribersOnly }).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const uploadVideo = createAsyncThunk(
  "posts/uploadVideo",
  async ({ subscribersOnly, file, userId }, { rejectWithValue }) => {
    try {
      const storageRef = storage.ref();
      const extension = [...file.name.split(".")].pop();

      const generatedFileName = `${Date.now()}_${uuidv4()}`;
      const postsPath = `posts/${userId}/${generatedFileName}`;
      const subscribersOnlyPostsPath = `subscribersOnlyPosts/${userId}/${generatedFileName}`;
      const fileName = `${
        subscribersOnly ? subscribersOnlyPostsPath : postsPath
      }.${extension}`;

      const fileRef = storageRef.child(fileName);
      const result = await fileRef.put(file);
      return result.metadata;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getUserPosts = createAsyncThunk(
  "posts/getUserPosts",
  async ({ link, startAfterId }, { rejectWithValue }) => {
    try {
      return await postsApi.getUserPosts({ link, startAfterId }).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const getMoreUserPosts = createAsyncThunk(
  "posts/getMoreUserPosts",
  async ({ link, startAfterId }, { rejectWithValue }) => {
    try {
      return await postsApi.getUserPosts({ link, startAfterId }).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

const deletePost = createAsyncThunk(
  "users/deletePost",
  async ({ id }, { rejectWithValue }) => {
    try {
      return await postsApi.deletePost({ id }).json();
    } catch (error) {
      return rejectWithValue(await error.response.json());
    }
  }
);

export const postSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {},
  extraReducers: {
    [getUserPosts.fulfilled]: (state, action) => ({
      ...state,
      [action.payload.author]: action.payload.posts,
    }),
    [getMoreUserPosts.fulfilled]: (state, action) => ({
      ...state,
      [action.payload.author]: [
        ...state[action.payload.author],
        ...action.payload.posts,
      ],
    }),
    [createTextPost.fulfilled]: (state, action) => {
      return {
        ...state,
        [action.payload.author]: [
          action.payload,
          ...(state[action.payload.author] ? state[action.payload.author] : []),
        ],
      };
    },
    [createPhotoPost.fulfilled]: (state, action) => ({
      ...state,
      [action.payload.author]: [
        action.payload,
        ...(state[action.payload.author] ? state[action.payload.author] : []),
      ],
    }),
    [createVideoPost.fulfilled]: (state, action) => ({
      ...state,
      [action.payload.author]: [
        action.payload,
        ...(state[action.payload.author] ? state[action.payload.author] : []),
      ],
    }),
    [deletePost.fulfilled]: (state, action) => ({
      ...state,
      [action.payload.author]: state[action.payload.author].filter(
        (item) => item.id !== action.payload.id
      ),
    }),
  },
});

export {
  getUserPosts,
  getMoreUserPosts,
  createTextPost,
  createPhotoPost,
  createVideoPost,
  uploadVideo,
  deletePost,
};

export default postSlice.reducer;
