import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/Layout";
import Loader from "../../components/loaders/Loader";
import Confetti from "../../components/confetti/Confetti";
import ErrorNotFound from "../errors/ErrorNotFound";
import ContextMenu from "../../components/contextMenu/ContextMenu";
import ContextMenuItem from "../../components/contextMenu/ContextMenuItem";
import BrokenHeartIcon from "../../components/assets/icons/BrokenHeartIcon";
import AtomicBombIcon from "../../components/assets/icons/AtomicBombIcon";
import UserOverview from "../../components/userOverview/UserOverview";
import UserOverviewSupporting from "../../components/userOverview/UserOverviewSupporting";
import SidebarStatistics from "../../components/sidebar/SidebarStatistics";
import SidebarSubscribe from "../../components/sidebar/SidebarSubscribe";
import SidebarSubscriptionCost from "../../components/sidebar/SidebarSubscriptionCost";
import SidebarMessage from "../../components/sidebar/SidebarMessage";
import SidebarBecomeStar from "../../components/sidebar/SidebarBecomeStar";
import ModalSubscribe from "../../components/modal/modalSubscribe/ModalSubscribe";
import ModalAgeVerification from "../../components/modal/modalAgeVerification/ModalAgeVerification";
import Post from "../../components/post/Post";
import { unfollowUser, getUser } from "../../data/users/usersSlice";
import {
  getUserPosts,
  getMoreUserPosts,
  deletePost,
} from "../../data/posts/postsSlice";
import { listCards } from "../../data/user/userSlice";
import { selectUserWithLink } from "../../data/users/usersSelectors";
import FollowButton from "./FollowButton";
import SocialMedia from "./SocialMedia";
import CreatePost from "../../components/createPost/CreatePost";
import styles from "./styles.module.css";

const Star = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { link } = useParams();
  const location = useLocation();
  const history = useHistory();
  const locationState = location.state || {};
  const user = useSelector((state) => state.user);
  const cards = useSelector((state) => state.user.cards);
  const isOwner = user.link === link;
  const auth = useSelector((state) => state.auth);
  const star = useSelector((state) => selectUserWithLink(state, link));
  const posts = useSelector((state) => state.posts);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isConfettiRunning, setConfettiRunning] = useState(false);
  const [isError, setError] = useState(false);
  const [isModalSubscribeOpen, setModalSubscribeOpen] = useState(false);
  const [isModalAgeVerificationOpen, setModalAgeVerificationOpen] = useState(
    !user.settings.showAdultContent
  );

  const postsByStar = star ? posts[star.id] || [] : [];
  const isFollowing = star
    ? Object.keys(user.following).indexOf(star.id) > -1
    : false;
  const isSubscriber = star
    ? Object.keys(star.subscribers).indexOf(user.id) > -1
    : null;

  const fetchStar = useCallback(() => {
    dispatch(getUser({ link })).then((resultAction) => {
      if (getUser.fulfilled.match(resultAction)) {
        setIsFetching(true);
        dispatch(getUserPosts({ link })).then(() => {
          setIsFetching(false);
        });
      }
      if (getUser.rejected.match(resultAction)) {
        setError(true);
      }
    });
  }, [link, dispatch]);

  useEffect(() => {
    fetchStar();
  }, [fetchStar, link, dispatch]);

  useEffect(() => {
    let confettiTimeout = null;
    if (locationState.withConfetti) {
      setConfettiRunning(true);
      confettiTimeout = setTimeout(() => {
        setConfettiRunning(false);
      }, 3000);
    }
    return () => {
      if (confettiTimeout) {
        clearTimeout(confettiTimeout);
      }
    };
  }, [locationState.withConfetti]);

  const onUnlock = () => {
    if (!auth.isLoggedIn) {
      history.push("/signin", { from: location.pathname });
    } else {
      dispatch(listCards()).then(() => {
        setModalSubscribeOpen(true);
        if (cards.length) {
          setModalSubscribeOpen(true);
        } else {
          //history.push("/settings");
        }
      });
    }
  };

  const onLoadMore = () => {
    if (postsByStar.length && !isFetching) {
      setIsFetching(true);
      dispatch(
        getMoreUserPosts({
          link,
          startAfterId: postsByStar[postsByStar.length - 1].id,
        })
      ).then((resultAction) => {
        if (
          resultAction.payload.posts &&
          resultAction.payload.posts.length === 0
        ) {
          setHasNextPage(false);
        }
        setIsFetching(false);
      });
    }
  };

  const infiniteScrollRef = useInfiniteScroll({
    loading: isFetching,
    hasNextPage,
    onLoadMore,
  });

  const right = () => {
    if (isOwner) {
      return (
        <div className={styles.sidebarOwner}>
          <SidebarStatistics wrapperClass={styles.sidebarBox} />
          {star && (
            <SidebarSubscriptionCost
              wrapperClass={styles.sidebarBox}
              onSuccess={() => fetchStar()}
              planPrice={star.payments.planPrice}
              planId={star.payments.planId}
            />
          )}
          {star && star.socialMedia && <SocialMedia {...star.socialMedia} />}
        </div>
      );
    }
    return (
      <div className={styles.sidebar}>
        {isSubscriber === false && (
          <SidebarSubscribe
            postsToUnlock={star.postsToUnlock}
            username={star.username}
            planPrice={star.payments.planPrice}
            onUnlock={() => onUnlock()}
          />
        )}
        {isSubscriber === true && <SidebarMessage id={star.id} />}
        {star && star.socialMedia && <SocialMedia {...star.socialMedia} />}
        {!user.isCreator && auth.isLoggedIn && <SidebarBecomeStar />}
      </div>
    );
  };

  const left = () => (
    <UserOverview>
      <UserOverviewSupporting />
    </UserOverview>
  );

  const renderPosts = () => (
    <div ref={infiniteScrollRef}>
      {postsByStar.map((post) => (
        <Post
          key={post.id}
          username={star.username}
          category={star.category}
          avatar={star.avatar}
          text={post.text}
          images={post.images}
          videos={post.videos}
          link={post.link}
          subscribersOnly={post.subscribersOnly}
          isSubscriber={isSubscriber || isOwner}
          price={star.payments.planPrice}
          onUnlock={() => onUnlock()}
          renderMenu={
            auth.isLoggedIn
              ? () => (
                <ContextMenu>
                  {isOwner ? (
                    <ContextMenuItem
                      onClick={() => {
                          dispatch(deletePost({ id: post.id }));
                        }}
                      icon={<AtomicBombIcon />}
                    >
                      {t("post.deletePost")}
                    </ContextMenuItem>
                    ) : (
                      <ContextMenuItem
                        onClick={() => dispatch(unfollowUser({ link }))}
                        icon={<BrokenHeartIcon />}
                      >
                        {t("post.unfollowStar")}
                      </ContextMenuItem>
                    )}
                </ContextMenu>
                )
              : null
          }
        />
      ))}
    </div>
  );

  if (isError) {
    return <ErrorNotFound />;
  }

  return (
    <Layout
      pageTitle={t("star.profile")}
      left={star && left()}
      right={star && right()}
      leftHideOnMobile
      rightHideOnMobile={false}
      mobilePosition="top"
    >
      <Helmet>
        <title>{star ? star.username : ""} (@{link})</title>
        <meta name="description" content="Join Fanbea today! Follow your favorite creators, chat with stars, and become a fan number one!" />
      </Helmet>
      <div className={styles.feed}>
        {star && !isOwner && (
          <UserOverview user={star}>
            <FollowButton
              isFollowing={isFollowing}
              isSubscriber={isSubscriber}
              link={link}
            />
          </UserOverview>
        )}
        {isOwner && <CreatePost />}

        {posts && renderPosts()}
        {hasNextPage && (
          <div className={styles.loader}>
            {(isFetching || !star) && <Loader />}
          </div>
        )}
      </div>
      {star && (
        <ModalSubscribe
          isOpen={isModalSubscribeOpen}
          link={link}
          planId={star.payments.planId}
          planPrice={star.payments.planPrice}
          closeModal={() => setModalSubscribeOpen(false)}
          onSuccess={() => {
            history.replace(location.pathname, { withConfetti: true });
            fetchStar();
          }}
        />
      )}
      {locationState.withConfetti && (
        <Confetti
          isActive={isConfettiRunning}
          onConfettiComplete={() => history.replace()}
        />
      )}
      {star && star.settings.isNudity && !isOwner && (
        <ModalAgeVerification
          isOpen={isModalAgeVerificationOpen}
          closeModal={() => setModalAgeVerificationOpen(false)}
        />
      )}
    </Layout>
  );
};

export default Star;
