import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Signup from "./auth/Signup";
import Signin from "./auth/Signin";
import AuthAction from "./auth/AuthAction";
import Star from "./star/Star";
import ResetPassword from "./auth/ResetPassword";
import Membership from "./membership/Membership";
import Settings from "./settings/Settings";
import Feed from "./feed/Feed";
import ErrorNotFound from "./errors/ErrorNotFound";
import Messages from "./messages/Messages";
import Conversation from "./messages/conversation/Conversation";
import BecomeStar from "./becomeStar/BecomeStar";
import ContactForm from "./contactForm/ContactForm";
import Homepage from "./homePage/HomePage";
import Blog from "./blog/Blog";
import BlogPostSingle from "./blog/BlogPostSingle";
import FAQ from "./docs/FAQ";
import Cookies from "../components/cookies/Cookies";
import Search from "./search/Search";
import PrivacyPolicy from "./docs/PrivacyPolicy";
import TermsAndConditions from "./docs/TermsAndConditions";
import Payouts from "./payouts/Payouts";
import DMCA from "./docs/dmca";
import Doc2257 from "./docs/2257";
import Maintenance from "./maintenance/Maintenance";
import { AuthController } from "../controllers";
import { getCurrentUser } from "../data/user/userSlice";

const PrivateRoute = ({ children, ...rest }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.isLoggedIn) {
      dispatch(getCurrentUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const StarsOnlyRoute = ({ children, ...rest }) => {
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  return (
    <Route
      {...rest}
      render={() =>
        auth.isLoggedIn && user.isCreator ? children : <ErrorNotFound />
      }
    />
  );
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}


const App = () => {
  return (
    <div className="App">
      <Helmet>
        <title>Fanbea.</title>
        <meta
          name="description"
          content="Join Fanbea today! Follow your favorite creators, chat with stars, and become a fan number one!"
        />
      </Helmet>
      <Router>
        <Header />
        <AuthController />
        <Switch>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route path="/search">
            <Search />
          </Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <PrivateRoute path="/membership">
            <Membership />
          </PrivateRoute>
          <Route path="/u/:link">
            <Star />
          </Route>
          <PrivateRoute path="/messages/:id">
            <Conversation />
          </PrivateRoute>
          <PrivateRoute path="/messages">
            <Messages />
          </PrivateRoute>
          <Route path="/signin">
            <Signin />
          </Route>
          <PrivateRoute path="/feed">
            <Feed />
          </PrivateRoute>
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
          <Route path="/auth-action">
            <AuthAction />
          </Route>
          <PrivateRoute path="/settings">
            <Settings />
          </PrivateRoute>
          <StarsOnlyRoute path="/payouts">
            <Payouts />
          </StarsOnlyRoute>
          <Route path="/contact">
            <ContactForm />
          </Route>
          <PrivateRoute path="/become-a-star">
            <BecomeStar />
          </PrivateRoute>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/dmca">
            <DMCA />
          </Route>
          <Route path="/2257">
            <Doc2257 />
          </Route>
          <Route path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route exact path="/blog">
            <Blog />
          </Route>
          <Route path="/blog/post/:slug">
            <BlogPostSingle />
          </Route>
          <Route path="/blog/:page">
            <Blog />
          </Route>
          <Route>
            <ErrorNotFound />
          </Route>
        </Switch>
        <Footer />
        <Cookies />
      </Router>
    </div>
  );
};

const AppOrMaintenance = getCookie("test") ? App : Maintenance;

export default AppOrMaintenance;
