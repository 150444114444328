import React from "react";
import LoaderWrapper from "../loaders/LoaderWrapper";
import ContextMenu from "../contextMenu/ContextMenu";
import ContextMenuItem from "../contextMenu/ContextMenuItem";
import AtomicBombIcon from "../assets/icons/AtomicBombIcon";
import styles from "./styles.module.css";
import Mastercard from "./svgs/mastercard.svg";
import Visa from "./svgs/visa.svg";

const Card = ({
  id,
  brand,
  first6,
  actionText,
  onAction,
  isLoading = false,
}) => {
  return (
    <LoaderWrapper isLoading={isLoading}>
      <div className={styles.card}>
        <div className={styles.cardBrand}>
          {brand === "Visa" ? (
            <img src={Visa} alt="Visa" />
          ) : (
            <img src={Mastercard} alt="Mastercard" />
          )}
        </div>
        <div className={styles.cardInfo}>
          <p className={styles.cardName}>
            {brand === "visa" ? "Visa card" : "Mastercard card"}
          </p>
          <p className={styles.cardNumber}>{`${first6}**********`}</p>
        </div>
        <div className={styles.cardMenu}>
          <ContextMenu>
            <ContextMenuItem
              onClick={() => onAction(id)}
              icon={<AtomicBombIcon />}
            >
              {actionText}
            </ContextMenuItem>
          </ContextMenu>
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default Card;
