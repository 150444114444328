import React from "react";
import CustomScroll from "react-custom-scroll";

const List = ({ children, childrenClass }) => {
  return (
    <CustomScroll addScrolledClass>
      <div className={childrenClass}>{children}</div>
    </CustomScroll>
  );
};
export default List;
