import React, { useState, useRef } from "react";
import RelativePortal from "react-relative-portal";
import styles from "./styles.module.css";
import DotsIcon from "../assets/icons/DotsIcon";

const ContextMenu = ({ children }) => {
  const dotsRef = useRef(null);
  const dotsWrapperRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className={styles.contextMenu}>
      <div
        ref={dotsWrapperRef}
        className={styles.icon}
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      >
        <DotsIcon ref={dotsRef} />
      </div>
      {showMenu && (
        <RelativePortal
          left={0}
          top={0}
          onOutClick={(event) => {
            if (
              dotsRef.current &&
              !dotsRef.current.contains(event.target) &&
              dotsRef.current !== event.target
            ) {
              setTimeout(() => {
                setShowMenu(false);
              }, 0);
            }
          }}
        >
          <div className={styles.items}>{children}</div>
        </RelativePortal>
      )}
    </div>
  );
};

export default ContextMenu;
