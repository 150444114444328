import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./styles.module.css";

const ContextMenuItem = ({ to, onClick, children, icon, warning }) => {
  const modeClassName = classNames(styles.button, {
    [styles.warning]: warning,
  });
  if (to) {
    return (
      <Link to={to} className={modeClassName}>
        {icon && <span className={styles.itemIcon}>{icon}</span>}
        {children}
      </Link>
    );
  }
  return (
    <div onClick={onClick} className={modeClassName}>
      {icon && <span className={styles.itemIcon}>{icon}</span>}
      {children}
    </div>
  );
};

export default ContextMenuItem;
