import React, { forwardRef } from "react";
import Cleave from "cleave.js/react";
import styles from "./styles.module.css";

const MaskedTextInput = forwardRef(
  (
    {
      name,
      label,
      onChange,
      placeholder,
      error,
      wrapperClassName = "",
      defaultValue,
      prefix,
      ...rest
    },
    ref
  ) => (
    <div className={`${styles.wrapper} `}>
      {label && <p className={styles.label}>{label}</p>}
      <label
        className={`${styles.inputWrapper} ${wrapperClassName} ${
          error ? styles.withError : ""
        }`}
      >
        <Cleave
          name={name}
          value={defaultValue}
          key={defaultValue || name}
          htmlRef={ref}
          type="text"
          placeholder={placeholder}
          className={styles.input}
          onChange={onChange}
          {...rest}
        />
      </label>
      {!!error && <p className={styles.error}>{error}</p>}
    </div>
  )
);

export default MaskedTextInput;
