import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import currency from "currency.js";
import SidebarBox from "./SidebarBox";
import StatisticsIcon from "../assets/emoticons/StatisticsIcon";
import styles from "./styles.module.css";

const SidebarStatistics = ({ wrapperClass }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  if (!user.isCreator) {
    return (
      <SidebarBox
        wrapperClass={wrapperClass}
        heading={
          <Link to="/membership" className={styles.headingLink}>
            {`${t("sidebarStatistics.following")}:
            ${Object.keys(user.following).length}`}
          </Link>
        }
      />
    );
  }

  return (
    <SidebarBox
      wrapperClass={wrapperClass}
      heading={
        <>
          <StatisticsIcon />
          <span>{t("sidebarStatistics.heading")}</span>
          <StatisticsIcon />
        </>
      }
    >
      <p className={styles.statisticsRow}>
        <Link to="/membership">
          <b>{t("sidebarStatistics.followers")}:</b>
          {Object.keys(user.followers).length}
        </Link>
      </p>
      <p className={styles.statisticsRow}>
        <Link to="/membership">
          <b>{t("sidebarStatistics.following")}:</b>
          {Object.keys(user.following).length}
        </Link>
      </p>
      <p className={styles.statisticsRow}>
        <Link to="/membership">
          <b>{t("sidebarStatistics.subscribers")}:</b>
          {Object.keys(user.subscribers).length}
        </Link>
      </p>

      <p className={styles.statisticsRow}>
        <Link to="/payouts">
          <b>{t("sidebarStatistics.earnings")}:</b>
          {currency(user.payments.balance).format()}
        </Link>
      </p>
    </SidebarBox>
  );
};

export default SidebarStatistics;
