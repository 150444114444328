import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import CheckBox from "../../components/checkBox/CheckBox";
import EmailIcon from "../../components/assets/emoticons/EmailIcon";
import HeartIcon from "../../components/assets/emoticons/HeartIcon";
import FireIcon from "../../components/assets/emoticons/FireIcon";
import EnvelopePigeonIcon from "../../components/assets/icons/EnvelopePigeonIcon";

const SettingsNotifications = ({ register, user }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.box}>
      <h2 className={styles.heading}>
        <>
          {t("settings.notificationsSettings")}
          <EmailIcon />
        </>
      </h2>
      <div className={styles.description}>
        <EnvelopePigeonIcon className={styles.descriptionIcon} />

        <p>{t("settings.notificationsSettingsDescription")}</p>
      </div>
      <CheckBox
        defaultChecked={user.settings.notifyAboutFans}
        name="settings.notifyAboutFans"
        ref={register}
        label={(
          <>
            {t("settings.notifyAboutFans")}
            <FireIcon />
          </>
        )}
      />
      <CheckBox
        defaultChecked={user.settings.sendEmailMarketing}
        name="settings.sendEmailMarketing"
        ref={register}
        label={(
          <>
            {t("settings.sendMarketingMails")}
            <HeartIcon />
          </>
        )}
      />
    </div>
  );
};

export default SettingsNotifications;
