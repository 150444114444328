import React from "react";

function MembershipIcon({ height = "1em", width = "1em" }) {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 16 16">
      <path
        fill="#041E40"
        d="M15.928 6.002a1.607 1.607 0 00-.468-.73 1.5 1.5 0 00-.764-.36l-3.683-.556L9.362.883A1.57 1.57 0 008.8.238a1.475 1.475 0 00-1.604 0 1.57 1.57 0 00-.562.645L4.988 4.355l-3.682.556c-.28.044-.544.168-.761.36-.218.191-.38.442-.469.724-.09.283-.1.587-.032.877.07.29.215.553.42.76l2.66 2.694-.63 3.808a1.66 1.66 0 00.091.868c.107.275.286.514.515.689a1.468 1.468 0 001.603.123L8 14.013l3.299 1.805a1.468 1.468 0 001.604-.123c.23-.176.408-.414.515-.69a1.66 1.66 0 00.09-.87l-.633-3.808 2.66-2.695c.204-.206.35-.468.419-.756.07-.288.06-.591-.026-.874z"
      />
    </svg>
  );
}

export default MembershipIcon;
