import React, { forwardRef } from "react";
import styles from "./styles.module.css";

const Radio = forwardRef(
  ({ name, label, children, error, value, ...rest }, ref) => (
    <label className={styles.radioWrapper}>
      <input
        className={styles.radio}
        type="radio"
        name={name}
        ref={ref}
        value={value}
        {...rest}
      />
      <span className={styles.fakeRadio} />
      <p className={styles.label}>{label}</p>
    </label>
  )
);

export default Radio;
