import React from "react";

function LoveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 80 80"
    >
      <path
        fill="#F2F2F2"
        d="M31.86 56.637a.832.832 0 00-.22-.584L11.103 33.807a14.74 14.74 0 01-2.83-17.2A15 15 0 0119.366 8.54a15.26 15.26 0 0113.3 4.28l5.104 4.667a3.333 3.333 0 004.49 0l5.203-4.744a15.166 15.166 0 0113.217-4.19 15 15 0 0111.096 8.067A14.423 14.423 0 0168.92 33.4a.833.833 0 00.113 1.237 23.643 23.643 0 013.553 3.25.834.834 0 00.61.28.85.85 0 00.62-.267 21.083 21.083 0 003.917-24.307 21.957 21.957 0 00-34.843-5.7l-2.86 2.61L37.266 8a21.746 21.746 0 00-18.97-6.037 21.573 21.573 0 00-15.963 11.63 21.333 21.333 0 004 24.847L30.29 64.397a.833.833 0 001.446-.547l.124-7.213z"
      />
      <path
        fill="#F2F2F2"
        d="M70.743 64.367a.827.827 0 01-.11-1.034A18.473 18.473 0 1065 68.967a.834.834 0 011.037.113l8.28 8.28a3.333 3.333 0 004.713-4.713l-8.287-8.28zm-27.41-10.93a11.764 11.764 0 1111.76 11.76 11.773 11.773 0 01-11.76-11.76z"
      />
    </svg>
  );
}

export default LoveIcon;
