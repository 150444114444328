import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { auth as firebaseAuth, firestore } from "../firebase";
import { setAuthUser, setIdToken } from "../data/auth/authSlice";
import { getCurrentUser, resetUser } from "../data/user/userSlice";

export const AuthController = () => {
  const unsubscribeUserSnapshot = useRef();
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const isLoading = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadUser = async () => {
      isLoading.current = true;
      dispatch(getCurrentUser()).then((resultAction) => {
        if (getCurrentUser.fulfilled.match(resultAction)) {
          history.push("/feed");
          isLoading.current = false;
        }
      });
    };

    if (
      auth.isLoggedIn &&
      auth.idToken &&
      !user.isLoaded &&
      !isLoading.current
    ) {
      loadUser();
    }
  }, [auth, dispatch, user, history]);

  const handleAuthStateChanged = useCallback(
    async (authUser) => {
      if (unsubscribeUserSnapshot.current) {
        unsubscribeUserSnapshot.current();
      }

      if (authUser) {
        dispatch(
          setAuthUser({
            isLoggedIn: true,
            uid: authUser.uid,
            email: authUser.email,
          })
        );

        unsubscribeUserSnapshot.current = firestore
          .collection("users")
          .doc(authUser.uid)
          .onSnapshot(() => {
            authUser.getIdToken(true);
          });

        return;
      }

      dispatch(
        setAuthUser({
          isLoggedIn: false,
          uid: null,
          email: null,
          idToken: null,
        })
      );
      dispatch(setIdToken(null));
      dispatch(resetUser());
    },
    [dispatch, history]
  );

  const handleIdTokenChanged = useCallback(
    async (authUser) => {
      if (authUser) {
        const idToken = await authUser.getIdToken();
        dispatch(setIdToken(idToken));
        return;
      }

      dispatch(setIdToken(null));
    },
    [dispatch]
  );

  useEffect(() => {
    const unsubscribeAuthStateChanged = firebaseAuth.onAuthStateChanged(
      handleAuthStateChanged
    );
    const unsubscribeIdToken = firebaseAuth.onIdTokenChanged(
      handleIdTokenChanged
    );

    return () => {
      unsubscribeAuthStateChanged();
      unsubscribeIdToken();
    };
  }, [handleAuthStateChanged, handleIdTokenChanged]);

  return null;
};
