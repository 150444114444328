import React from "react";
import ReactModal from "react-modal";
import styles from "./styles.module.css";

const Modal = ({
  isOpen,
  closeModal,
  heading,
  children,
  buttons,
  text,
  size = "small",
  isBlurred = false,
}) => {
  const sizes = {
    small: "350px",
    medium: "390px",
    big: "460px",
  };
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(4, 30, 64, 0.4)",
      zIndex: 2,
    },
    content: {
      maxWidth: sizes[size],
      width: "100%",
      height: "auto",
      padding: 0,
      top: "calc(50% + 64px)",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
      bodyOpenClassName={isBlurred ? "blurred" : null}
    >
      <div className={styles.modal}>
        {!!heading && <h2 className={styles.heading}>{heading}</h2>}
        {!!children && <div className={styles.content}>{children}</div>}
        {!!text && <p className={styles.text}>{text}</p>}
        {!!buttons && <div className={styles.buttons}>{buttons}</div>}
      </div>
    </ReactModal>
  );
};

export default Modal;
