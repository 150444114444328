import React from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./styles.module.css";
import { smallDevice } from "../../../components/assets/breakpoints/breakpoints";

const SectionBox = ({
  heading,
  subText,
  boxClass,
  headingClass,
  subTextClass,
  children,
}) => {
  const isMobile = useMediaQuery(smallDevice);
  return (
    <div
      className={`
        ${styles.sectionBox} ${boxClass} 
        ${isMobile ? styles.mobileSectionBox : ""}
      `}
    >
      <div className={`${styles.sectionPrimaryText} ${headingClass}`}>
        {heading}
      </div>
      <div className={`${styles.sectionSecondaryText} ${subTextClass}`}>
        {subText}
      </div>
      {children}
    </div>
  );
};

export default SectionBox;
