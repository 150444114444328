import React from "react";
import currency from "currency.js";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";

const CostsTable = ({ planPrice }) => {
  const { t } = useTranslation();
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>{t("costsTable.price")}</th>
          <th>{t("costsTable.processingFees")}</th>
          <th>{t("costsTable.vat")}</th>
          <th>{t("costsTable.starRevenue")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{currency(planPrice).format()}</td>
          <td>{currency(planPrice).multiply(0.08).format()}</td>
          <td>
            {currency(planPrice)
              .multiply(23 / 100)
              .format()}
          </td>
          <td>
            {currency(planPrice)
              .subtract(currency(planPrice).multiply(8 / 100))
              .subtract(currency(planPrice).multiply(23 / 100))
              .format()}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CostsTable;
