import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

const AuthAction = () => {
  const query = new URLSearchParams(useLocation().search);
  const mode = query.get("mode");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (mode === "verifyEmail") {
      history.push(
        `${location.pathname}${location.search}`.replace(
          "auth-action",
          "signin"
        )
      );
    }
    if (mode === "resetPassword") {
      history.push(
        `${location.pathname}${location.search}`.replace(
          "auth-action",
          "reset-password"
        )
      );
    }
  }, [history, location.pathname, location.search, mode]);

  return null;
};

export default AuthAction;
