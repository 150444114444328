import api from "../api";

const usersApi = {
  getUser: ({ link }) => api.get("user", { searchParams: { link } }),
  getSuggestions: () => api.get("suggestions"),
  followUser: ({ link }) => api.post("user/follow", { json: { link } }),
  unfollowUser: ({ link }) => api.post("user/unfollow", { json: { link } }),
  subscribeUser: ({ link, token }) =>
    api.post("user/subscribe", {
      json: { link, token },
    }),
  unsubscribeUser: ({ subscriptionId, starId }) =>
    api.post("user/unsubscribe", {
      json: {
        subscriptionId,
        starId,
      },
    }),
  searchUsers: ({ username }) =>
    api.get("search-users", { searchParams: { username } }),
};

export default usersApi;
