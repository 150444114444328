import * as React from "react";

function Lock(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M13 6.333h-.5V4.5a4.5 4.5 0 10-9 0v1.833H3a1.333 1.333 0 00-1.333 1.334v7A1.333 1.333 0 003 16h10a1.333 1.333 0 001.334-1.333v-7A1.333 1.333 0 0013 6.333zm-5 6a1.333 1.333 0 110-2.667 1.333 1.333 0 010 2.667zM10.834 6a.333.333 0 01-.334.333h-5A.333.333 0 015.167 6V4.5a2.833 2.833 0 115.667 0V6z"
        fill="#7B7B7B"
      />
    </svg>
  );
}

const MemoLock = React.memo(Lock);
export default MemoLock;
