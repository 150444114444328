import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { firebase, firestore } from "../../firebase";

const initialState = {
  conversations: {},
  contacts: [],
  areContactsLoaded: false,
  unreadConversationsCount: 0,
};

const updateConversation = createAction("messages/updateConversation");

const sendMessage = createAsyncThunk(
  "messages/sendMessage",
  async ({ receiverId, text }, { getState, rejectWithValue }) => {
    try {
      let conversation = null;
      const snapshots = await firestore
        .collection("conversations")
        .where(`members.${getState().user.id}`, "==", true)
        .where(`members.${receiverId}`, "==", true)
        .get();
      if (snapshots.docs.length) {
        conversation = snapshots.docs[0].ref;
      }
      const result = await conversation.collection("messages").add({
        text,
        to: receiverId,
        from: getState().user.id,
        createdAt: firebase.firestore.Timestamp.now(),
      });
      await conversation.update({
        lastText: text,
        lastTextAuthor: getState().user.id,
        updatedAt: firebase.firestore.Timestamp.now(),
        isUnread: true,
      });
      return result.id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getUnreadConversationsCount = createAsyncThunk(
  "messages/getUnreadConversationsCount",
  async (options, { getState, rejectWithValue }) => {
    try {
      const snapshots = await firestore
        .collection("conversations")
        .where(`members.${getState().user.id}`, "==", true)
        .get();
      const conversations = snapshots.docs.map(async (conversation) => {
        const userId = Object.keys(conversation.data().members).find(
          (user) => user !== getState().user.id
        );
        const user = await firestore.collection("users").doc(userId).get();
        if (!user.exists) {
          return null;
        }
        return conversation.data();
      });
      const result = await Promise.all(conversations);
      return result
        .filter((conversation) => conversation !== null)
        .filter(
          (conversation) =>
            conversation.isUnread &&
            conversation.lastTextAuthor !== getState().user.id
        ).length;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getConversations = createAsyncThunk(
  "messages/getConversations",
  async (options, { getState, rejectWithValue }) => {
    try {
      const snapshots = await firestore
        .collection("conversations")
        .where(`members.${getState().user.id}`, "==", true)
        .get();
      const conversations = snapshots.docs.map(async (conversation) => {
        const userId = Object.keys(conversation.data().members).find(
          (user) => user !== getState().user.id
        );
        const user = await firestore.collection("users").doc(userId).get();
        if (!user.exists) {
          return null;
        }
        const userData = user.data();
        return {
          id: conversation.id,
          ...conversation.data(),
          updatedAt: conversation.data().updatedAt.valueOf(),
          user: {
            id: user.id,
            username: userData.username,
            link: userData.link,
            avatar: userData.avatar,
          },
        };
      });
      const result = await Promise.all(conversations);
      return result.filter((conversation) => conversation !== null);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initConversation = createAsyncThunk(
  "messages/initConversation",
  async ({ receiverId }, { getState, rejectWithValue }) => {
    if (!receiverId) {
      rejectWithValue(null);
    }
    try {
      const query = firestore
        .collection("conversations")
        .where(`members.${getState().user.id}`, "==", true)
        .where(`members.${receiverId}`, "==", true);
      const snapshots = await query.get();
      if (!snapshots.docs.length) {
        await firestore.collection("conversations").add({
          updatedAt: firebase.firestore.Timestamp.now(),
          lastText: null,
          members: {
            [receiverId]: true,
            [getState().user.id]: true,
          },
        });
      }
      const resultSnapshots = await query.get();
      const conversation = await resultSnapshots.docs[0].ref.get();
      const userId = Object.keys(conversation.data().members).find(
        (user) => user !== getState().user.id
      );
      const user = await firestore.collection("users").doc(userId).get();
      const userData = user.data();
      return {
        id: conversation.id,
        ...conversation.data(),
        updatedAt: conversation.data().updatedAt.valueOf(),
        user: {
          id: user.id,
          username: userData.username,
          link: userData.link,
          avatar: userData.avatar,
        },
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const subscribeToConversation = async ({ userId, id, dispatch }) => {
  const messages = firestore
    .collection("conversations")
    .doc(id)
    .collection("messages")
    .orderBy("createdAt", "asc");

  const conversation = firestore.collection("conversations").doc(id);
  const conversationSnapshot = await conversation.get();

  if (conversationSnapshot.data().lastTextAuthor !== userId) {
    conversation.update({
      isUnread: false,
    });
  }

  const unsubscribe = messages.onSnapshot((snapshot) => {
    dispatch(
      updateConversation({
        id,
        messages: snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            createdAt: doc.data().createdAt.valueOf(),
          };
        }),
      })
    );
  });
  return unsubscribe;
};

const getContacts = createAsyncThunk(
  "messages/getContacts",
  async (options, { getState, rejectWithValue }) => {
    try {
      const { user } = getState();
      const followers = await Promise.all(
        Object.keys(user.followers).map(async (followerId) => {
          const follower = await firestore
            .collection("users")
            .doc(followerId)
            .get();
          if (!follower.exists) {
            return null;
          }
          const data = follower.data();
          return {
            id: follower.id,
            username: data.username,
            link: data.link,
            avatar: data.avatar,
            category: data.category,
          };
        })
      );
      const stars = await Promise.all(
        Object.keys(user.subscribes).map(async (userId) => {
          const star = await firestore.collection("users").doc(userId).get();
          if (!star.exists) {
            return null;
          }
          const data = star.data();
          return {
            id: star.id,
            username: data.username,
            link: data.link,
            avatar: data.avatar,
            category: data.category,
          };
        })
      );
      const result = [...stars, ...followers]
        .filter((contact) => contact !== null)
        .filter(
          (contact, index, array) =>
            array.findIndex((item) => item.id === contact.id) === index
        );
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    updateConversation: (state, action) => ({
      ...state,
      conversations: {
        ...state.conversations,
        [action.payload.id]: {
          ...state.conversations[action.payload.id],
          ...action.payload,
        },
      },
    }),
  },
  extraReducers: {
    [initConversation.fulfilled]: (state, action) => ({
      ...state,
      conversations: {
        ...state.conversations,
        [action.payload.id]: action.payload,
      },
    }),
    [getUnreadConversationsCount.fulfilled]: (state, action) => ({
      ...state,
      unreadConversationsCount: action.payload,
    }),
    [getConversations.fulfilled]: (state, action) => ({
      ...state,
      conversations: action.payload.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {}),
    }),
    [getContacts.fulfilled]: (state, action) => ({
      ...state,
      contacts: action.payload,
      areContactsLoaded: true,
    }),
  },
});

export {
  getConversations,
  getUnreadConversationsCount,
  initConversation,
  sendMessage,
  subscribeToConversation,
  getContacts,
};

export default postSlice.reducer;
