import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { signin, clearErrors, verifyEmail } from "../../data/auth/authSlice";
import TextInput from "../../components/textInput/TextInput";
import Layout from "../../components/layout/Layout";
import Button from "../../components/button/Button";
import Popup from "../../components/popup/Popup";
import WavingIcon from "../../components/assets/emoticons/WavingIcon";
import styles from "./styles.module.css";

const Signin = () => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [isEmailVerified, setEmailVerified] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const mode = query.get("mode");
  const oobCode = query.get("oobCode");
  const dispatch = useDispatch();
  const error = useSelector((state) => state.auth.errors.signin);

  useEffect(() => {
    if (mode === "verifyEmail") {
      dispatch(verifyEmail({ oobCode })).then((resultAction) => {
        if (verifyEmail.fulfilled.match(resultAction)) {
          setEmailVerified(true);
        }
      });
    }
  }, [dispatch, mode, oobCode]);

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(signin(data));
    setLoading(false);
  };

  const emailError = () => {
    if (error && error.code === "auth/invalid-email") {
      return t("signin.invalidEmail");
    }
    if (error && error.code === "auth/user-not-found") {
      return t("signin.emailNotFound");
    }
    if (error && error.code === "auth/unverified-email") {
      return t("signin.emailNotVerified");
    }
    return null;
  };

  const passwordError = () => {
    if (error && error.code === "auth/wrong-password") {
      return t("signin.invalidPassword");
    }
    if (
      error &&
      error.code &&
      error.code.startsWith("auth/too-many-requests")
    ) {
      return t("signin.tooManyAttempts");
    }
    return null;
  };

  return (
    <Layout>
      <div className={styles.box}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className={styles.heading}>
            <>
              {t("signin.header")}
              <WavingIcon className={styles.icon} />
            </>
          </h2>
          <TextInput
            name="email"
            type="email"
            ref={register}
            label={t("signin.email")}
            placeholder={t("signin.emailPlaceholder")}
            error={emailError()}
          />
          <TextInput
            name="password"
            type="password"
            ref={register}
            label={t("signin.password")}
            placeholder={t("signin.passwordPlaceholder")}
            error={passwordError()}
          />
          <div className={styles.submit}>
            <Button primary type="submit" isLoading={isLoading}>
              {t("signin.login")}
            </Button>
          </div>
        </form>
      </div>
      <div className={styles.links}>
        <div className={styles.link}>
          <Link to="/reset-password">{t("signin.forgotPassword")}</Link>
        </div>
        <div className={styles.link}>
          <Link to="/signup">{t("signin.signupRedirect")}</Link>
        </div>
      </div>
      <Popup
        isOpen={isEmailVerified}
        onClose={() => {
          setEmailVerified(false);
        }}
        heading={t("signin.emailVerifiedHeading")}
        text={t("signin.emailVerifiedDescription")}
      />
    </Layout>
  );
};

export default Signin;
