import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TextInput from "../../components/textInput/TextInput";
import Select from "../../components/select/Select";
import Avatar from "../../components/avatar/Avatar";
import LoaderWrapper from "../../components/loaders/LoaderWrapper";
import Modal from "../../components/modal/Modal";
import CheckBox from "../../components/checkBox/CheckBox";
import Button from "../../components/button/Button";
import EyesIcon from "../../components/assets/emoticons/EyesIcon";
import CryingIcon from "../../components/assets/emoticons/CryingIcon";
import styles from "./styles.module.css";
import { updateAvatar, deleteAvatar } from "../../data/user/userSlice";

const SettingsGeneral = ({ register, user, watch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isAvatarConfirmationOpen, setAvatarConfirmationOpen] = useState(false);
  const [isAvatarDeleting, setAvatarDeleting] = useState(false);
  const [isAvatarUploading, setAvatarUploading] = useState(false);
  let avatarInput = null;
  const watchLink = watch("link");

  const handleUpload = (event) => {
    const formData = new FormData();
    setAvatarUploading(true);
    formData.append("avatar", event.target.files[0]);
    dispatch(updateAvatar({ formData })).then(() => {
      setAvatarUploading(false);
    });
  };

  const handleDeleteAvatar = () => {
    setAvatarDeleting(true);
    dispatch(deleteAvatar({})).then(() => {
      setAvatarDeleting(false);
      setAvatarConfirmationOpen(false);
    });
  };

  const goToProfile = (event) => {
    event.preventDefault();
    if (watchLink) {
      history.push(`/u/${watchLink}`);
    }
  };

  const findError = (fieldName) => {
    const field = (user.errors || []).find((error) => {
      return error.param === fieldName;
    });
    return field ? field.msg : null;
  };

  return (
    <div className={styles.box}>
      <h2 className={styles.heading}>
        <>
          {t("settings.accountSettings")}
          <EyesIcon />
        </>
      </h2>
      <p className={styles.description} />
      <TextInput
        defaultValue={user.email}
        label={t("settings.email")}
        name="email"
        placeholder={t("settings.emailPlaceholder")}
        ref={register}
        error={findError("email")}
      />
      <TextInput
        defaultValue={user.username}
        label={t("settings.username")}
        name="username"
        placeholder={t("settings.usernamePlaceholder")}
        ref={register}
        error={findError("username")}
        onInput={(event) => {
          event.target.value = event.target.value
            .toLowerCase()
            .replace(/([^0-9a-z_])/g, "");
        }}
      />
      {user.isCreator && (
        <Select
          label={t("settings.accountCategory")}
          name="category"
          defaultValue={user.category}
          ref={register}
          error={findError("category")}
        >
          <option value="adult">{t("categories.adult")}</option>
          <option value="videos">{t("categories.videos")}</option>
          <option value="design">{t("categories.design")}</option>
          <option value="podcasts">{t("categories.podcasts")}</option>
          <option value="writing">{t("categories.writing")}</option>
          <option value="music">{t("categories.music")}</option>
          <option value="software">{t("categories.software")}</option>
          <option value="photography">{t("categories.photography")}</option>
          <option value="business">{t("categories.business")}</option>
        </Select>
      )}
      {user.isCreator && (
        <div className={styles.linkWrapper}>
          <div className={styles.linkInput}>
            <TextInput
              prefix="fanbea.com/u/"
              defaultValue={user.link}
              label={t("settings.link")}
              name="link"
              placeholder={t("settings.linkPlaceholder")}
              ref={register}
              error={findError("link")}
            />
          </div>
          <div className={styles.goToProfile}>
            <Button onClick={goToProfile} secondary>
              {t("settings.goToProfile")}
            </Button>
          </div>
        </div>
      )}
      <input
        type="file"
        id="input"
        className={styles.avatarInput}
        onChange={handleUpload}
        ref={(input) => {
          avatarInput = input;
        }}
      />
      <div className={styles.avatarBox}>
        <label className={styles.label}>{t("settings.accountAvatar")}</label>
        <div className={styles.manageAvatar}>
          <div className={styles.uploadAvatar}>
            <div className={styles.avatar}>
              <LoaderWrapper isLoading={isAvatarUploading}>
                <Avatar
                  alt={user.username}
                  src={user.avatar}
                  onClick={() => avatarInput.click()}
                />
              </LoaderWrapper>
            </div>
            <div className={styles.avatarUploadButton} onClick={() => avatarInput.click()} role="button" tabIndex="0">
              <p className={styles.avatarTip}>{t("settings.uploadAvatar")}</p>
            </div>
            <p className={styles.avatarTipDescription}>
              {t("settings.uploadAvatarDescription")}
            </p>
          </div>
          <div className={styles.deleteAvatar}>
            <button
              type="button"
              transparent
              warning
              className={
                user.avatar
                  ? styles.deleteAvatarButton
                  : styles.deleteAvatarButtonDisabled
              }
              onClick={() => setAvatarConfirmationOpen(true)}
            >
              {t("settings.deleteAvatar")}
            </button>
          </div>
        </div>
      </div>
      <CheckBox
        defaultChecked={user.settings.showAdultContent}
        ref={register}
        name="settings.showAdultContent"
        label={t("settings.showAdultContent")}
      />
      <Modal
        isOpen={isAvatarConfirmationOpen}
        heading={(
          <>
            <CryingIcon />
            {` ${t("settings.avatarConfirmationHeading")} `}
            <CryingIcon />
          </>
        )}
        text={t("settings.avatarConfirmationDescription")}
        buttons={(
          <>
            <Button
              isLoading={isAvatarDeleting}
              warning
              onClick={handleDeleteAvatar}
            >
              {t("settings.avatarConfirmationConfirm")}
            </Button>
            <Button
              transparent
              onClick={() => setAvatarConfirmationOpen(false)}
            >
              {t("settings.avatarConfirmationCancel")}
            </Button>
          </>
        )}
      />
    </div>
  );
};

export default SettingsGeneral;
