import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link, useLocation, useHistory } from "react-router-dom";
import { initResetPassword, resetPassword } from "../../data/auth/authSlice";
import TextInput from "../../components/textInput/TextInput";
import Layout from "../../components/layout/Layout";
import Popup from "../../components/popup/Popup";
import Button from "../../components/button/Button";
import OkayIcon from "../../components/assets/emoticons/OkayIcon";
import styles from "./styles.module.css";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const oobCode = query.get("oobCode");
  const initResetPasswordError = useSelector(
    (state) => state.auth.errors.initResetPassword
  );
  const resetPasswordError = useSelector(
    (state) => state.auth.errors.resetPassword
  );
  const sendEmail = (data) => {
    setLoading(true);
    dispatch(initResetPassword(data)).then((resultAction) => {
      setLoading(false);
      if (initResetPassword.fulfilled.match(resultAction)) {
        setIsPopupOpen(true);
      }
    });
  };
  const setNewPassword = ({ newPassword }) => {
    setLoading(true);
    dispatch(resetPassword({ newPassword, oobCode })).then((resultAction) => {
      setLoading(false);
      if (resetPassword.fulfilled.match(resultAction)) {
        history.push("/signin");
      }
    });
  };

  const getValidationTranslation = (error) => {
    const codes = {
      EXPIRED_OOB_CODE: t("resetPassword.expiredCode"),
      INVALID_OOB_CODE: t("resetPassword.invalidCode"),
      EMAIL_NOT_FOUND: t("resetPassword.emailNotFound"),
      MISSING_EMAIL: t("resetPassword.missingEmail"),
      INVALID_EMAIL: t("resetPassword.invalidEmail"),
      WEAK_PASSWORD: t("resetPassword.weakPassword"),
    };
    return error && codes[error.message.split(" ")[0]]
      ? codes[error.message.split(" ")[0]]
      : null;
  };

  if (oobCode) {
    return (
      <Layout>
        <div className={styles.wrapper}>
          <div className={styles.box}>
            <form onSubmit={handleSubmit(setNewPassword)}>
              <h2 className={styles.heading}>
                {t("resetPassword.uniquePassword")}
              </h2>
              <TextInput
                name="newPassword"
                type="password"
                ref={register}
                label={t("resetPassword.newPassword")}
                placeholder={t("resetPassword.newPasswordPlaceholder")}
                error={getValidationTranslation(resetPasswordError)}
              />
              <div className={styles.submit}>
                <Button primary type="submit" isLoading={isLoading}>
                  {t("resetPassword.resetPassword")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.box}>
        <form onSubmit={handleSubmit(sendEmail)}>
          <h2 className={styles.heading}>
            <>
              {t("resetPassword.header")}
              <OkayIcon className={styles.icon} />
            </>
          </h2>
          <TextInput
            name="email"
            ref={register}
            label={t("resetPassword.email")}
            placeholder={t("resetPassword.emailPlaceholder")}
            error={getValidationTranslation(initResetPasswordError)}
          />
          <div className={styles.submit}>
            <Button primary type="submit" isLoading={isLoading}>
              {t("resetPassword.resetPassword")}
            </Button>
          </div>
        </form>
      </div>
      <div className={styles.links}>
        <div className={styles.link}>
          <Link to="/signin">{t("resetPassword.signinRedirect")}</Link>
        </div>
        <div className={styles.link}>
          <Link to="/signup">{t("resetPassword.signupRedirect")}</Link>
        </div>
      </div>
      <Popup
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
        }}
        heading={t("resetPassword.checkEmailHeading")}
        text={t("resetPassword.checkEmailText")}
        closeTimeoutMS={1}
      />
    </Layout>
  );
};

export default ResetPassword;
