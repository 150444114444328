import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import currency from "currency.js";
import SidebarBox from "./SidebarBox";
import Button from "../button/Button";
import Modal from "../modal/Modal";
import TextInput from "../textInput/TextInput";
import CashIcon from "../assets/emoticons/CashIcon";
import styles from "./styles.module.css";
import { updatePlanPrice } from "../../data/user/userSlice";

const ModalSubscriptionCost = ({
  isOpen,
  closeModal,
  planPrice,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const priceIntValue = currency(planPrice, {
    format: (value) => `${value.dollars()}`,
  }).format();
  const [newPrice, setNewPrice] = useState(`$${priceIntValue}`);
  const [isLoading, setLoading] = useState(false);
  const isPriceValid = currency(newPrice).value < 999;

  const onSave = () => {
    if (!isPriceValid) {
      return;
    }
    const newPriceFormated = currency(newPrice).format();
    if (newPriceFormated === planPrice) {
      closeModal();
      onSuccess();
      return;
    }
    setLoading(true);
    dispatch(updatePlanPrice({ price: newPriceFormated })).then(() => {
      setLoading(false);
      closeModal();
      onSuccess();
    });
  };

  const onChange = (event) => {
    const { value } = event.target;
    let text = value[0] === "$" ? value : `$${value}`;
    text = text.replace(/[^\d$]/g, "");
    setNewPrice(text);
  };

  return (
    <Modal
      heading={t("modalSubscriptionCost.heading")}
      isOpen={isOpen}
      onRequestClose={closeModal}
      closeModal={closeModal}
    >
      <div className={styles.modalUpdatePlan}>
        <TextInput
          onChange={onChange}
          value={newPrice}
          label={t("modalSubscriptionCost.subscriptionCost")}
          inputClassName={styles.subscriptionCostInput}
          error={isPriceValid ? null : true}
        />
        <Button onClick={() => onSave()} primary isLoading={isLoading}>
          {t("modalSubscriptionCost.save")}
        </Button>
      </div>
    </Modal>
  );
};

const SidebarSubscriptionCost = ({
  planId,
  planPrice = 0,
  onSuccess,
  wrapperClass,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const priceFormated = currency(planPrice, {
    format: (value) => `$${value.dollars()}`,
  }).format();
  return (
    <SidebarBox
      wrapperClass={wrapperClass}
      heading={(
        <>
          <CashIcon />
          <span>{t("sidebarSubscriptionCost.heading")}</span>
          <CashIcon />
        </>
      )}
    >
      <div className={styles.row}>
        <p className={styles.subscriptionCost}>
          <span>
            {`${t("sidebarSubscriptionCost.cost")}: ${priceFormated}`}
          </span>
          <Button transparent onClick={() => setModalOpen(true)}>
            {t("sidebarSubscriptionCost.edit")}
          </Button>
        </p>
      </div>
      <ModalSubscriptionCost
        planId={planId}
        planPrice={planPrice}
        isOpen={isModalOpen}
        closeModal={() => setModalOpen(false)}
        onSuccess={onSuccess}
      />
    </SidebarBox>
  );
};

export default SidebarSubscriptionCost;
