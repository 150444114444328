import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "../Modal";
import CheckBox from "../../checkBox/CheckBox";
import Button from "../../button/Button";
import { updateCurrentUser } from "../../../data/user/userSlice";
import styles from "./styles.module.css";

const ModalAgeVerification = ({ isOpen, closeModal = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const [remember, setRemember] = useState(true);

  const onConfirm = () => {
    if (remember && auth.isLoggedIn && user.isLoaded) {
      dispatch(
        updateCurrentUser({
          data: {
            ...user,
            settings: { ...user.settings, showAdultContent: true },
          },
        })
      );
    }
    closeModal();
  };

  return (
    <Modal
      heading={t("modalAgeVerification.heading")}
      isOpen={isOpen}
      onRequestClose={closeModal}
      closeModal={closeModal}
      isBlurred
      buttons={(
        <>
          <Button secondary onClick={() => history.goBack()}>
            {t("modalAgeVerification.goBack")}
          </Button>
          <Button primary onClick={() => onConfirm()}>
            {t("modalAgeVerification.viewProfile")}
          </Button>
        </>
      )}
    >
      <div className={styles.modalAgeVerification}>
        <p className={styles.description}>
          {t("modalAgeVerification.description")}
        </p>
        {auth.isLoggedIn && user.isLoaded && (
          <CheckBox
            checked={remember}
            onClick={() => setRemember(!remember)}
            label={t("modalAgeVerification.saveChoice")}
          />
        )}
      </div>
    </Modal>
  );
};

export default ModalAgeVerification;
